import axios from 'axios';

export const downloadFile = async (url: string,
  filename: string, extension: string): Promise<void> => {
  const link = document.createElement('a');
  const allowExtension = ['mp4', 'pdf', 'jpeg', 'png', 'jpg'];
  if (allowExtension.includes(extension)) {
    const response = await axios.get(url, { responseType: 'blob' });

    const href = window.URL.createObjectURL(new Blob([response.data], {
      type: `${response.data.type};charset=utf-8`,
    }));
    link.href = href;
  } else {
    link.href = url;
  }
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// TODO: remove after add more export
export const placeholder = null;
