import { all } from 'redux-saga/effects';

import watchFetchAssessmentCourseSaga from './criteria';
import fetchDetailCourseSaga from './folder';
import watchFetchPhaseSaga from './phase';
import watchFetchProgressSaga from './progress';

export default function* watchFetchDetailCourseSaga(): Generator {
  yield all([watchFetchPhaseSaga(), watchFetchProgressSaga(),
    fetchDetailCourseSaga(), watchFetchAssessmentCourseSaga(),
  ]);
}
