import React, { Suspense, useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import {
  BrowserRouter as Router, Switch, Redirect,
} from 'react-router-dom';

import store from './store';

import { getAccessToken } from 'apis/localstorage';
import Common from 'components/common';
import { ModalProvider } from 'contexts/modal';
import { requestCheckSignInAction } from 'reducers/auth/actions';
import { requestGetSystem } from 'reducers/system/actions';
// eslint-disable-next-line import/order
import routes from 'routes';

import './App.scss';

import './i18n';
import PrivateRoute from 'routes/PrivateRoute';
import RedirectWithRole from 'routes/RedirectWithRole';
import useSelector from 'selectors/useSelector';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(requestGetSystem());
    const token = getAccessToken();
    if (token) {
      dispatch(requestCheckSignInAction());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback>
      <Switch>
        {routes.map((route) => (!route.isPrivate ? (
          <RedirectWithRole
            exact
            key={route.path}
            path={route.path}
            user={user}
            component={route.component}
          />
        ) : (
          <PrivateRoute
            exact
            key={route.path}
            component={route.component}
            path={route.path}
          />
        )))}
        <Redirect from="*" to="/notfound" />
      </Switch>
    </Suspense>
  );
};

const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <Router>
      <ModalProvider>
        <App />
        <Common />
      </ModalProvider>
    </Router>
  </Provider>
);

export default AppWrapper;
