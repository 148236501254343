import {
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_ERROR,
  POST_SEEN_NOTIFICATION_REQUEST,
  POST_SEEN_NOTIFICATION_SUCCESS,
  POST_SEEN_NOTIFICATION_ERROR,
  GetNotificationActionTypes,
  NotificationState,
} from './types';

const defaultState: NotificationState = {
  loading: false,
  list: [],
  errors: null,
  seenNotification: false,
};

export default (
  state = defaultState,
  action: GetNotificationActionTypes,
): NotificationState => {
  switch (action.type) {
    case GET_NOTIFICATION_REQUEST:
    case POST_SEEN_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        list: action.response,
        loading: false,
        errors: null,
      };
    case POST_SEEN_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        seenNotification: true,
        errors: null,
      };
    case GET_NOTIFICATION_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case POST_SEEN_NOTIFICATION_ERROR:
      return {
        ...state,
        loading: false,
        seenNotification: false,
        errors: action.errors,
      };
    default:
      return state;
  }
};
