import {
  ErrorsResponse,
} from '../phase/types';

import {
  REQUEST_GET_COURSE_PROGRESS,
  GET_COURSE_PROGRESS_SUCCESS,
  GET_COURSE_PROGRESS_ERROR,
  CLEAR_COURSE_PROGRESS,
  GetProgressActionTypes,
} from './types';

import { ItemPhase } from 'reducers/pages/home/types';


interface InitStateType{
  loading: boolean;
  data: ItemPhase[]|null;
  error: ErrorsResponse|null;
}
const initState: InitStateType = {
  loading: false,
  data: null,
  error: null,
};
export default (state = initState, action: GetProgressActionTypes): InitStateType => {
  switch (action.type) {
    case REQUEST_GET_COURSE_PROGRESS:
      return {
        ...state, loading: true,
      };
    case GET_COURSE_PROGRESS_SUCCESS:
      return {
        ...state, loading: false, data: action.payload, error: null,
      };
    case GET_COURSE_PROGRESS_ERROR:
      return {
        ...state, loading: false, data: null, error: action.payload,
      };
    case CLEAR_COURSE_PROGRESS:
      return {
        ...state, data: null,
      };
    default:
      return { ...state };
  }
};
