import { call, put, takeLatest } from 'redux-saga/effects';

import getListCountryService from 'apis/requests/country';
import { requestGetListCountryError, requestGetListCountrySuccess } from 'reducers/country/actions';
import {
  CountryTypes,
  ErrorsResponse,
  REQUEST_GET_LIST_COUNTRY,
} from 'reducers/country/types';

export function* requestListCountry(): Generator {
  try {
    const data = (yield call(getListCountryService)) as CountryTypes[];
    yield put(requestGetListCountrySuccess(data));
  } catch (e) {
    yield put(requestGetListCountryError(e as ErrorsResponse));
  }
}

export default function* watchGetListCountrySaga(): Generator {
  yield takeLatest(REQUEST_GET_LIST_COUNTRY, requestListCountry);
}
