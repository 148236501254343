import {
  REQUEST_FETCH_ABOUT_YOU,
  FETCH_ABOUT_YOU_SUCCESS,
  FETCH_ABOUT_YOU_ERROR,
  ContactState,
  ContactActionTypes,
} from 'actions/pages/types';

const initState: ContactState = {
  aboutYou: null,
  loading: false,
  errors: null,
};

export default (state = initState, action: ContactActionTypes): ContactState => {
  switch (action.type) {
    case REQUEST_FETCH_ABOUT_YOU:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case FETCH_ABOUT_YOU_SUCCESS:
      return {
        ...state,
        aboutYou: action.response,
        loading: false,
        errors: null,
      };
    case FETCH_ABOUT_YOU_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    default:
      return state;
  }
};
