/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import { getDetailCourse, getAllFolder } from 'apis/requests/detailcourse';
import { findPhaseActive } from 'function/func';
import {
  getAllFolderSuccess,
} from 'reducers/detailcourse/foldercourse/actions';
import {
  DataResponse,
} from 'reducers/detailcourse/foldercourse/types';
import {
  getDetailCourseSuccess,
  getDetailCourseError,
} from 'reducers/detailcourse/phase/actions';
import {
  REQUEST_GET_DETAIL_COURSE,
  RequestDetailCourseAction,
  DataDetailCourseResponse,
} from 'reducers/detailcourse/phase/types';


function* fetchDetailCourseSaga(action: RequestDetailCourseAction): Generator {
  try {
    const data = (yield call(getDetailCourse, action.payload)) as DataDetailCourseResponse;
    yield put(getDetailCourseSuccess(data));
  } catch (errors) {
    yield put(getDetailCourseError(errors[0]));
  }
}
export default function* watchFetchDetailCourseSaga(): Generator {
  yield takeLatest(REQUEST_GET_DETAIL_COURSE, fetchDetailCourseSaga);
}
