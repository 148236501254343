import {
  REQUEST_GET_DATA_HOME,
  GET_DATA_HOME_ON_SUCCESS,
  DataHomeCarousel,
  HomePageActionTypes,
} from './types';


export const requestGetDataHomePage = (
): HomePageActionTypes => ({
  type: REQUEST_GET_DATA_HOME,
});

export const getDataHomePageCarouselOnSuccess = (
  dataHomeCarousel: DataHomeCarousel,
  dataHomeCourse: DataDetailtCourse[],
): HomePageActionTypes => ({
  type: GET_DATA_HOME_ON_SUCCESS,
  dataHomeCarousel,
  dataHomeCourse,
});
