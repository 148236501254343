import {
  GetInfoRegistrationActionTypes,
  ResponseInfoRegistrations,
  REQUEST_GET_INFO_REGISTRATION,
  GET_INFO_REGISTRATION_SUCCESS,
  GET_INFO_REGISTRATION_ERROR,
} from './types';

const defaultState: ResponseInfoRegistrations = {
  data: undefined,
  loading: false,
};

export default (
  state = defaultState,
  action: GetInfoRegistrationActionTypes,
): ResponseInfoRegistrations => {
  switch (action.type) {
    case REQUEST_GET_INFO_REGISTRATION:
      return {
        ...state,
        loading: true,
      };
    case GET_INFO_REGISTRATION_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case GET_INFO_REGISTRATION_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        errors: action.errors,
      };
    default:
      return state;
  }
};
