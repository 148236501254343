import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import { getProfile } from 'apis/requests/profile';
import { userSignInOnError, userSignInOnSuccess } from 'reducers/auth/actions';
import {
  UserSigninResponse,
  REQUEST_CHECK_USER_SIGNIN,
} from 'reducers/auth/types';

export function* requestCheckSignInSaga(
): Generator {
  try {
    const data = (yield call(getProfile)) as UserSigninResponse;
    yield put(userSignInOnSuccess(data));
  } catch (e) {
    yield put(userSignInOnError(e));
  }
}


export default function* watchSigninSaga(): Generator {
  yield all([
    takeLatest(REQUEST_CHECK_USER_SIGNIN, requestCheckSignInSaga),
  ]);
}
