/* eslint-disable @typescript-eslint/camelcase */
export const REQUEST_GET_PROFILE = 'REQUEST_GET_PROFILE';
export const GET_PROFILE_ON_SUCCESS = 'GET_PROFILE_ON_SUCCESS';
export const GET_PROFILE_ON_ERROR = 'GET_PROFILE_ON_ERROR';
export const REQUEST_UPDATE_PROFILE = 'REQUEST_UPDATE_PROFILE';
export const UPDATE_PROFILE_ON_SUCCESS = 'UPDATE_PROFILE_ON_SUCCESS';
export const UPDATE_PROFILE_ON_ERROR = 'UPDATE_PROFILE_ON_ERROR';
export const REST_STATE_PROFILE = 'REST_STATE_PROFILE';

export type AvailabilityTranslation ={
  id: number;
  availabilityId: number;
  locale: string;
  name: string;
}

export type Availability ={
  id: number;
  availabilityTranslation: AvailabilityTranslation;
}

export type PositionTranslation ={
  id: number;
  positionId: number;
  locale: string;
  name: string;
}

export type Position ={
  id: number;
  positionTranslation: PositionTranslation;
}

export type Title ={
  college: number;
  occupation: number;
}

// interface
interface PrepareToWorkTranslation {
  id: number;
  prepareToWorkId: number;
  locale: string;
  name: string;
}

interface PrepareToWorks {
  id: number;
  isEditable: number;
  createdAt: Date;
  updatedAt: Date;
  prepareToWorkTranslation: PrepareToWorkTranslation;
}

// get progile new
export interface ProfileResponse {
  location: string;
  id: number;
  studentId: string;
  firstName: string;
  lastName: string;
  intro: string;
  email: string;
  type: number;
  gender: number;
  image: string;
  dob: string;
  address: string;
  occupation: string;
  technical: string;
  language: string;
  active: number;
  emailVerifiedAt: string;
  rememberToken: string;
  createdAt: string;
  updatedAt: string;
  prepareToWorks: PrepareToWorks[];
  subRole?: any;
  positionId?: number;
  positionText?: string;
  availabilityId?: number;
  availabilityText?: string;
  phone?: string;
  availability?: Availability;
  position?: Position;
  country?: {
    id: number;
    countryTranslation: {
      countryId: number;
      id: number;
      locale: string;
      name: string;
    };
  };
  countryId?: number;
  first_name?: string;
  last_name?: string;
}
//
export interface ErrorsResponse{
  code: string;
  detail: string;
  title: string;
}

export interface UpdateProfileRequest {
  intro?: string;
  address?: string;
}

export interface UpdateProfileParticipantRequest {
  address?: string;
  prepareToWork?: number[];
  positionId?: number;
  positionText?: string;
  availabilityId?: number;
  languages?: string;
  occupation?: string;
  technicalIds?: number[];
  technicalText?: string;
  countryId?: number;
}

export interface ProfileState {
  data: ProfileResponse | null;
  loading: boolean;
  errors: ErrorsResponse | null;
  isSuccess: boolean;
  isUpdateProfile: boolean;
}
export interface RequestGetProfileAction {
  type: typeof REQUEST_GET_PROFILE;
}

export interface RequestUpdateProfileAction {
  type: typeof REQUEST_UPDATE_PROFILE;
  data: UpdateProfileRequest;
}

interface GetProfileOnErrorAction {
  type: typeof GET_PROFILE_ON_ERROR;
  errors: ErrorsResponse;
}

interface GetProfileOnSuccessAction {
  type: typeof GET_PROFILE_ON_SUCCESS;
  responseProfile: ProfileResponse;
}

interface UpdateProfileOnErrorAction {
  type: typeof UPDATE_PROFILE_ON_ERROR;
  errors: ErrorsResponse;
}

interface UpdateProfileOnSuccessAction {
  type: typeof UPDATE_PROFILE_ON_SUCCESS;
}

interface RestStateProfileAction {
  type: typeof REST_STATE_PROFILE;
}

export type GetProfileActionTypes =
  | RequestGetProfileAction
  | GetProfileOnErrorAction
  | GetProfileOnSuccessAction
  | RequestUpdateProfileAction
  | UpdateProfileOnErrorAction
  | UpdateProfileOnSuccessAction
  | RestStateProfileAction;
