import { ErrorsResponse } from '../phase/types';

import {
  REQUEST_ASSESSMENT_COURSE,
  GET_ASSESSMENT_COURSE_SUCCESS,
  GET_ASSESSMENT_COURSE_ERROR,
  UPDATE_COMMENT_PARTICIPANT,
  CLEAR_ASSESSMENT_PARTICIPANT,
  DataAssessmentCourseResponse,
  AssessmentCourseActionTypes,
} from './types';

interface InitStateType{
  loading: boolean;
  data: DataAssessmentCourseResponse|null;
  error: ErrorsResponse|null;
}
const initState: InitStateType = {
  loading: false,
  data: null,
  error: null,
};
export default (state = initState, action: AssessmentCourseActionTypes): InitStateType => {
  switch (action.type) {
    case REQUEST_ASSESSMENT_COURSE:
      return {
        ...state, loading: true,
      };
    case GET_ASSESSMENT_COURSE_SUCCESS:
      return {
        ...state, loading: false, data: action.payload, error: null,
      };
    case GET_ASSESSMENT_COURSE_ERROR:
      return {
        ...state, loading: false, data: null, error: action.payload,
      };
    case UPDATE_COMMENT_PARTICIPANT:
      if (state.data) {
        return {
          ...state,
          data: {
            ...state.data,
            finalComment: action.payload,
          },
        };
      }
      return {
        ...state,
      };
    case CLEAR_ASSESSMENT_PARTICIPANT:
      return {
        ...state,
        data: null,
      };
    default:
      return { ...state };
  }
};
