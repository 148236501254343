import {
  all, call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';

import { getProfile, updateProfile } from 'apis/requests/profile';
import {
  getProfileOnSuccess,
  getProfileOnError,
  updateProfileOnError,
  updateProfileOnSuccess,
} from 'reducers/profile/actions';
import {
  ProfileResponse,
  REQUEST_GET_PROFILE,
  REQUEST_UPDATE_PROFILE,
  RequestUpdateProfileAction,
} from 'reducers/profile/types';


export function* requestGetProfile(): Generator {
  try {
    const data = (yield call(getProfile)) as ProfileResponse;
    yield put(getProfileOnSuccess(data));
  } catch (e) {
    yield put(getProfileOnError(e));
  }
}

export function* requestUpdateProfile(dataForm: RequestUpdateProfileAction): Generator {
  try {
    yield call(updateProfile, dataForm.data);
    yield put(updateProfileOnSuccess());
  } catch (e) {
    yield put(updateProfileOnError(e));
  }
}

export default function* watchGetProfileSaga(): Generator {
  yield all([takeLatest(REQUEST_GET_PROFILE, requestGetProfile),
    takeEvery(REQUEST_UPDATE_PROFILE, requestUpdateProfile)]);
}
