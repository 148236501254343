import { call, put, takeLatest } from 'redux-saga/effects';

import getSystem from 'apis/requests/system';
import {
  getSystemOnError,
  getSystemOnSuccess,
} from 'reducers/system/actions';
import {
  REQUEST_GET_SYSTEM,
  SystemResponse,
} from 'reducers/system/types';


export function* requestSystem(): Generator {
  try {
    const data = (yield call(getSystem)) as SystemResponse;
    yield put(getSystemOnSuccess(data));
  } catch (e) {
    yield put(getSystemOnError({
      code: 'Error',
      detail: 'Error',
      title: 'Error',
    }));
  }
}
export default function* watchFetchSystemSaga(): Generator {
  yield takeLatest(REQUEST_GET_SYSTEM, requestSystem);
}
