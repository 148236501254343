import {
  REQUEST_GET_LIST_COURSES,
  GET_LIST_COURSES_ON_SUCCESS,
  GET_LIST_COURSES_ON_ERROR,
  RESET_LIST_COURSES,
  ListCoursesActionTypes,
  ListCoursesInState,
} from './types';

const defaultState: ListCoursesInState = {
  data: [],
  loading: false,
  errors: null,
};

export default (state = defaultState,
  action: ListCoursesActionTypes): ListCoursesInState => {
  switch (action.type) {
    case REQUEST_GET_LIST_COURSES:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case GET_LIST_COURSES_ON_SUCCESS:
    {
      let newData = action.listCourseResponse.data;

      if (state.data.length) {
        newData = state.data.map((item) => {
          const findCourseData = action.listCourseResponse.data.find(
            (v) => v.category.id === item.category.id,
          );

          if (findCourseData) {
            const newCourse = findCourseData.data.filter(
              (v) => item.data.every((x) => x.id !== v.id),
            );

            return {
              ...item,
              data: [
                ...item.data,
                ...newCourse,
              ],
            };
          }
          return item;
        });
      }

      return {
        ...state,
        data: newData,
        loading: false,
        errors: null,
      };
    }
    case GET_LIST_COURSES_ON_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case RESET_LIST_COURSES:
      return defaultState;
    default:
      return state;
  }
};
