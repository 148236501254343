import { call, put, takeEvery } from 'redux-saga/effects';

import postAcceptPolicy from 'apis/requests/acceptpolicy';
import { acceptPolicyOnSuccess, acceptPolicyOnError } from 'reducers/acceptpolicy/actions';
import { RequestAcceptPolicyAction, REQUEST_ACCEPT_POLICY } from 'reducers/acceptpolicy/types';
import { requestStopLoading } from 'reducers/loading/actions';


export function* requestAcceptPolicyPassword(action: RequestAcceptPolicyAction): Generator {
  try {
    yield call(postAcceptPolicy, action.acceptPolicy);
    yield put(requestStopLoading());
    yield put(acceptPolicyOnSuccess());
  } catch (e) {
    yield put(requestStopLoading());
    yield put(acceptPolicyOnError(e[0]));
  }
}

export default function* watchAcceptPolicySaga(): Generator {
  yield takeEvery(REQUEST_ACCEPT_POLICY, requestAcceptPolicyPassword);
}
