import {
  call, put, takeEvery, all,
} from 'redux-saga/effects';

import { uploadFile, deleteFile, submitUploadFiles } from 'apis/requests/courseuploadfiles';
import {
  uploadFilesOnSuccess,
  uploadFilesOnError,
  deleteFilesOnError,
  deleteFilesOnSuccess,
  submitUploadFilesOnError,
  submitUploadFilesOnSuccess,
} from 'reducers/courseuploadfiles/actions';
import {
  RequestUploadFilesAction,
  RequestDeleteFilesAction,
  RequestSubmitUploadFilesAction,
  REQUEST_SUBMIT_UPLOAD_FILE,
  REQUEST_UPLOAD_FILE,
  REQUEST_DELETE_FILE,
  UploadFile,
} from 'reducers/courseuploadfiles/types';

export function* requestUploadFile(
  formData: RequestUploadFilesAction,
): Generator {
  try {
    const data = (yield call(uploadFile, formData.uploadFiles, formData.config)) as UploadFile;
    data.fileId = formData.fileId;
    yield put(uploadFilesOnSuccess(data));
  } catch (e) {
    if (!e) return;
    yield put(uploadFilesOnError(e[0], formData.fileId));
  }
}

export function* requestDeleteFile(
  action: RequestDeleteFilesAction,
): Generator {
  try {
    yield call(deleteFile, action.file.id);
    yield put(deleteFilesOnSuccess(action.file));
  } catch (e) {
    yield put(deleteFilesOnError(e));
  }
}

export function* requestSubmitUploadFiles(
  action: RequestSubmitUploadFilesAction,
): Generator {
  try {
    yield call(submitUploadFiles, action.submitUploadFiles);
    yield put(submitUploadFilesOnSuccess());
  } catch (e) {
    yield put(submitUploadFilesOnError(e));
  }
}

export default function* watchUploadFilesSaga(): Generator {
  yield all([
    takeEvery(REQUEST_UPLOAD_FILE, requestUploadFile),
    takeEvery(REQUEST_DELETE_FILE, requestDeleteFile),
    takeEvery(REQUEST_SUBMIT_UPLOAD_FILE, requestSubmitUploadFiles),
  ]);
}
