/* eslint-disable linebreak-style */
import {
  REQUEST_GET_LIST_COURSE_FILES,
  GET_LIST_COURSE_FILES_ON_ERROR,
  GET_LIST_COURSE_FILES_ON_SUCCESS,
  REQUEST_GET_FILE_ALL_VERSIONS,
  ListCourseFileActionTypes,
  ListCourseFilesResponse,
} from './types';

import { ErrorsResponse } from 'reducers/auth/types';

export const requestGetListCourseFiles = (
  folferId: number,
): ListCourseFileActionTypes => ({
  type: REQUEST_GET_LIST_COURSE_FILES,
  folferId,
});

export const getListCourseFilesOnError = (
  errors: ErrorsResponse,
): ListCourseFileActionTypes => ({
  type: GET_LIST_COURSE_FILES_ON_ERROR,
  errors,
});

export const getListCourseFilesOnSuccess = (
  data: ListCourseFilesResponse[],
): ListCourseFileActionTypes => ({
  type: GET_LIST_COURSE_FILES_ON_SUCCESS,
  data,
});

export const requestGetFileAllVersions = (
  fileId: number,
  userType: number,
  participantId?: number,
): ListCourseFileActionTypes => ({
  type: REQUEST_GET_FILE_ALL_VERSIONS,
  fileId,
  userType,
  participantId,
});
