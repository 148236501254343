import {
  REQUEST_GET_INFO_REGISTRATION,
  GET_INFO_REGISTRATION_SUCCESS,
  GET_INFO_REGISTRATION_ERROR,
  RequestGetInfoRegistration,
  RequestGetInfoRegistrationSuccess,
  RequestGetInfoRegistrationError,
  DataInfoRegister,
  ErrorsResponse,
} from './types';

export const requestGetInfoRegistrations = (
): RequestGetInfoRegistration => ({
  type: REQUEST_GET_INFO_REGISTRATION,
});

export const requestGetInfoRegistrationsSuccess = (
  data: DataInfoRegister,
): RequestGetInfoRegistrationSuccess => ({
  type: GET_INFO_REGISTRATION_SUCCESS,
  data,
});

export const requestGetInfoRegistrationsError = (
  errors: ErrorsResponse,
): RequestGetInfoRegistrationError => ({
  type: GET_INFO_REGISTRATION_ERROR,
  errors,
});
