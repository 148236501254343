import {
  all, call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';

import {
  getEmployerNotification, getExpertNotification, getParticipantNotification, postNotification,
} from 'apis/requests/notification';
import {
  successGetNotification,
  errorGetNotification,
  successPostNotification,
  errorPostNotification,
} from 'reducers/notification/actions';
import {
  GET_NOTIFICATION_REQUEST,
  POST_SEEN_NOTIFICATION_REQUEST,
  DataNotificationResponse,
  RequestGetNotificationAction,
} from 'reducers/notification/types';

function* requestGetNotificationSaga(action: RequestGetNotificationAction): Generator {
  try {
    let data;
    switch (action.personType) {
      case 1:
        data = (yield call(getParticipantNotification)) as DataNotificationResponse[];
        yield put(successGetNotification(data));
        break;
      case 2:
        data = (yield call(getExpertNotification)) as DataNotificationResponse[];
        yield put(successGetNotification(data));
        break;
      case 3:
        data = (yield call(getEmployerNotification)) as DataNotificationResponse[];
        yield put(successGetNotification(data));
        break;
      default:
        break;
    }
  } catch (e) {
    yield put(errorGetNotification(e));
  }
}

function* requestPostNotificationSaga(): Generator {
  try {
    yield call(postNotification);
    yield put(successPostNotification());
  } catch (e) {
    yield put(errorPostNotification(e));
  }
}

export default function* watchGetNotificationSaga(): Generator {
  yield all([
    takeLatest(GET_NOTIFICATION_REQUEST, requestGetNotificationSaga),
    takeEvery(POST_SEEN_NOTIFICATION_REQUEST, requestPostNotificationSaga),
  ]);
}
