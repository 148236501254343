import { ErrorsResponse, SlugCourse } from 'reducers/expertcourse/types';
import { ItemPhase } from 'reducers/pages/home/types';

export const REQUEST_GET_COURSE_PROGRESS = 'REQUEST_GET_COURSE_PROGRESS';
export const GET_COURSE_PROGRESS_SUCCESS = 'GET_COURSE_PROGRESS_SUCCESS';
export const GET_COURSE_PROGRESS_ERROR = 'GET_COURSE_PROGRESS_ERROR';
export const CLEAR_COURSE_PROGRESS = 'CLEAR_COURSE_PROGRESS';
export interface Expert {
  id: number;
  firstName: string;
  lastName: string;
  intro: null | string;
  email: string;
  type: number;
  gender: number;
  image: null | string;
  dob: string;
  location: null | string;
  occupation: null | string;
  technical: null | string;
  language: null | string;
  active: number;
  emailVerifiedAt: string;
  rememberToken: null | string;
}
export interface Assessment {
  id: number;
  active: number;
  displayOrder: number;
  phaseId: number;
  participantId: number;
  expertId: number;
  avgScore: number;
  expertComment: string;
  expert: Expert;
  createdAt?: string;
}
export interface ResponseData {
  data: ItemPhase[];
}
export interface RequestGetProgressAction {
  type: typeof REQUEST_GET_COURSE_PROGRESS;
  payload: SlugCourse;
}
export interface GetProgressSuccessAction {
  type: typeof GET_COURSE_PROGRESS_SUCCESS;
  payload: ItemPhase[];
}
export interface GetProgressErrorAction {
  type: typeof GET_COURSE_PROGRESS_ERROR;
  payload: ErrorsResponse;
}
export interface ClearProgressAction {
  type: typeof CLEAR_COURSE_PROGRESS;
}
export type GetProgressActionTypes = RequestGetProgressAction
  | GetProgressSuccessAction
  | GetProgressErrorAction
  | ClearProgressAction;
