import {
  DataDetailCourseResponse,
  TypeDetailCourseActions,
  REQUEST_GET_DETAIL_COURSE,
  GET_DETAIL_COURSE_SUCCESS,
  GET_DETAIL_COURSE_ERROR,
  ErrorsResponse,
  CLEAN_UP_DETAIL_COURSE,
  UPDATE_DETAIL_COURSE,
} from './types';

interface InitStateType{
  loading: boolean;
  data: DataDetailCourseResponse|null;
  error: ErrorsResponse|null;
}
const initState: InitStateType = {
  loading: false,
  data: null,
  error: null,
};
export default (state = initState, action: TypeDetailCourseActions): InitStateType => {
  switch (action.type) {
    case CLEAN_UP_DETAIL_COURSE:
      return initState;
    case REQUEST_GET_DETAIL_COURSE:
      return {
        ...state, loading: true,
      };
    case GET_DETAIL_COURSE_SUCCESS:
      return {
        ...state, loading: false, data: action.payload,
      };
    case GET_DETAIL_COURSE_ERROR:
      return {
        ...state, loading: false, error: action.payload,
      };
    case UPDATE_DETAIL_COURSE:
      if (state.data && state.data.phases.length > 0) {
        return {
          ...state,
          data: {
            ...state.data,
            phases: state.data.phases.map((x) => {
              if (x.id === action.payload.id) {
                return action.payload;
              }
              return x;
            }),
          },
        };
      }
      return state;
    default:
      return state;
  }
};
