import { ErrorsResponse } from '../account/types';

import { DataPublishPortfolioListResponse, LinksResponse, ListCoursesRequest } from 'reducers/pages/courses/types';

export const GET_PUBLISHPORTFOLIO_LIST_REQUEST = 'GET_PUBLISHPORTFOLIO_LIST_REQUEST';
export const GET_PUBLISHPORTFOLIO_LIST_SUCCESS = 'GET_PUBLISHPORTFOLIO_LIST_SUCCESS';
export const GET_PUBLISHPORTFOLIO_LIST_ERROR = 'GET_PUBLISHPORTFOLIO_LIST_ERROR';
export const PUBLISHPORTFOLIO_LIST_RESET = 'PUBLISHPORTFOLIO_LIST_RESET';

// Interface
export interface MetaPublishPortfolioListResponse {
  totalPages: number;
  totalRecords: number;
}

export interface PublishPortfolioListResponse {
  meta: MetaPublishPortfolioListResponse | null;
  data: DataPublishPortfolioListResponse[] | [];
  links: LinksResponse | null;
}

export interface PublishPortfolioListState {
  loading: boolean;
  errors: ErrorsResponse | null;
  meta: MetaPublishPortfolioListResponse | null;
  data: DataPublishPortfolioListResponse[];
  links: LinksResponse | null;
}

// Action
export interface RequestGetPublishPortfolioListAction {
  type: typeof GET_PUBLISHPORTFOLIO_LIST_REQUEST;
  request: ListCoursesRequest;
}

interface SuccessGetPublishPortfolioListAction {
  type: typeof GET_PUBLISHPORTFOLIO_LIST_SUCCESS;
  response: PublishPortfolioListResponse;
}

interface ErrorGetPublishPortfolioListAction {
  type: typeof GET_PUBLISHPORTFOLIO_LIST_ERROR;
  errors: ErrorsResponse;
}

interface ResetGetPublishPortfolioListAction {
  type: typeof PUBLISHPORTFOLIO_LIST_RESET;
}

export type GetPublishPortfolioListActionTypes =
  | RequestGetPublishPortfolioListAction
  | SuccessGetPublishPortfolioListAction
  | ErrorGetPublishPortfolioListAction
  | ResetGetPublishPortfolioListAction;
