import {
  ContactActionTypes,
  REQUEST_FETCH_ABOUT_YOU,
  FETCH_ABOUT_YOU_SUCCESS,
  FETCH_ABOUT_YOU_ERROR,
  AboutYouResponse,
  ErrorsResponse,
} from './types';

export const requestFetchAboutYou = (): ContactActionTypes => ({
  type: REQUEST_FETCH_ABOUT_YOU,
});

export const fetchAboutYouSuccess = (
  response: AboutYouResponse[],
): ContactActionTypes => ({
  type: FETCH_ABOUT_YOU_SUCCESS,
  response,
});

export const fetchAboutYouError = (
  errors: ErrorsResponse[],
): ContactActionTypes => ({
  type: FETCH_ABOUT_YOU_ERROR,
  errors,
});
