import {
  StateType,
  GetSystemActionTypes,
  REQUEST_GET_SYSTEM,
  GET_SYSTEM_ON_SUCCESS,
  GET_SYSTEM_ON_ERROR,
} from './types';

const defaultState: StateType = {
  data: null,
  loading: false,
  error: null,
};

export default (state = defaultState,
  action: GetSystemActionTypes): StateType => {
  switch (action.type) {
    case REQUEST_GET_SYSTEM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SYSTEM_ON_SUCCESS:
      return {
        ...state,
        data: action.response,
        loading: false,
        error: null,
      };
    case GET_SYSTEM_ON_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.errors,
      };
    default:
      return state;
  }
};
