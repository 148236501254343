import {
  GET_PUBLISHPORTFOLIO_LIST_REQUEST,
  GET_PUBLISHPORTFOLIO_LIST_SUCCESS,
  GET_PUBLISHPORTFOLIO_LIST_ERROR,
  PUBLISHPORTFOLIO_LIST_RESET,
  GetPublishPortfolioListActionTypes,
  PublishPortfolioListState,
} from './types';

const defaultState: PublishPortfolioListState = {
  loading: false,
  errors: null,
  meta: null,
  data: [],
  links: null,
};

export default (
  state = defaultState,
  action: GetPublishPortfolioListActionTypes,
): PublishPortfolioListState => {
  switch (action.type) {
    case GET_PUBLISHPORTFOLIO_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case GET_PUBLISHPORTFOLIO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        meta: action.response.meta,
        data: [...action.response.data],
        links: action.response.links,
      };
    case GET_PUBLISHPORTFOLIO_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case PUBLISHPORTFOLIO_LIST_RESET:
      return defaultState;
    default:
      return state;
  }
};
