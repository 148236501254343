import {
  ListParticipantsActionsType,
  REQUEST_GET_LIST_PARTICIPANTS,
  GET_LIST_PARTICIPANTS_SUCCESS,
  GET_LIST_PARTICIPANTS_ERROR,
  CLEAR_LIST_PARTICIPANTS,
  ListParticipantsInitState,
} from './types';

const initState: ListParticipantsInitState = {
  loading: false,
  data: [],
  links: null,
  meta: null,
  error: null,
};
export default (state = initState, action: ListParticipantsActionsType):
  ListParticipantsInitState => {
  switch (action.type) {
    case REQUEST_GET_LIST_PARTICIPANTS:
      return {
        ...state, loading: true,
      };
    case GET_LIST_PARTICIPANTS_SUCCESS: {
      if (action.payload.isShowMore) {
        return {
          ...state,
          meta: action.payload.meta,
          data: [...state.data, ...action.payload.data],
          links: action.payload.links,
          loading: false,
        };
      }
      return {
        ...state,
        meta: action.payload.meta,
        data: action.payload.data,
        links: action.payload.links,
        loading: false,
      };
    }
    case GET_LIST_PARTICIPANTS_ERROR:
      return {
        ...state, loading: false, error: action.payload,
      };
    case CLEAR_LIST_PARTICIPANTS:
      return initState;
    default:
      return state;
  }
};
