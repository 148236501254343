import {
  ErrorsResponse,
  REQUEST_USER_SIGNIN,
  SIGNIN_ON_ERROR,
  SIGNIN_ON_SUCCESS,
  REQUEST_USER_LOGOUT,
  REQUEST_CHECK_USER_SIGNIN,
  UserSignInActionTypes,
  UserSignInRequest,
  UserSigninResponse,
  SUBMITTED_SUBSCRIBE_PACKAGE,
} from './types';


export const requestUserSignIn = (
  userSignIn: UserSignInRequest,
): UserSignInActionTypes => ({
  type: REQUEST_USER_SIGNIN,
  userSignIn,
});

export const userSignInOnSuccess = (
  userResponse: UserSigninResponse,
): UserSignInActionTypes => ({
  type: SIGNIN_ON_SUCCESS,
  userResponse,
});
export const userSignInOnError = (
  errors: ErrorsResponse,
): UserSignInActionTypes => ({
  type: SIGNIN_ON_ERROR,
  errors,
});

export const requestLogoutAction = (
): UserSignInActionTypes => ({
  type: REQUEST_USER_LOGOUT,
});

export const requestCheckSignInAction = (): UserSignInActionTypes => ({
  type: REQUEST_CHECK_USER_SIGNIN,
});

export const submittedSubscribePackage = (): UserSignInActionTypes => ({
  type: SUBMITTED_SUBSCRIBE_PACKAGE,
});
