import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import { getProgress } from 'apis/requests/detailcourse';
import {
  getCourseProgressSuccess,
  getCourseProgressError,
} from 'reducers/detailcourse/progess/actions';
import {
  REQUEST_GET_COURSE_PROGRESS,
  RequestGetProgressAction,
} from 'reducers/detailcourse/progess/types';
import { ItemPhase } from 'reducers/pages/home/types';


function* fetchDetailCourseSaga(action: RequestGetProgressAction): Generator {
  try {
    const data = (yield call(getProgress, action.payload)) as ItemPhase[];
    yield put(getCourseProgressSuccess(data));
  } catch (errors) {
    yield put(getCourseProgressError(errors[0]));
  }
}
export default function* watchFetchDetailCourseSaga(): Generator {
  yield takeLatest(REQUEST_GET_COURSE_PROGRESS, fetchDetailCourseSaga);
}
