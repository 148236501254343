import { ErrorsResponse } from '../account/types';
import { Phase } from '../detailcourse/phase/types';
import { DataListCoursesResponse } from '../pages/courses/types';
import { ProfileResponse } from '../profile/types';

import { Project } from 'apis/requests/workFolder';

export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_ERROR = 'GET_NOTIFICATION_ERROR';

export const POST_SEEN_NOTIFICATION_REQUEST = 'POST_SEEN_NOTIFICATION_REQUEST';
export const POST_SEEN_NOTIFICATION_SUCCESS = 'POST_SEEN_NOTIFICATION_SUCCESS';
export const POST_SEEN_NOTIFICATION_ERROR = 'POST_SEEN_NOTIFICATION_ERROR';

export interface MetaNotificationResponse {
  participant: ProfileResponse;
  employer: ProfileResponse;
  contact: {
    accountId: number;
    companyName: string;
    courseId: number;
    email: string;
    message: string;
    name: string;
    phone: string;
  };
  project: Project;
}

export interface PhaseFolder {
  id: number;
  phaseId: number;
  name: string;
  accountId: number;
  userId: number;
  maxSize: number;
  minSize: number;
  maxFiles: number;
  minFiles: number;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
}

export interface NotificationTranslations {
  content: string;
  id: number;
  locale: string;
  notificationId: number;
  title: string;
}

export interface DataNotificationResponse {
  metaData: MetaNotificationResponse;
  id: number;
  accountId: number;
  courseId: number;
  phaseId: number;
  phaseFolderId: number;
  seen: number;
  title: string;
  content: string;
  type: number;
  displayOrder: number;
  active: number;
  course: DataListCoursesResponse;
  phase: Phase;
  createdAt: string;
  updatedAt: string;
  phaseFolder?: PhaseFolder;
  notificationTranslations: NotificationTranslations[];
}

export interface RequestGetNotificationAction {
  type: typeof GET_NOTIFICATION_REQUEST;
  personType: number;
}

export interface SuccessGetNotificationAction {
  type: typeof GET_NOTIFICATION_SUCCESS;
  response: DataNotificationResponse[];
}

export interface ErrorGetNotificationAction {
  type: typeof GET_NOTIFICATION_ERROR;
  errors: ErrorsResponse[];
}

export interface NotificationState {
  loading: boolean;
  list: DataNotificationResponse[] | [];
  errors: ErrorsResponse[] | null;
  seenNotification: boolean;
}

export interface RequestPostNotificationAction {
  type: typeof POST_SEEN_NOTIFICATION_REQUEST;
}

export interface SuccessPostNotificationAction {
  type: typeof POST_SEEN_NOTIFICATION_SUCCESS;
}

export interface ErrorPostNotificationAction {
  type: typeof POST_SEEN_NOTIFICATION_ERROR;
  errors: ErrorsResponse[];
}

export type GetNotificationActionTypes =
| RequestGetNotificationAction
| SuccessGetNotificationAction
| ErrorGetNotificationAction
| RequestPostNotificationAction
| SuccessPostNotificationAction
| ErrorPostNotificationAction;
