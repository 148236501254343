import { AnyAction } from 'redux';

import { REQUEST_DOWNLOAD_FILE } from 'actions/common/download';

const defaultState = {
  data: {},
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_DOWNLOAD_FILE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
