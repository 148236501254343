export const REQUEST_GET_COURSE_EXPERT = 'REQUEST_GET_COURSE_EXPERT ';
export const GET_COURSE_EXPERT_SUCCESS = 'GET_COURSE_EXPERT_SUCCESS';
export const GET_COURSE_EXPERT_ERROR = 'GET_COURSE_EXPERT_ERROR';
export const CLEAR_COURSE_EXPERT = 'CLEAR_COURSE_EXPERT';
interface ShareCourseExpertType {
  id: number;
  displayOrder: number;
  createdAt: string;
  updatedAt: string;
  startDate: string;
  endDate: string;
  image: string;
  active: number;
}

export interface TranslateCourseAndPhase {
  course: CourseTranslationsType;
  phase: PhasesTranslationsType;
}
interface ShareTranslationsType {
  id: number;
  locale: string;
  name: string;
  slug: string;
  description: string | null;
  title: string;
}
export interface CourseTranslationsType extends ShareTranslationsType {
  courseId: number;
  about: null | string;
  support: null | string;
}
export interface PhasesTranslationsType extends ShareTranslationsType {
  phaseId: number;
}
interface PhasesItemType extends ShareCourseExpertType {
  startDateOfAssessment: string;
  endDateOfAssessment: string;
  video: string;
  courseId: number;
  totalParticipant: number;
  phasesTranslations: PhasesTranslationsType[];
}
export interface ResponseDataType extends ShareCourseExpertType {
  courseManagement: null | string;
  courseFormats: null | string;
  supportAndAdvices: null | string;
  suppliers: null | string;
  isHomepage: 0;
  courseTranslations: CourseTranslationsType[];
  phases: PhasesItemType[];
}
export interface ErrorsResponse {
  code: string;
  title: string;
  source: string;
}
export type Slug={
  slugcourse: string;
  slugphase: string;
}
export type SlugCourse = string;
export interface RequestCourseExpertActionType {
  type: typeof REQUEST_GET_COURSE_EXPERT;
  payload: SlugCourse;
}
export interface GetCourseExpertSuccessActionType {
  type: typeof GET_COURSE_EXPERT_SUCCESS;
  payload: ResponseDataType;
}
export interface GetCourseExpertErrorActionType {
  type: typeof GET_COURSE_EXPERT_ERROR;
  payload: ErrorsResponse;
}

export interface ClearCourseExpertActionType {
  type: typeof CLEAR_COURSE_EXPERT;
}
export type CourseExpertActionsType = RequestCourseExpertActionType
  | GetCourseExpertSuccessActionType
  | GetCourseExpertErrorActionType
  | ClearCourseExpertActionType;
