import { combineReducers } from 'redux';

import acceptpolicy from './acceptpolicy';
import account from './account';
import auth from './auth';
import common from './common';
import country from './country';
import coursefile from './coursefile';
import courseuploadfiles from './courseuploadfiles';
import detailcourse from './detailcourse';
import expertcourse from './expertcourse';
import expertpublishportfolio from './expertpublishportfolio';
import listparticipants from './listparticipants';
import loading from './loading';
import modalerror from './modalerror';
import notification from './notification';
import pages from './pages';
import profile from './profile';
import infoRegistration from './register';
import system from './system';

export default combineReducers({
  pages,
  common,
  auth,
  account,
  coursefile,
  profile,
  detailcourse,
  courseuploadfiles,
  expertcourse,
  listparticipants,
  expertpublishportfolio,
  notification,
  loading,
  acceptpolicy,
  modalerror,
  system,
  infoRegistration,
  country,
});
