import {
  REQUEST_GET_COURSE_EXPERT,
  GET_COURSE_EXPERT_SUCCESS,
  GET_COURSE_EXPERT_ERROR,
  CLEAR_COURSE_EXPERT,
  CourseExpertActionsType,
  ErrorsResponse,
  ResponseDataType,
} from './types';

interface InitStateType{
  loading: boolean;
  data: ResponseDataType|null;
  error: ErrorsResponse|null;
}
const initState: InitStateType = {
  loading: false,
  data: null,
  error: null,
};
export default (state = initState, action: CourseExpertActionsType): InitStateType => {
  switch (action.type) {
    case REQUEST_GET_COURSE_EXPERT:
      return {
        ...state, loading: true,
      };
    case GET_COURSE_EXPERT_SUCCESS:
      return {
        ...state, loading: false, data: action.payload,
      };
    case GET_COURSE_EXPERT_ERROR:
      return {
        ...state, loading: false, error: action.payload,
      };
    case CLEAR_COURSE_EXPERT:
      return initState;
    default:
      return state;
  }
};
