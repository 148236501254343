import {
  SlugCourse, ErrorsResponse,
} from '../phase/types';

import {
  REQUEST_GET_COURSE_PROGRESS,
  GET_COURSE_PROGRESS_SUCCESS,
  GET_COURSE_PROGRESS_ERROR,
  CLEAR_COURSE_PROGRESS,
  GetProgressActionTypes,
} from './types';

import { ItemPhase } from 'reducers/pages/home/types';

export const requestGetProgress = (slug: SlugCourse): GetProgressActionTypes => ({
  type: REQUEST_GET_COURSE_PROGRESS,
  payload: slug,
});
export const getCourseProgressSuccess = (data: ItemPhase[]):
GetProgressActionTypes => ({
  type: GET_COURSE_PROGRESS_SUCCESS,
  payload: data,
});
export const getCourseProgressError = (error: ErrorsResponse):
GetProgressActionTypes => ({
  type: GET_COURSE_PROGRESS_ERROR,
  payload: error,
});

export const clearCourseProgress = ():
GetProgressActionTypes => ({
  type: CLEAR_COURSE_PROGRESS,
});
