export type State = {
  modal: {
    register: boolean;
    login: boolean;
    forgot: boolean;
  };
};

export enum ActionType {
  Toggle
}

export enum ModalType {
  Register,
  Login,
  Forgot
}

export type PayloadToggleType = {
  modal: ModalType;
  status: boolean;
}

export type ToggleModalType = {
  type: ActionType.Toggle;
  payload: PayloadToggleType;
};

export type ModalActions = ToggleModalType;
