import { calculationCourseProcess, smoothScrollTo } from './func';

import { cmsHttp } from 'apis/instance';
import { IconName } from 'components/atoms/Icon';
import i18n from 'i18n';
import imgFile from 'images/icons/icon_file.svg';
import imgMp4 from 'images/icons/icon_mp4.svg';
import imgPdf from 'images/icons/icon_pdf.svg';
import imgPhoto from 'images/icons/icon_photo.svg';
import imgXlsx from 'images/icons/icon_xlsx.svg';
import { Criteria, DataAssessmentCourseResponse } from 'reducers/detailcourse/criteria/types';
import {
  Phase,
  CourseTranslations,
  DataDetailCourseResponse,
} from 'reducers/detailcourse/phase/types';
import { ItemPhase, PhasesTranslations } from 'reducers/pages/home/types';

export const nonAccentVietnamese = (str: string): string => str
  .toLowerCase()
  .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  .replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  .replace(/đ/g, 'd')
  // Some system encode vietnamese combining accent as individual utf-8 characters
  .replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // Huyền sắc hỏi ngã nặng
  .replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư

export function getParams(): { [key: string]: string } {
  return window.location.search
    .split(/[?&]/)
    .map((s) => s.split('='))
    .filter((a) => a.length === 2)
    .map(([k, v]) => [decodeURIComponent(k), decodeURIComponent(v)])
    .reduce(
      (a, [k, v]) => ({
        ...a,
        [k]: v,
      }),
      {},
    );
}

export function formatBytes(bytes: number, decimals = 1): string {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // eslint-disable-next-line no-restricted-properties
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}

export const allowedExtensions = /\.(jpg|png|jpeg|xlsx|pdf|mp4)$/i;

export function checkFormatFileUpload(fileType: string): IconName {
  if (fileType.includes('sheet') || fileType.includes('excel')) {
    return 'xlsx';
  }
  if (fileType.includes('pdf')) {
    return 'pdf';
  }
  if (fileType.includes('mp4')) {
    return 'mp4';
  }
  if (fileType.includes('image')) {
    return 'photo';
  }
  return 'file';
}

export const getThumnailFile = (extension: string): string => {
  switch (extension.toLocaleLowerCase()) {
    case 'jpg':
    case 'png':
    case 'jpeg':
      return imgPhoto;
    case 'pdf':
      return imgPdf;
    case 'xlsx':
    case 'xls':
      return imgXlsx;
    case 'mp4':
      return imgMp4;
    default:
      return imgFile;
  }
};

export const checkImgSrc = (def: string, url?: string|null): string => {
  if (url) {
    return cmsHttp + url;
  }
  return def;
};

export const hrefPolicy = '/documents/copyright/copyright info  - PATF.pdf';

export const getPercentOfProcess = (phase?: Phase): number | string => {
  if (phase && phase.accounts.length > 0) {
    const {
      percent,
    } = calculationCourseProcess(phase.accounts[0].AccountPhasesModel.startDate,
      (phase.accounts[0].AccountPhasesModel.endDate || ''));
    if (percent) {
      return `${Math.floor(percent)}%`;
    }
  }
  return '';
};

export const getNameCourse = (phase: DataDetailCourseResponse|null, language: string): string => {
  if (phase && phase.courseTranslations) {
    const course = phase.courseTranslations.find((item) => item.locale === language);
    if (course) {
      return course.name;
    }
  }
  return '';
};

export const getNamePhase = (language: string, phase?: Phase): string => {
  if (phase) {
    const phaseByName = phase.phasesTranslations.find((item) => item.locale === language);
    if (phaseByName) {
      return phaseByName.name;
    }
  }
  return '';
};

export const getCourseTranslations = (phase: DataDetailCourseResponse | null,
  language: string): CourseTranslations | undefined => {
  if (phase && phase.courseTranslations) {
    const course = phase.courseTranslations.find((item) => item
      .locale === language);
    return course;
  }
  return undefined;
};

export const excuteScroll = (ele: React.MutableRefObject<HTMLDivElement|null>,
  time = 1500): void => {
  if (ele.current) {
    smoothScrollTo(ele.current.offsetTop, time);
  }
};

export const checkNull = (condition: any, callback?: () => void, callback2?: () => void): void => {
  if (condition && callback) { callback(); return; }
  if (callback2) { callback2(); }
};

export const isCheckSrocePhaseNearTotal = (dataAssessment:
  DataAssessmentCourseResponse): boolean => {
  if (dataAssessment.listPhases
      && dataAssessment.listPhases[dataAssessment.listPhases.length - 1].accounts.length > 0
      && dataAssessment.listPhases[dataAssessment.listPhases.length - 1].accounts[0]
        .AccountPhasesModel.assessment
  ) return true;
  return false;
};

export const checkDocumentsCourse = (phase: DataDetailCourseResponse): boolean => {
  if (phase.courseManagement instanceof Array
  || phase.courseFormats instanceof Array
  || phase.supportAndAdvices instanceof Array
  || phase.suppliers instanceof Array
  ) return false;
  return true;
};

export const checkClassScoreTable = (
  dataAssessment: DataAssessmentCourseResponse,
  phaseIdActive: number|string,
  item: Criteria,
): string => (
  phaseIdActive === 'total' ? 'active' : item.avgMark && isCheckSrocePhaseNearTotal(dataAssessment) ? '' : 'score-mark'
);

export const getDistanceScroll = (callback: (distance: number) => void): void => {
  if (document.getElementsByClassName('o-footer').length <= 0) { return; }
  const topFooter = document.getElementsByClassName('o-footer')[0].getBoundingClientRect().top;
  const heightWindown = window.innerHeight;
  if (topFooter <= heightWindown) {
    callback(heightWindown - topFooter + 30);
    return;
  }
  callback(30);
};

export const checkUser = (isViewProfile?: boolean, type?: number|null): { occupation: boolean;
  skills: boolean;
  location: boolean;
  contact: boolean;
  language: boolean;} => {
  if (isViewProfile) {
    return {
      occupation: true,
      skills: true,
      location: true,
      contact: false,
      language: true,
    };
  }
  if (type === 3) {
    return {
      occupation: false,
      skills: false,
      location: true,
      contact: true,
      language: false,
    };
  }
  return {
    occupation: true,
    skills: true,
    location: true,
    contact: false,
    language: true,
  };
};

export const checkDocumentIsArray = (documents: ItemDocument[]): boolean => {
  if (documents && documents instanceof Array && documents.length > 0) return true;
  return false;
};

export const getTranslationPhase = (language: string,
  phase: Phase): PhasesTranslations => {
  const phaseByName = phase.phasesTranslations.find((item) => item.locale === language);
  if (phaseByName) return phaseByName;
  return phase.phasesTranslations[0];
};

export const getSlugCourse = (language: string,
  course: DataDetailtCourse): string => {
  const courseFind = course.courseTranslations.find((item) => item.locale === language);
  if (courseFind) return courseFind.slug;
  return '';
};

export const getSlugPhase = (language: string,
  phase: Phase): string => {
  const phaseFind = phase.phasesTranslations.find((item) => item.locale === language);
  if (phaseFind) return phaseFind.slug;
  return '';
};

export const getItemPhaseByLocale = (phase: ItemPhase): PhasesTranslations|undefined => {
  const phaseTranslateFind = phase.phasesTranslations.find((item) => item.locale === i18n.language);
  if (phaseTranslateFind) return phaseTranslateFind;
  return undefined;
};

export const getTranslate = <T extends {locale: string}>(translates: T[]): T => {
  const translateFind = translates.find((item) => item.locale === i18n.language);
  if (translateFind) return translateFind;
  return translates[0];
};

// https://stackoverflow.com/questions/54399084/change-locale-in-react-datepicker
const months = ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'];
const days = ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'];
export const locale = {
  localize: {
    month: (n: any): string => months[n],
    day: (n: any): string => days[n],
  },
  formatLong: {},
};

export const getUrlWithRole = (type?: number|null): string => {
  if (type === 1) {
    return '/participant/list-course';
  }
  if (type === 2) {
    return '/expert/courses';
  }
  if (type === 3) {
    return '/employer/courses';
  }
  return '/';
};
