import {
  ListParticipantsActionsType,
  REQUEST_GET_LIST_PARTICIPANTS,
  GET_LIST_PARTICIPANTS_SUCCESS,
  GET_LIST_PARTICIPANTS_ERROR,
  CLEAR_LIST_PARTICIPANTS,
  DataResponseType,
  ErrorsResponse,
  QueryParamsType,
} from './types';

export const requestListParticipants = (slug: QueryParamsType): ListParticipantsActionsType => ({
  type: REQUEST_GET_LIST_PARTICIPANTS,
  payload: slug,
});
export const getListParticipantsSuccess = (data: DataResponseType):
ListParticipantsActionsType => ({
  type: GET_LIST_PARTICIPANTS_SUCCESS,
  payload: data,
});
export const getListParticipantsError = (error: ErrorsResponse):
ListParticipantsActionsType => ({
  type: GET_LIST_PARTICIPANTS_ERROR,
  payload: error,
});
export const requestClearListParticipants = (): ListParticipantsActionsType => ({
  type: CLEAR_LIST_PARTICIPANTS,
});
