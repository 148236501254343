/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useFormContext, get, Controller } from 'react-hook-form';
import Select, {
  OptionsType, GroupedOptionsType, Props as SelectProps, components,
} from 'react-select';

import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';

interface Props extends SelectProps{
  options: OptionsType<any> | GroupedOptionsType<any>;
  placeholder?: string;
  isDisabled?: boolean;
  error?: string;
  name?: string;
  isMulti?: boolean;
  isDisableCustom?: boolean;
}

const PullDown: React.FC<Props> = ({
  name,
  options,
  isDisabled,
  isSearchable,
  error,
  placeholder,
  isMulti,
  isDisableCustom,
  ...innerProps
}) => {
  const DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <Icon iconName={props.selectProps.menuIsOpen ? 'up-orange-small' : 'down-dark-small'} />
    </components.DropdownIndicator>
  );
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      padding: 0,
      borderColor: error ? '#ff636d'
        : (state.isFocused || state.isSelected) ? '#ffc371' : '#ddd',
      outline: 'none',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'none',
      },
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    option: (provided: any, state: any) => ({
      ...provided,
      borderBottom: '1px solid #ECF2F9',
      color: '#8A9099',
      padding: 10,
      backgroundColor:
        state.isFocused || state.isSelected ? '#f4f6fa'
          : state.isDisabled ? '#f2f2f2' : 'transparent',
      '&:active': {
        backgroundColor: '#fafafa',
      },
      cursor: state.isDisabled ? 'not-allowed' : 'default',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      padding: '0',
      lineHeight: '20px',
      fontSize: '16px',
      color: error ? '#ff636d' : '#8A9099',
      fontWeight: '300',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      height: '44px',
      padding: '0 20px',
      display: isMulti ? 'flex' : provided.display,
      flexWrap: isMulti ? 'nowrap' : provided.flexWrap,
      overflow: isMulti ? 'auto' : provided.overflow,
      '::-webkit-scrollbar': {
        width: 2,
        height: 4,
      },
      '::-webkit-scrollbar-track': {
        background: '#fff',
        borderRadius: 3,
        marginLeft: 20,
      },
      '::-webkit-scrollbar-thumb': {
        background: '#66666696',
        borderRadius: 3,
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#666666',
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      lineHeight: '20px',
      fontSize: '16px',
      color: 'rgba(112, 112, 112, 0.7)',
      fontWeight: '300',
    }),
    container: (provided: any) => ({
      ...provided,
      borderRadius: '5px',
      background: '#fff',
      boxShadow: '0px 3px 10px rgba(92, 101, 114, 0.2)',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      flexShrink: 0,
      maxWidth: 100,
    }),
  };

  return (
    <div className={`m-pulldown ${isDisableCustom && 'm-pulldown-customDisabled'}`}>
      <Select
        {...innerProps}
        styles={customStyles}
        name={name}
        options={options}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isMulti={isMulti}
        components={{
          DropdownIndicator,
        }}
      />
      {error && (
      <div className="m-pulldown_errormessage">
        <Text size="fs12-lg" modifiers={['red-dark']}>
          {error}
        </Text>
      </div>
      )}
    </div>
  );
};
interface PulldownHookFormProps extends Props {
  name: string;
  isEnterOther?: boolean;
  type?: string;
  handleClickCancel?: () => void;
  placeholderInput?: string;
  nameInput?: string;
  defaultValueInput?: string;
}
export const PulldownHookForm: React.FC<PulldownHookFormProps> = ({
  handleClickCancel,
  nameInput,
  isDisableCustom,
  placeholderInput,
  isEnterOther,
  type,
  name,
  defaultValueInput,
  ...props
}) => {
  const { control, errors } = useFormContext();
  const hasError: Record<string, string> = get(errors, `${name}`);
  if (isEnterOther) {
    return (
      <Input
        defaultValue={defaultValueInput}
        handleClickIconCancel={handleClickCancel}
        modifiers={['login', 'disabled-default', 'cancel']}
        name={nameInput}
        type={type || 'text'}
        placeholder={placeholderInput}
      />
    );
  }
  return (
    <Controller
      options={props.options}
      control={control}
      name={name}
      defaultValue={props.value}
      render={({ ...innerProps }) => (
        <PullDown
          isDisableCustom={isDisableCustom}
          error={hasError && hasError.message}
          {...props}
          {...innerProps}
        />
      )}
    />
  );
};
export default PullDown;
