
import { lazy } from 'react';

import ROUTE_PATH from 'utils/common';

const Contact = lazy(() => import('pages/Contact'));
const EmployerCourses = lazy(() => import('pages/EmployerCourses'));
const EmployerListParticipants = lazy(() => import('pages/EmployerListParticipants'));
const EmployerViewParticipant = lazy(() => import('pages/EmployerViewParticipant'));
const ExpertCourses = lazy(() => import('pages/ExpertCourses'));
const ExpertPublishCourse = lazy(() => import('pages/ExpertPublishCourse'));
const ExpertScoreProcess = lazy(() => import('pages/ExpertScoreProcess'));
const ParticipantDetailCourse = lazy(() => import('pages/ParticipantDetailCourse'));
const Home = lazy(() => import('pages/Home'));
const Overview = lazy(() => import('pages/Overview'));
const Profile = lazy(() => import('pages/Profile'));
const Success = lazy(() => import('pages/Success'));
const ViewProfile = lazy(() => import('pages/ViewProfile'));
const NotFound = lazy(() => import('pages/NotFound'));
const ExpertListParticipant = lazy(() => import('pages/ExpertListParticipant'));
const ParticipantWorkFolder = lazy(() => import('pages/ParticipantWorkFolder'));
const CategoryWorkFolder = lazy(() => import('pages/ParticipantWorkFolder/Category'));
const EmployeeWorkFolder = lazy(() => import('pages/EmployeeWorkFolder'));
const OwnWorkDetail = lazy(() => import('pages/OwnWorkDetail'));
const ParticipantViewResultCourse = lazy(() => import('pages/ParticipantViewResultCourse'));
const ExpertViewParticipantWorkFolder = lazy(() => import('pages/ExpertViewWorkFolderParticipant'));
const ExpertViewEmployeeWorkFolder = lazy(() => import('pages/ExpertViewEmployeeWorkFolder'));
const ExpertViewProjectDetail = lazy(() => import('pages/ExpertViewProjectDetail'));
const SponsorViewParticipantWorkFolder = lazy(() => import('pages/SponsorViewWorkFolderParticipant'));
const SponsorViewEmployeeWorkFolder = lazy(() => import('pages/SponsorViewEmployeeWorkFolder'));
const SponsorViewProjectDetail = lazy(() => import('pages/SponsorViewProjectDetail'));

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/contact',
    component: Contact,
  },
  {
    path: '/register-success',
    component: Success,
  },
  {
    path: '/notfound',
    component: NotFound,
  },
  {
    path: '/participant/list-course',
    component: Overview,
    isPrivate: true,
  },
  {
    path: ROUTE_PATH.PARTICIPANT_WORK_FOLDER,
    component: ParticipantWorkFolder,
    isPrivate: true,
  },
  {
    path: '/participant/work-folder/category/:categoryId',
    component: CategoryWorkFolder,
    isPrivate: true,
  },
  {
    path: '/expert/work-folder/participant/:participantId/category/:categoryId',
    component: ExpertViewParticipantWorkFolder,
    isPrivate: true,
  },
  {
    path: ROUTE_PATH.EMPLOYEE_WORK_FOLDER,
    component: EmployeeWorkFolder,
    isPrivate: true,
  },
  {
    path: '/work-folder/own-work/:projectId',
    component: OwnWorkDetail,
    isPrivate: true,
  },
  {
    path: '/work-folder/own-work/category/:categoryId/project/:projectId',
    component: OwnWorkDetail,
    isPrivate: true,
  },
  {
    path: '/profile',
    component: Profile,
    isPrivate: true,
  },
  {
    path: '/participant/course/:slug',
    component: ParticipantDetailCourse,
    isPrivate: true,
  },
  {
    path: '/participant/course/:courseSlug/result',
    component: ParticipantViewResultCourse,
    isPrivate: true,
  },
  {
    path: '/expert/:courseSlug/participants',
    component: ExpertListParticipant,
    isPrivate: true,
  },
  {
    path: '/expert/courses/:courseSlug/:participantId',
    component: ExpertScoreProcess,
    isPrivate: true,
  },
  {
    path: '/expert/courses',
    component: ExpertCourses,
    isPrivate: true,
  },
  {
    path: '/expert-publish-portfolio',
    component: ExpertPublishCourse,
    isPrivate: true,
  },
  {
    path: '/employer/courses',
    component: EmployerCourses,
    isPrivate: true,
  },
  {
    path: '/employer/viewprofile/:idParticipant',
    component: ViewProfile,
    isPrivate: true,
  },
  {
    path: '/course/:courseSlug/employer/participant/portfolio/:participantId',
    component: EmployerViewParticipant,
    isPrivate: true,
  },
  {
    path: '/course/:slug/employer/participants',
    component: EmployerListParticipants,
    isPrivate: true,
  },
  {
    path: '/expert/work-folder/employee/:participantId',
    component: ExpertViewEmployeeWorkFolder,
    isPrivate: true,
  },
  {
    path: '/expert/project/:projectId',
    component: ExpertViewProjectDetail,
    isPrivate: true,
  },
  {
    path: '/sponsor/work-folder/participant/:participantId/category/:categoryId',
    component: SponsorViewParticipantWorkFolder,
    isPrivate: true,
  },
  {
    path: '/sponsor/work-folder/employee/:participantId',
    component: SponsorViewEmployeeWorkFolder,
    isPrivate: true,
  },
  {
    path: '/sponsor/project/:projectId',
    component: SponsorViewProjectDetail,
    isPrivate: true,
  },
];

export default routes;
