import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import { getAssessmentCourse } from 'apis/requests/detailcourse';
import {
  getAssessmentCourseError,
  getAssessmentCourseSuccess,
} from 'reducers/detailcourse/criteria/actions';
import {
  RequestAssessmentCourse,
  REQUEST_ASSESSMENT_COURSE,
  DataAssessmentCourseResponse,
} from 'reducers/detailcourse/criteria/types';


function* fetchAssessmentCourseSaga(action: RequestAssessmentCourse): Generator {
  try {
    const data = (yield call(getAssessmentCourse, action.payload)) as DataAssessmentCourseResponse;
    yield put(getAssessmentCourseSuccess(data));
  } catch (errors) {
    yield put(getAssessmentCourseError(errors[0]));
  }
}
export default function* watchFetchAssessmentCourseSaga(): Generator {
  yield takeLatest(REQUEST_ASSESSMENT_COURSE, fetchAssessmentCourseSaga);
}
