import { http, httpNode } from '../instance';

import { DataHomeCarousel, DataHomeCourse } from 'reducers/pages/home/types';

export const getDataHomeCarousel = async (): Promise<DataHomeCarousel> => {
  const response = await http.get('home-page');
  return response.data.data;
};

export const getDataHomeCourse = async (): Promise<DataHomeCourse> => {
  const response = await httpNode.get('course/homepage');
  return response.data.data;
};
