import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import { getDataHomeCarousel } from 'apis/requests/home';
import { getListCourse } from 'apis/requests/listcourses';
import { getDataHomePageCarouselOnSuccess } from 'reducers/pages/home/action';
import { DataHomeCarousel, REQUEST_GET_DATA_HOME } from 'reducers/pages/home/types';

function* requestGetDataHomeSaga(): Generator {
  const dataCarousel = (yield call(getDataHomeCarousel)) as DataHomeCarousel;
  const dataCourses = (yield call(getListCourse)) as DataDetailtCourse[];
  yield put(getDataHomePageCarouselOnSuccess(dataCarousel, dataCourses));
}

export default function* watchFetchHomePageSaga() {
  yield takeLatest(REQUEST_GET_DATA_HOME, requestGetDataHomeSaga);
}
