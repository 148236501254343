import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import Icon, { IconName } from '../Icon';

import mapModifiers from 'function/mapModifier';

export type ButtonTheme =
    | 'ovalFill'
    | 'ovalOutline'
    | 'ovalFillBlue'
    | 'ovalFillAlert'
    | 'uppercase'
    | 'puzzle'
    | 'transparent'
    | 'icon-rightSide'
    | 'icon-leftSide'
    | 'white'
    | 'blueDark'
    | 'fs32'
    | 'h45'
    | 'bold'
    | 'shadow'
    | 'pd-small'
interface ButtonProps {
    type?: 'button' | 'submit' | 'reset';
    href?: string;
    handleClick?: (event?: React.MouseEvent<HTMLButtonElement>,
                    arg?: string|number) => void;
    disabled?: boolean;
    modifiers?: ButtonTheme[];
    iconName?: IconName;
    style?: CSSProperties ;
}

const Button: React.FC<ButtonProps> = ({
  href,
  handleClick,
  children,
  modifiers,
  disabled,
  iconName,
  style,
  type,
}) => {
  const className = `${disabled ? 'a-button-disabled ' : ''}${mapModifiers(
    'a-button',
    modifiers,
  )}${iconName ? ' a-button-icon' : ''}`;
  if (href) {
    return (
      <Link to={href} className={className} style={style}>
        {children}
        {iconName && <Icon iconName={iconName} />}
      </Link>
    );
  }
  return (
    <button
      className={className}
      type={type}
      disabled={disabled}
      onClick={(event?: React.MouseEvent<HTMLButtonElement>,
        arg?: string|number): void => { if (!disabled && handleClick) handleClick(event, arg); }}
      style={style}
    >
      {children}
      {iconName && <Icon iconName={iconName} />}
    </button>
  );
};

Button.defaultProps = {
  type: 'button',
};

export default Button;
