import { ErrorsResponse } from '../phase/types';

import {
  REQUEST_GET_ALL_FOLDER,
  GET_ALL_FOLDER_ERROR,
  GET_ALL_FOLDER_SUCCESS,
  CLEAR_ALL_FOLDER,
  TypeAllFolderActions,
  DataResponse,
} from './types';

interface InitStateType{
  loading: boolean;
  data: DataResponse|null;
  error: ErrorsResponse|null;
}
const initState: InitStateType = {
  loading: false,
  data: null,
  error: null,
};
export default (state = initState, action: TypeAllFolderActions): InitStateType => {
  switch (action.type) {
    case REQUEST_GET_ALL_FOLDER:
      return {
        ...state, loading: true,
      };
    case GET_ALL_FOLDER_SUCCESS:
      return {
        ...state, loading: false, data: action.payload,
      };
    case GET_ALL_FOLDER_ERROR:
      return {
        ...state, loading: false, error: action.payload,
      };
    case CLEAR_ALL_FOLDER:
      return initState;
    default:
      return state;
  }
};
