import { httpNode } from '../instance';

import {
  SlugCourse,
  ResponseDataType,
  Slug,
} from 'reducers/expertcourse/types';

export const getExpertCourse = async (
  slugCourse: SlugCourse,
): Promise<ResponseDataType> => {
  const response = await httpNode.get(`course/${slugCourse}/expert`);
  return response.data.data;
};
export const getInfoPhase = async (
  slug: Slug,
): Promise<ResponseDataType> => {
  try {
    const response = await httpNode.get(`course/${slug.slugcourse}/expert/phase/${slug.slugphase}`);
    return response.data.data;
  } catch (error) {
    throw error.message;
  }
};
