import { ErrorsResponse } from '../account/types';

import {
  GET_PUBLISHPORTFOLIO_LIST_REQUEST,
  GET_PUBLISHPORTFOLIO_LIST_SUCCESS,
  GET_PUBLISHPORTFOLIO_LIST_ERROR,
  PUBLISHPORTFOLIO_LIST_RESET,
  PublishPortfolioListResponse,
  GetPublishPortfolioListActionTypes,
} from './types';

import { ListCoursesRequest } from 'reducers/pages/courses/types';

export const requestGetPublishPortfolioList = (
  request: ListCoursesRequest,
): GetPublishPortfolioListActionTypes => ({
  type: GET_PUBLISHPORTFOLIO_LIST_REQUEST,
  request,
});

export const successGetPublishPortfolioList = (
  response: PublishPortfolioListResponse,
): GetPublishPortfolioListActionTypes => ({
  type: GET_PUBLISHPORTFOLIO_LIST_SUCCESS,
  response,
});

export const errorGetPublishPortfolioList = (
  errors: ErrorsResponse,
): GetPublishPortfolioListActionTypes => ({
  type: GET_PUBLISHPORTFOLIO_LIST_ERROR,
  errors,
});

export const resetPublishPortfolioList = (
): GetPublishPortfolioListActionTypes => ({
  type: PUBLISHPORTFOLIO_LIST_RESET,
});
