/* eslint-disable linebreak-style */
import {
  REQUEST_GET_PROFILE,
  GET_PROFILE_ON_SUCCESS,
  GET_PROFILE_ON_ERROR,
  GetProfileActionTypes,
  ProfileResponse,
  ErrorsResponse,
  REQUEST_UPDATE_PROFILE,
  UPDATE_PROFILE_ON_SUCCESS,
  UPDATE_PROFILE_ON_ERROR,
  UpdateProfileRequest,
  REST_STATE_PROFILE,
} from './types';

export const requestGetProfile = (
): GetProfileActionTypes => ({
  type: REQUEST_GET_PROFILE,
});

export const getProfileOnError = (
  errors: ErrorsResponse,
): GetProfileActionTypes => ({
  type: GET_PROFILE_ON_ERROR,
  errors,
});

export const getProfileOnSuccess = (
  responseProfile: ProfileResponse,
): GetProfileActionTypes => ({
  type: GET_PROFILE_ON_SUCCESS,
  responseProfile,
});

export const requestUpdateProfile = (
  data: UpdateProfileRequest,
): GetProfileActionTypes => ({
  type: REQUEST_UPDATE_PROFILE,
  data,
});

export const updateProfileOnError = (
  errors: ErrorsResponse,
): GetProfileActionTypes => ({
  type: UPDATE_PROFILE_ON_ERROR,
  errors,
});

export const updateProfileOnSuccess = (
): GetProfileActionTypes => ({
  type: UPDATE_PROFILE_ON_SUCCESS,
});

export const resetProfile = (
): GetProfileActionTypes => ({
  type: REST_STATE_PROFILE,
});
