import React from 'react';

export type IconName =
  | 'angle-right'
  | 'black-man'
  | 'close'
  | 'down-blue'
  | 'down'
  | 'error'
  | 'facebook-blue'
  | 'facebook'
  | 'file'
  | 'info-white'
  | 'instagram-blue'
  | 'instagram'
  | 'menu-mb'
  | 'next-blue'
  | 'next-icon'
  | 'pinterest-blue'
  | 'pinterest'
  | 'subscribe_new'
  | 'subscribe'
  | 'twitter-blue'
  | 'twitter'
  | 'up-blue'
  | 'up'
  | 'user-light'
  | 'user'
  | 'user-white'
  | 'warning'
  | 'x-white'
  | 'correct'
  | 'pdf'
  | 'mp4'
  | 'return'
  | 'up-orange'
  | 'upload'
  | 'triangle-down-white'
  | 'vietnam'
  | 'russia'
  | 'kingdom'
  | 'japan'
  | 'upload-orange'
  | 'bell'
  | 'angle-left'
  | 'account'
  | 'account-active'
  | 'download'
  | 'search'
  | 'xlsx'
  | 'thumbFile'
  | 'filter'
  | 'cancel'
  | 'phone'
  | 'filter-white'
  | 'pad-lock'
  | 'next-orange'
  | 'prev-orange'
  | 'up-orange-small'
  | 'down-dark-small'
  | 'photo'
  | 'upload-photo'
  | 'upload-file'
  | 'clock-blue'
  | 'icon-info'
  | 'fullscreen'
  | undefined; // TODO: more icon

interface IconProps {
  iconName: IconName;
  onClick?: () => void;
  center?: boolean;
}

const Icon: React.FC<IconProps> = ({
  center, iconName, onClick,
}) => {
  const owlClass = 'a-icon';

  return (
    <>
      {center ? (
        <div className={center ? `${owlClass}_center` : ''} onClick={onClick}>
          <span className={`${owlClass} ${owlClass}-${iconName}`} />
        </div>
      ) : (
        <span className={`${owlClass} ${owlClass}-${iconName}`} onClick={onClick} />
      )}
    </>
  );
};

Icon.defaultProps = {};

export default Icon;
