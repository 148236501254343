import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router";

import { getAccessToken } from "apis/localstorage";
import PopupLogin from "components/templates/PopupLogin";
import { ModalContext } from "contexts/modal";
import { toggleModal } from "contexts/modal/actions";
import { ModalType } from "contexts/modal/types";

const ModalLogin: React.FC = () => {
  const location = useLocation();
  const {
    state: { modal },
    dispatch,
  } = useContext(ModalContext);

  // useEffect(() => {
  //   const token = getAccessToken();

  //   if (!token && location.pathname === '/') {
  //     dispatch(toggleModal({ modal: ModalType.Login, status: true }));
  //   }
  // }, [dispatch, location.pathname]);

  return (
    <PopupLogin
      isShow={modal.login}
      onHide={(): void =>
        dispatch(toggleModal({ modal: ModalType.Login, status: false }))
      }
      onLoginSuccess={(): void =>
        dispatch(toggleModal({ modal: ModalType.Login, status: false }))
      }
      onRedirectToForgotPassword={(): void =>
        dispatch(toggleModal({ modal: ModalType.Forgot, status: true }))
      }
      handleRedirectRegister={(): void =>
        dispatch(toggleModal({ modal: ModalType.Register, status: true }))
      }
    />
  );
};

export default ModalLogin;
