import {
  TypeDetailCourseActions,
  REQUEST_GET_DETAIL_COURSE,
  GET_DETAIL_COURSE_SUCCESS,
  GET_DETAIL_COURSE_ERROR,
  CLEAN_UP_DETAIL_COURSE,
  DataDetailCourseResponse,
  ErrorsResponse,
  SlugCourse,
  UPDATE_DETAIL_COURSE,
  Phase,
} from './types';

export const requestDetailCourse = (slug: SlugCourse): TypeDetailCourseActions => ({
  type: REQUEST_GET_DETAIL_COURSE,
  payload: slug,
});
export const getDetailCourseSuccess = (data: DataDetailCourseResponse):
TypeDetailCourseActions => ({
  type: GET_DETAIL_COURSE_SUCCESS,
  payload: data,
});
export const getDetailCourseError = (error: ErrorsResponse):
TypeDetailCourseActions => ({
  type: GET_DETAIL_COURSE_ERROR,
  payload: error,
});
export const requestCleanUp = ():
TypeDetailCourseActions => ({
  type: CLEAN_UP_DETAIL_COURSE,
});

export const updateDetailCourse = (payload: Phase):
TypeDetailCourseActions => ({
  type: UPDATE_DETAIL_COURSE,
  payload,
});
