import { httpNode } from '../instance';

import { UserSigninResponse } from 'reducers/auth/types';

interface LoginParams {
  email: string;
  password: string;
}

export const postLogin = async (
  params: LoginParams,
): Promise<UserSigninResponse> => {
  const response = await httpNode.post('account/login', params);
  return response.data.data;
};

export const deleteLogout = async (): Promise<void> => {
  await httpNode.delete('account/logout');
};
