import { Assessment } from 'reducers/detailcourse/progess/types';

/* eslint-disable @typescript-eslint/camelcase */
export const REQUEST_GET_DATA_HOME = 'REQUEST_GET_DATA_HOME';
export const GET_DATA_HOME_ON_SUCCESS = 'GET_DATA_HOME_ON_SUCCESS';
export const RESET_LIST_COURSES = 'RESET_LIST_COURSES';

// interface
export interface PageMetaData {
  home_herocarousel_1: {
    banner: string;
    title: string;
    sub_title: string;
    content: string;
    button1: string;
    button2: string;
  };
  section_program_6: {
    title: string;
    sub_title: string;
    content: string;
  };
  section_program_7: {
    title: string;
    sub_title: string;
    content: string;
  };
  home_slider_2: {
    slider: {
      [key in string]: {
        thumbnail: string;
      };
    };
  };
  home_benefits_3: {
    title_1: string;
    sub_title_1: string;
    content_1: string;
    title_2: string;
    sub_title_2: string;
    content_2: string;
    title_3: string;
    sub_title_3: string;
    content_3: string;
    title_4: string;
    sub_title_4: string;
    content_4: string;
  };
  section_title_4: {
    title: {
    };
  };
  section_about_8: {
    title: string;
    button: {
      text: string;
      link: string;
    };
  };
  section_structure_9: {
    title: string;
    button: string;
  };
  section_support_10: {
    items: {
      [key in string]: {
        title: string;
        title_button: string;
      };
    };
    title_footer: string;
  };
}

export interface DataPrograms {
  title: string;
  sub_title: string;
  content: string;
}

export interface DataCarouselsParner {
  thumbnail: string;
}

export interface DataTitle {
  title: string;
}

export interface DataBenefits {
  title_1: string;
  sub_title_1: string;
  content_1: string;
  title_2: string;
  sub_title_2: string;
  content_2: string;
  title_3: string;
  sub_title_3: string;
  content_3: string;
  title_4: string;
  sub_title_4: string;
  content_4: string;
}

export interface DataSectionAbout {
  title: string;
  button: {
    text: string;
    link: string;
  };
}

export interface DataSectionSupport {
  items: {
    [key in string]: {
      title: string;
      title_button: string;
    };
  };
  title_footer: string;
}

export interface DataSectionStructure {
  title: string;
  button: string;
}

export interface DataHomeCarousel {
  id: number;
  image: string | null;
  active: number | null;
  template_id: number | null;
  is_homepage: number | null;
  template_code: string | null;
  title: string | null;
  description: string | null;
  content: string | null;
  slug: string | null;
  page_meta_data: PageMetaData;
}

export interface CourseTranslations {
  courseManagement?: ItemDocument[];
  courseFormats?: ItemDocument[];
  supportAndAdvices?: ItemDocument[];
  suppliers?: ItemDocument[];
  id: number;
  courseId: number;
  locale: string;
  name: string;
  slug: string;
  title: string;
  description: string;
  about: string;
  support: string;
}

export interface PhasesTranslations {
  id: number;
  phaseId: number;
  locale: string;
  name: string;
  slug: string;
  title: string;
  description: string | null;
  about?: string;
  courseId?: number;
  support?: string;
  video?: string;
  titlePiece?: string;
}
interface Accounts{
  AccountPhasesModel: {
    phaseId: number;
    startDate: string;
    endDate: string;
    assessment?: Assessment;
  };
}
export interface ItemPhase {
  id: number;
  courseId: number;
  image: string;
  video: string;
  active: number;
  startDate: string;
  endDate: string;
  startDateOfAssessment: string;
  endDateOfAssessment: string;
  displayOrder: number;
  createdAt: string;
  updatedAt: string;
  phasesTranslations: PhasesTranslations[];
  bgThumbnail?: string;
  assessment: Assessment | null;
  accounts: [] | Accounts[];
}

export interface CourseFile {
  title: string;
  fileName: string;
  link: string;
  className: string;
}

export interface AccountPolicy {
  id: number;
  AccountCoursesModel: {
    id: number;
    accountId: number;
    expertId: number;
    courseId: number;
    status: number;
    total: number;
    comment: string;
    expertComment: string;
    accept: number;
    createdAt: string;
    updatedAt: string;
  };
}

export interface DataHomeCourse {
  courseManagement: CourseFile[];
  courseFormats: CourseFile[];
  supportAndAdvices: CourseFile[];
  suppliers: CourseFile[];
  courseTranslations: CourseTranslations[];
  phases: ItemPhase[];
  accounts: AccountPolicy[];
  startDate: string;
}


export interface DataHomePageResponse {
  isLoading: boolean;
  dataHomeCarousel: DataHomeCarousel | null;
  programs: DataPrograms[] | null;
  carousels: DataCarouselsParner[] | null;
  benefits: DataBenefits | null;
  titles: DataTitle[] | null;
  sectionAbout: DataSectionAbout | null;
  sectionStructure: DataSectionStructure | null;
  sectionSupport: DataSectionSupport | null;
  courses: DataDetailtCourse[] | null;
}

export interface RequestGetHomePageAction {
  type: typeof REQUEST_GET_DATA_HOME;
}

export interface GetHomePageOnSuccessAction {
  type: typeof GET_DATA_HOME_ON_SUCCESS;
  dataHomeCarousel: DataHomeCarousel;
  dataHomeCourse: DataDetailtCourse[];
}

export type HomePageActionTypes =
  | RequestGetHomePageAction
  | GetHomePageOnSuccessAction;
