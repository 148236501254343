import { httpNode } from '../instance';

import { QueryParamsType, DataResponseType } from 'reducers/listparticipants/types';

const getListParticipants = async (
  params: QueryParamsType,
): Promise<DataResponseType> => {
  const response = await httpNode.get(`course/${params.slugCourse}/expert/phase/${params.slugPhase}/list-participants`, {
    params,
  });
  return response.data;
};
export default getListParticipants;
