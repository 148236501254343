/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { postRegister } from 'apis/requests/register';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import { DatePickerHookForm } from 'components/molecules/DatePicker';
import { InputPhoneHookForm } from 'components/molecules/InputPhone';
import { PulldownHookForm } from 'components/molecules/PullDown';
import FormContainer from 'components/organisms/FormContainer';
import Modal from 'components/organisms/Modal';
import mapModifiers from 'function/mapModifier';
import useRegister, {
  FormResgisterType,
  PulldownType,
} from 'hooks/useRegister';
import i18n from 'i18n';
import { requestGetListCountry } from 'reducers/country/actions';
import {
  requestStartLoading,
  requestStopLoading,
} from 'reducers/loading/actions';
import { requestGetInfoRegistrations } from 'reducers/register/actions';
import { DataInfoRegister, RegisterRequest } from 'reducers/register/types';
import useSelector from 'selectors/useSelector';
import languges from 'translation/languages.json';
import { AccountRegisterErrType } from 'utils/consts';

interface BlockFeildProps {
  titleSmall: string;
  isTooltip?: boolean;
  size?: ('small' | 'maxwidth')[];
  isDontshow?: boolean;
  hasStar?: boolean;
}
export const BlockFeild: React.FC<BlockFeildProps> = ({
  titleSmall,
  isTooltip,
  children,
  isDontshow,
  hasStar,
  size,
}) => {
  if (isDontshow) {
    return <></>;
  }
  return (
    <div className={mapModifiers('o-blockFeild', size)}>
      <div className="o-blockFeild_titleSmall">
        <Text size="fs18-lg" weight="bold" modifiers={['brand-blue-dark']}>
          {hasStar ? `${titleSmall}*` : titleSmall}
        </Text>
        {isTooltip && <span className="o-blockFeild_tooltip" />}
      </div>
      <div className="o-blockFeild_feild">{children}</div>
    </div>
  );
};
interface ModalRegistrationProps {
  isShow: boolean;
  languageVi?: boolean;
  handleHidePopupResgister?: () => void;
  HandleShowPopupSuccess?: () => void;
}

type OptionsType = {
  value: string;
  label: string;
};

interface OptionsTypeArray {
  availabilities: OptionsType[];
  positions: OptionsType[];
}

const filterOptions = (data?: DataInfoRegister): OptionsTypeArray => {
  if (!data) {
    return {
      availabilities: [],
      positions: [],
    };
  }

  const availabilities = data.availabilities.map(
    (aval) => ({
      value: aval.availabilityTranslation.availabilityId.toString(),
      label: aval.availabilityTranslation.name,
    } as OptionsType),
  );

  const positions = data.positions.map(
    (aval) => ({
      value: aval.positionTranslation.positionId.toString(),
      label: aval.positionTranslation.name,
    } as OptionsType),
  );

  return { availabilities, positions };
};

const optionsLanguge = languges.map((item) => ({
  value: item.code,
  label: item.name,
}));

const checkPositionId = (positionId: PulldownType): number => {
  if (typeof positionId === 'object') {
    return Number(positionId.value);
  }
  return 8;
};

const handleCaseError = (
  item: any,
  methods: UseFormMethods<FormResgisterType>,
): void => {
  if (item.code === AccountRegisterErrType.EMAIL_EXISTS) {
    methods.setError('email', { type: 'validate', message: item.title });
  }
  if (item.code === AccountRegisterErrType.PHONE_NUMBER_EXISTS) {
    methods.setError('phone', { type: 'validate', message: item.title });
  }
  if (item.code === AccountRegisterErrType.NOT_ALLOW_EMPTY) {
    methods.setError(item.source.parameter, {
      type: 'validate',
      message: item.title,
    });
  }
  if (item.code === AccountRegisterErrType.STUDENT_ID_EXITS) {
    methods.setError('studentId', { type: 'validate', message: item.detail });
  }
  if (item.code === AccountRegisterErrType.COMPANY_CODE_NOT_FOUND) {
    methods.setError('companyCode', { type: 'validate', message: item.detail });
  }
};

const handleCatchError = (
  catchErr: any,
  methods: UseFormMethods<FormResgisterType>,
): void => {
  if (!catchErr || !Array.isArray(catchErr)) return;
  catchErr.forEach((item: any) => {
    if (!item.code) return;
    handleCaseError(item, methods);
  });
};

const ModalRegistration: React.FC<ModalRegistrationProps> = ({
  isShow,
  languageVi,
  handleHidePopupResgister,
  HandleShowPopupSuccess,
}) => {
  const { t } = useTranslation([
    'registration',
    'placeholder',
    'button',
    'validation',
    'employer_list_participants',
  ]);
  const dispatch = useDispatch();
  const { infoRegistration, country } = useSelector((state) => state);

  const optionsGender: OptionsType[] = [
    { value: '1', label: t('employer_list_participants:male') },
    { value: '2', label: t('employer_list_participants:female') },
  ];

  const { methods, isEnterPosition, setIsEnterPosition } = useRegister(
    infoRegistration.data,
  );

  const handleSubmitForm = async (data: FormResgisterType): Promise<void> => {
    const customData: RegisterRequest = {
      gender: Number(data.gender.value),
      availabilityId: Number(data.availability.value),
      positionId: checkPositionId(data.positionId),
      positionText: data.positionId.label,
      languages: [data.languages.map((lang) => lang.label)].join(', '),
      dob: moment(data.dob).format('YYYY-MM-DD'),
      address: data.address,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      occupation: data.occupation,
      password: data.password,
      phone: data.phone,
      studentId: data.studentId || undefined,
      countryId: Number(data.countryId.value),
      companyCode: data.companyCode || undefined,
    };

    try {
      dispatch(requestStartLoading());
      await postRegister(customData);
      dispatch(requestStopLoading());
      if (HandleShowPopupSuccess) {
        HandleShowPopupSuccess();
      }
    } catch (err) {
      dispatch(requestStopLoading());
      handleCatchError(err, methods);
    }
  };

  useEffect(() => {
    dispatch(requestGetInfoRegistrations());
    dispatch(requestGetListCountry());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const { availabilities, positions } = filterOptions(infoRegistration.data);

  const countryOptions = useMemo(
    () => country
      && country.data.map((item) => ({
        label: item.countryTranslation.name,
        value: item.countryTranslation.countryId,
      })),
    [country],
  );

  return (
    <Modal
      onHide={handleHidePopupResgister}
      isShow={isShow}
      modifier="upload"
      size="lg"
    >
      <div className="o-modalRegistration">
        <FormContainer method={methods} submitForm={handleSubmitForm}>
          <div className="o-modalRegistration_heading">
            <Heading
              type="h3"
              modifiers={['center', 'brand-blue-dark', 'uppercase']}
              weight="bold"
              size="lg"
            >
              {t('resgistration')}
            </Heading>
          </div>
          <div className="o-modalRegistration_personalTitle">
            <Heading
              type="h3"
              modifiers={['cadet', 'uppercase']}
              weight="bold"
              size="md"
            >
              {t('personal_information')}
            </Heading>
          </div>
          <div className="o-modalRegistration_row">
            <BlockFeild titleSmall={`${t('first_name')}*`}>
              <Input
                name="firstName"
                placeholder={t('placeholder:enter_name')}
                type="text"
                modifiers={['login']}
              />
            </BlockFeild>
            <BlockFeild titleSmall={`${t('last_name')}*`}>
              <Input
                name="lastName"
                placeholder={t('placeholder:enter_last_name')}
                type="text"
                modifiers={['login']}
              />
            </BlockFeild>
            <BlockFeild titleSmall={`${t('dob')}*`}>
              <DatePickerHookForm
                placeholderText={t('placeholder:dob')}
                maxDate={new Date()}
                name="dob"
                dateFormat={languageVi ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
              />
            </BlockFeild>
            <BlockFeild titleSmall={`${t('email')}*`}>
              <Input
                type="text"
                name="email"
                placeholder={t('placeholder:enter_email')}
                modifiers={['login']}
              />
            </BlockFeild>
            <BlockFeild titleSmall={`${t('phone')}*`}>
              <InputPhoneHookForm name="phone" />
            </BlockFeild>
            <BlockFeild titleSmall={`${t('gender')}*`}>
              <PulldownHookForm
                value={optionsGender[0]}
                options={optionsGender}
                name="gender"
              />
            </BlockFeild>
            <BlockFeild titleSmall={`${t('College')}*`}>
              <Input
                type="text"
                name="occupation"
                placeholder={t('placeholder:college_occupation')}
                modifiers={['login']}
              />
            </BlockFeild>
            <BlockFeild titleSmall={t('student_id')}>
              <Input
                type="text"
                name="studentId"
                placeholder={t('placeholder:student')}
                modifiers={['login']}
              />
            </BlockFeild>
            <BlockFeild titleSmall={`${t('Availability')}*`}>
              <PulldownHookForm
                defaultValue={undefined}
                placeholder={t('placeholder:availability')}
                options={availabilities}
                name="availability"
              />
            </BlockFeild>
            <BlockFeild titleSmall={`${t('your_country')}*`}>
              <PulldownHookForm
                defaultValue={undefined}
                placeholder={t('placeholder:participant_country')}
                options={countryOptions || []}
                name="countryId"
              />
            </BlockFeild>
            <BlockFeild titleSmall={`${t('address')}*`}>
              <Input
                type="text"
                name="address"
                placeholder={t('placeholder:address')}
                modifiers={['login']}
              />
            </BlockFeild>
            <BlockFeild titleSmall={`${t('language')}*`}>
              <PulldownHookForm
                defaultValue={undefined}
                isClearable={false}
                isMulti
                isSearchable
                placeholder={t('placeholder:lang')}
                options={optionsLanguge}
                name="languages"
              />
            </BlockFeild>
            <BlockFeild titleSmall={`${t('desired_position')}*`}>
              <PulldownHookForm
                placeholder={t('placeholder:position')}
                placeholderInput={t('placeholder:position')}
                handleClickCancel={(): void => setIsEnterPosition(false)}
                isEnterOther={isEnterPosition}
                options={positions}
                name="positionId"
                nameInput="positionId"
                defaultValue={undefined}
              />
            </BlockFeild>
            <BlockFeild titleSmall={t('company_code')}>
              <Input
                type="text"
                name="companyCode"
                placeholder={t('placeholder:company_code')}
                modifiers={['login']}
              />
            </BlockFeild>
          </div>
          <div className="o-modalRegistration_personalTitle">
            <Heading
              type="h3"
              modifiers={['cadet', 'uppercase']}
              weight="bold"
              size="md"
            >
              {t('account_info')}
            </Heading>
          </div>
          <div className="o-modalRegistration_info">
            <div className="o-modalRegistration_row">
              <BlockFeild titleSmall={`${t('password')}*`}>
                <Input
                  type="password"
                  name="password"
                  placeholder="*********"
                  modifiers={['login']}
                />
              </BlockFeild>
              <BlockFeild titleSmall={`${t('re_password')}*`}>
                <Input
                  type="password"
                  name="reNewPassword"
                  placeholder="*********"
                  modifiers={['login']}
                />
              </BlockFeild>
            </div>
          </div>
          <div className="o-modalRegistration_button">
            <Button
              type="submit"
              modifiers={[
                'ovalFill',
                'white',
                'icon-leftSide',
                'uppercase',
                'bold',
              ]}
            >
              {t('register')}
            </Button>
          </div>
        </FormContainer>
      </div>
    </Modal>
  );
};

export default ModalRegistration;
