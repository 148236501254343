import { ErrorsResponse } from '../account/types';

import {
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_ERROR,
  POST_SEEN_NOTIFICATION_REQUEST,
  POST_SEEN_NOTIFICATION_SUCCESS,
  POST_SEEN_NOTIFICATION_ERROR,
  GetNotificationActionTypes,
  DataNotificationResponse,
} from './types';

export const requestGetNotification = (
  personType: number,
): GetNotificationActionTypes => ({
  type: GET_NOTIFICATION_REQUEST,
  personType,
});

export const successGetNotification = (
  response: DataNotificationResponse[],
): GetNotificationActionTypes => ({
  type: GET_NOTIFICATION_SUCCESS,
  response,
});

export const errorGetNotification = (
  errors: ErrorsResponse[],
): GetNotificationActionTypes => ({
  type: GET_NOTIFICATION_ERROR,
  errors,
});


export const requestPostNotification = (
): GetNotificationActionTypes => ({
  type: POST_SEEN_NOTIFICATION_REQUEST,
});

export const successPostNotification = (
): GetNotificationActionTypes => ({
  type: POST_SEEN_NOTIFICATION_SUCCESS,
});

export const errorPostNotification = (
  errors: ErrorsResponse[],
): GetNotificationActionTypes => ({
  type: POST_SEEN_NOTIFICATION_ERROR,
  errors,
});
