import { http } from '../instance';

import { PostContactParams, AboutYouResponse, ErrorsResponse } from 'actions/pages/types';

export const postContact = async (params: PostContactParams): Promise<boolean | ErrorsResponse> => {
  try {
    const response = await http.post('contacts', params);
    return response.data.success;
  } catch (error) {
    return error;
  }
};

export const getAboutYou = async (): Promise<AboutYouResponse[]> => {
  const res = await http.get('about-you');
  return res.data.data;
};
