import React, { ChangeEvent } from "react";
import { useFormContext, get } from "react-hook-form";

import IconButton from "../IconButton";

import { IconName } from "components/atoms/Icon";
import Text from "components/atoms/Text";
import mapModifiers from "function/mapModifier";
import IconEye from "./IconEye";
import IconHideEye from "./IconHideEye";
type ModifiersType =
  | "icSearch"
  | "border-default"
  | "login"
  | "disabled-default"
  | "account-form"
  | "border-none"
  | "boxshadow-none"
  | "error-below"
  | "transparent"
  | "no-padding"
  | "search"
  | "cancel";
interface InputProps {
  type: string;
  id?: string;
  name?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  error?: boolean;
  modifiers?: ModifiersType[];
  defaultValue?: string | null;
  hidden?: boolean;
  autoComplete?: string;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  handleSearch?: () => void;
  handleClickIconCancel?: () => void;
  iconName?: IconName;
  isDisableCustom?: boolean;
}

const Input: React.FC<InputProps> = ({
  type,
  id,
  name,
  value,
  placeholder,
  modifiers,
  disabled,
  defaultValue,
  handleChange,
  handleFocus,
  readonly,
  handleBlur,
  hidden,
  autoComplete,
  onKeyPress,
  handleSearch,
  iconName,
  handleClickIconCancel,
  isDisableCustom,
}) => {
  const { register, errors } = useFormContext();
  const hasError: Record<string, string> = get(errors, `${name}`);
  const [showPassword, SetShowPassword] = React.useState(false);
  const implicitType = () => {
    if (type === "password") {
      let typePass = showPassword ? "text" : "password";
      return typePass;
    }
    return type;
  };
  return (
    <div
      className={mapModifiers(
        "a-input",
        isDisableCustom ? "disableCustom" : "",
        modifiers,
        hasError ? ["error"] : []
      )}
    >
      <input
        type={implicitType()}
        disabled={disabled}
        className="a-input_input"
        name={name}
        id={id}
        autoComplete={autoComplete}
        ref={register}
        readOnly={readonly}
        hidden={hidden}
        defaultValue={defaultValue || undefined}
        value={value}
        placeholder={placeholder}
        onChange={(e): void => handleChange && handleChange(e)}
        onFocus={(e): void => handleFocus && handleFocus(e)}
        onBlur={(e): void => handleBlur && handleBlur(e)}
        onKeyPress={onKeyPress}
      />
      {type === "password" ? (
        <div className="iconEye" onClick={() => SetShowPassword(!showPassword)}>
          {showPassword ? <IconEye /> : <IconHideEye />}
        </div>
      ) : (
        ""
      )}
      {hasError && (
        <div className="a-input_error">
          <Text size="fs12-lg" modifiers={["red-dark"]}>
            {hasError.message}
          </Text>
        </div>
      )}
      {modifiers && modifiers.includes("search") && (
        <IconButton
          iconName={iconName || "search"}
          handleClick={handleSearch}
        />
      )}
      {modifiers && modifiers.includes("cancel") && (
        <IconButton
          iconName={iconName || "cancel"}
          handleClick={handleClickIconCancel}
        />
      )}
    </div>
  );
};

export default Input;
