import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/atoms/Button";
import Text from "components/atoms/Text";
import Modal from "components/organisms/Modal";
import ModalRegistration from "components/organisms/ModalRegistration";
import { ModalContext } from "contexts/modal";
import { toggleModal } from "contexts/modal/actions";
import { ModalType } from "contexts/modal/types";
import i18n from "i18n";

const ModalRegister: React.FC = () => {
  const { t } = useTranslation(["registration", "button"]);
  const {
    state: { modal },
    dispatch,
  } = useContext(ModalContext);

  const [showPopupSuccess, setShowPopupSuccess] = useState(false);

  const handleShowPopupSuccess = (): void => {
    dispatch(toggleModal({ modal: ModalType.Login, status: false }));
    setShowPopupSuccess(true);
  };

  return (
    <>
      <ModalRegistration
        isShow={modal.register}
        languageVi={i18n.language === "vi"}
        handleHidePopupResgister={(): void =>
          dispatch(toggleModal({ modal: ModalType.Login, status: false }))
        }
        HandleShowPopupSuccess={handleShowPopupSuccess}
      />
      <Modal isShow={showPopupSuccess}>
        <div className="t-accountform_modal">
          <Text>{t("register_success")}</Text>
          <Button
            handleClick={(): void => {
              setShowPopupSuccess(false);
              dispatch(toggleModal({ modal: ModalType.Login, status: true }));
            }}
            modifiers={["white", "ovalFill", "uppercase", "bold"]}
            type="button"
          >
            {t("button:ok")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ModalRegister;
