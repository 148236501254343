import React, { useReducer } from 'react';

import reducer from './reducer';
import { ModalActions, State } from './types';

const initialState = {
  modal: {
    register: false,
    login: false,
    forgot: false,
  },
};

export const ModalContext = React.createContext<{
  state: State;
  dispatch: React.Dispatch<ModalActions>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const ModalProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ModalContext.Provider value={{ state, dispatch }}>
      {children}
    </ModalContext.Provider>
  );
};
