import {
  REQUEST_ACCEPT_POLICY,
  ACCEPT_POLICY_ON_SUCCESS,
  ACCEPT_POLICY_ON_ERROR,
  AcceptPolicyActionTypes,
  AcceptPolicyState,
} from './types';

const defaultState: AcceptPolicyState = {
  isSuccessAcceptPolicy: false,
  errors: null,
};

export default (
  state = defaultState,
  action: AcceptPolicyActionTypes,
): AcceptPolicyState => {
  switch (action.type) {
    case REQUEST_ACCEPT_POLICY:
      return {
        ...state,
        errors: null,
      };
    case ACCEPT_POLICY_ON_SUCCESS:
      return {
        ...state,
        isSuccessAcceptPolicy: true,
        errors: null,
      };
    case ACCEPT_POLICY_ON_ERROR:
      return {
        ...state,
        isSuccessAcceptPolicy: false,
        errors: action.errors,
      };
    default:
      return state;
  }
};
