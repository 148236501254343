import { call, put, takeEvery } from 'redux-saga/effects';

import { updatePassword } from 'apis/requests/account';
import { userUpdatePasswordOnError, userUpdatePasswordOnSuccess } from 'reducers/account/actions';
import { RequestUpdatePasswordAction, REQUEST_USER_UPDATE_PASSWORD } from 'reducers/account/types';


export function* requestUserUpdatePassword(action: RequestUpdatePasswordAction): Generator {
  try {
    yield call(updatePassword, action.userUpdatePassword);
    yield put(userUpdatePasswordOnSuccess());
  } catch (e) {
    yield put(userUpdatePasswordOnError(e[0]));
  }
}

export default function* watchUpdatePasswordSaga(): Generator {
  yield takeEvery(REQUEST_USER_UPDATE_PASSWORD, requestUserUpdatePassword);
}
