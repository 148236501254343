/* eslint-disable linebreak-style */
import {
  REQUEST_ACCEPT_POLICY,
  ACCEPT_POLICY_ON_SUCCESS,
  ACCEPT_POLICY_ON_ERROR,
  RequestAcceptPolicy,
  AcceptPolicyActionTypes,
  ErrorsResponse,
} from './types';

export const requestAcceptPolicy = (
  acceptPolicy: RequestAcceptPolicy,
): AcceptPolicyActionTypes => ({
  type: REQUEST_ACCEPT_POLICY,
  acceptPolicy,
});

export const acceptPolicyOnError = (
  errors: ErrorsResponse,
): AcceptPolicyActionTypes => ({
  type: ACCEPT_POLICY_ON_ERROR,
  errors,
});

export const acceptPolicyOnSuccess = (
): AcceptPolicyActionTypes => ({
  type: ACCEPT_POLICY_ON_SUCCESS,
});
