import axios, { AxiosError } from 'axios';

import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from './localstorage';

import { AccountErrorType, CourseErrorType, GeneralErrorType } from 'utils/consts';
import { API_CMS, API_NODE, CMS } from 'variables-env';

export const cmsHttp = CMS;

export const http = axios.create({
  baseURL: API_CMS,
  headers: {
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(
  async (config) => {
    const token = getAccessToken();
    return {
      ...config,
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        locale: window.localStorage.getItem('language') || 'en',
        ...config.headers,
      },
    };
  },
  (error) => {
    Promise.reject(error);
  },
);

http.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response ? error.response.data.errors : error),
);

export const httpNode = axios.create({
  baseURL: API_NODE,
  headers: {
    'Content-Type': 'application/json',
  },
});

httpNode.interceptors.request.use(
  async (config) => {
    const token = getAccessToken();
    return {
      ...config,
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        locale: window.localStorage.getItem('language') || 'en',
        ...config.headers,
      },
    };
  },
  (error) => {
    Promise.reject(error);
  },
);

httpNode.interceptors.response.use(
  (response) => response,
  (error): Promise<AxiosError> => {
    const { errors } = error.response.data;
    const refreshToken = getRefreshToken();
    switch (errors[0].code) {
      case CourseErrorType.COURSE_NOT_FOUND: // Course not found
      case CourseErrorType.PHASE_NOT_FOUND: // Phase not found
      case CourseErrorType.NOT_ACCEPT_TERM_CONDITION: // Not accept term conditions and privacy
      case AccountErrorType.SPONSOR_DO_NOT_HAS_ACCESS:
        window.location.replace('/notfound');
        break;
      case CourseErrorType.COURSE_NOT_STARTED: // course not start
        window.location.replace('/');
        break;
      case GeneralErrorType.INVALID_ACCESS_TOKEN: // Authorization Invalid Token
      case GeneralErrorType.MISSING_ACCESS_TOKEN: // Unauthorized
      case GeneralErrorType.INVALID_REFRESH_TOKEN: // Authorization Invalid RefreshToken
        window.location.replace('/');
        removeAccessToken();
        removeRefreshToken();
        break;
      case GeneralErrorType.EXPIRED_ACCESS_TOKEN: // Unauthorized
        httpNode
          .post('account/refresh-token', {
            refreshToken: `Bearer ${refreshToken || ''}`,
          })
          .then((res) => {
            setAccessToken(res.data.data.token);
            setRefreshToken(res.data.data.refreshToken);
            const { config } = res;
            config.headers.Authorization = `Bearer ${res.data.data.token}`;
            return httpNode(config);
          },
          (err) => {
            window.location.replace('/');
            removeAccessToken();
            removeRefreshToken();
            return Promise.reject(err);
          });
        break;
      default:
        break;
    }
    return Promise.reject(errors);
  },
);
