import { all } from 'redux-saga/effects';

import watchAcceptPolicySaga from './acceptpolicy';
import watchUpdatePasswordSaga from './account';
import watchSigninSaga from './auth/index';
import watchFetchDownloadFileSaga from './common/document';
import watchGetListCountrySaga from './country';
import watchUploadFilesSaga from './courseuploadfiles';
import watchFetchInfoRegistration from './inforegistration';
import watchListCourseFilesSaga from './listcoursefiles';
import watchGetNotificationSaga from './notification';
import watchFetchContactPageSaga from './pages/contact';
import watchFetchListCoursesPageSaga from './pages/courses';
import watchFetchDetailCourseSaga from './pages/detailsource';
import watchFetchExpertCourseSaga from './pages/expertcourse';
import watchFetchListParticipantsSaga from './pages/expertphasecourse';
import watchGetPublishPortfolioListSaga from './pages/expertpublishportfolio';
import watchFetchHomePageSaga from './pages/home';
import watchGetProfileSaga from './profile';
import watchFetchSystemSaga from './system';

export default function* rootSaga(): Generator {
  yield all([
    watchFetchHomePageSaga(),
    watchFetchDownloadFileSaga(),
    watchFetchContactPageSaga(),
    watchSigninSaga(),
    watchUpdatePasswordSaga(),
    watchFetchListCoursesPageSaga(),
    watchListCourseFilesSaga(),
    watchGetProfileSaga(),
    watchFetchDetailCourseSaga(),
    watchUploadFilesSaga(),
    watchFetchExpertCourseSaga(),
    watchFetchListParticipantsSaga(),
    watchGetPublishPortfolioListSaga(),
    watchGetNotificationSaga(),
    watchAcceptPolicySaga(),
    watchFetchSystemSaga(),
    watchFetchInfoRegistration(),
    watchGetListCountrySaga(),
  ]);
}
