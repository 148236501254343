import {
  REQUEST_UPLOAD_FILE,
  REQUEST_DELETE_FILE,
  REQUEST_SUBMIT_UPLOAD_FILE,
  UPLOAD_FILE_ON_SUCCESS,
  UPLOAD_FILE_ON_ERROR,
  DELETE_FILE_ON_SUCCESS,
  DELETE_FILE_ON_ERROR,
  SUBMIT_UPLOAD_FILE_ON_SUCCESS,
  SUBMIT_UPLOAD_FILE_ON_ERROR,
  UploadFilesState,
  UploadFilesActionTypes,
  SET_UPLOAD_PROGRESS,
  SET_UPLOAD_FILE,
  FILTER_FILE_PROGRESS,
  RESET_UPLOAD_FILES,
} from './types';

import { modifyFiles } from 'function/func';

const defaultState: UploadFilesState = {
  dataUploadFile: [],
  fileProgress: [],
  loading: false,
  uploadedFiles: false,
  isSuccess: false,
  errors: null,
};

export default (state = defaultState, action: UploadFilesActionTypes): UploadFilesState => {
  switch (action.type) {
    case SET_UPLOAD_FILE: {
      const newFilesUploading = modifyFiles(state.fileProgress, action.files);
      const filterDuplicateFile = newFilesUploading.filter((item) => {
        if (state.fileProgress && state.fileProgress.length > 0) {
          const existing = state.fileProgress.some((fileProgress) => fileProgress.name === item.name
            && fileProgress.size === item.size);
          if (existing) return null;
          return item;
        }
        return item;
      });


      return {
        ...state,
        fileProgress: state.fileProgress.concat(filterDuplicateFile),
        loading: true,
      };
    }
    case SET_UPLOAD_PROGRESS: {
      const newFileProgress = state.fileProgress;
      const indexFile = newFileProgress.findIndex((item) => item.id === action.id);
      newFileProgress[indexFile].progress = action.progress;

      return {
        ...state,
        fileProgress: newFileProgress,
      };
    }
    case REQUEST_UPLOAD_FILE:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_DELETE_FILE: {
      const newFileProgress = [...state.fileProgress];
      const remainFileProgress = newFileProgress.filter((item) => item.id !== action.file.id);
      return {
        ...state,
        fileProgress: remainFileProgress,
        loading: true,
      };
    }
    case REQUEST_SUBMIT_UPLOAD_FILE:
      return {
        ...state,
      };
    case UPLOAD_FILE_ON_ERROR: {
      const newFileProgress = [...state.fileProgress];
      const indexFile = newFileProgress.findIndex((item) => item.id === action.fileId);
      newFileProgress[indexFile].isUploading = false;
      newFileProgress[indexFile].errors = action.errors
          && (action.errors.title || action.errors.message);

      return {
        ...state,
        fileProgress: newFileProgress,
        loading: false,
        isSuccess: false,
      };
    }
    case DELETE_FILE_ON_ERROR:
    case SUBMIT_UPLOAD_FILE_ON_ERROR:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        errors: action.errors && (action.errors.title || action.errors.message),
      };
    case UPLOAD_FILE_ON_SUCCESS: {
      const newFileProgress = [...state.fileProgress];
      const indexFile = newFileProgress.findIndex(
        (item) => item.id === action.dataUploadFile.fileId,
      );
      newFileProgress[indexFile].isUploading = false;
      return {
        ...state,
        dataUploadFile: [
          ...state.dataUploadFile,
          action.dataUploadFile,
        ],
        fileProgress: newFileProgress,
        loading: false,
        isSuccess: true,
        errors: null,
      };
    }
    case DELETE_FILE_ON_SUCCESS: {
      const newDataUpload = [...state.dataUploadFile];
      const newFileProgress = [...state.fileProgress];
      const remainFiles = newDataUpload.filter((item) => item.fileId !== action.file.fileId);
      const remainFileProgress = newFileProgress.filter((item) => item.id !== action.file.fileId);
      if (remainFiles && remainFileProgress) {
        return {
          ...state,
          dataUploadFile: remainFiles,
          fileProgress: remainFileProgress,
          loading: false,
          isSuccess: true,
          errors: null,
        };
      }
      return state;
    }
    case FILTER_FILE_PROGRESS: {
      const newFileProgress = [...state.fileProgress];
      const remainFileProgress = newFileProgress.filter((item) => item.id !== action.fileId);
      if (remainFileProgress) {
        return {
          ...state,
          fileProgress: remainFileProgress,
          loading: false,
          isSuccess: true,
          errors: null,
        };
      }
      return state;
    }
    case SUBMIT_UPLOAD_FILE_ON_SUCCESS:
      return {
        ...state,
        dataUploadFile: [],
        fileProgress: [],
        uploadedFiles: true,
        errors: null,
      };
    case RESET_UPLOAD_FILES:
      return defaultState;
    default:
      return state;
  }
};
