import {
  ListCountryState,
  ListCountryActionTypes,
  GET_LIST_COUNTRY_ON_SUCCESS,
  REQUEST_GET_LIST_COUNTRY,
  GET_LIST_COUNTRY_ON_ERROR,
} from './types';

const defaultState: ListCountryState = {
  data: [],
  loading: false,
};

export default (
  state = defaultState,
  action: ListCountryActionTypes,
): ListCountryState => {
  switch (action.type) {
    case REQUEST_GET_LIST_COUNTRY:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_COUNTRY_ON_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case GET_LIST_COUNTRY_ON_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    default:
      return state;
  }
};
