import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import getListParticipants from 'apis/requests/listparticipants';
import {
  getListParticipantsError,
  getListParticipantsSuccess,
} from 'reducers/listparticipants/actions';
import {
  RequestListParticipantsActionType,
  REQUEST_GET_LIST_PARTICIPANTS,
  DataResponseType,
} from 'reducers/listparticipants/types';

function* fetchListParticipantsSaga(action: RequestListParticipantsActionType): Generator {
  try {
    const data = (yield call(getListParticipants, action.payload)) as DataResponseType;
    yield put(getListParticipantsSuccess({ ...data, isShowMore: action.payload.isShowMore }));
  } catch (e) {
    yield put(getListParticipantsError(e.response.data.errors[0]));
  }
}
export default function* watchFetchListParticipantsSaga(): Generator {
  yield takeLatest(REQUEST_GET_LIST_PARTICIPANTS, fetchListParticipantsSaga);
}
