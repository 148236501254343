import { httpNode, http } from '../instance';

import {
  UpdateProfileRequest, ProfileResponse, UpdateProfileParticipantRequest,
} from 'reducers/profile/types';

export const getProfile = async (
): Promise<ProfileResponse> => {
  const response = await httpNode.get('account/profile');
  return response.data.data;
};

export const updateProfile = async (
  dataForm: UpdateProfileRequest,
): Promise<void> => {
  const response = await httpNode.put('account/update-profile', dataForm);
  return response.data;
};
export const uploadAvartar = async (
  params: FormData,
): Promise<void> => {
  const response = await http.post('/accounts/upload-avatar', params);
  return response.data.data;
};
export const updateProfileEmployer = async (
  dataForm: UpdateProfileRequest,
): Promise<void> => {
  const response = await httpNode.put('account/employer/update-profile', dataForm);
  return response.data;
};
export const updateProfileParticipant = async (
  dataForm: UpdateProfileParticipantRequest,
): Promise<void> => {
  const response = await httpNode.put('account/participants/update-profile', dataForm);
  return response.data;
};
