import { yupResolver } from '@hookform/resolvers';
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import postForgotPassword from 'apis/requests/forgot';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Input from 'components/atoms/Input';
import Modalboostrap from 'components/organisms/Modal';
import { requestStartLoading, requestStopLoading } from 'reducers/loading/actions';

interface ForgotPasswordFormType {
  email: string;
}

interface ForgotPasswordProps {
  isShow: boolean;
  onHide?: (value: string) => void;
  onRedirectToLogin?: () => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  isShow,
  onHide,
  onRedirectToLogin,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['form_user', 'button', 'placeholder', 'validation']);

  const ForgotPasswordFormSchema = yup.object().shape({
    email: yup
      .string()
      .required(t('validation:email_required'))
      .email(t('validation:email_not_valid')),
  });


  const [isPostForgotSuccess, setPostForgotSuccess] = useState(false);
  const methods = useForm<ForgotPasswordFormType>({
    resolver: yupResolver(
      ForgotPasswordFormSchema,
    ),
  });
  const onSubmit = async (data: ForgotPasswordFormType): Promise<void> => {
    dispatch(requestStartLoading());
    const response = await postForgotPassword(data);
    if (typeof response === 'boolean' && response) {
      setPostForgotSuccess(true);
      dispatch(requestStopLoading());
    } else {
      methods.setError('email', { type: 'validate', message: `${response}. Please try again!` });
      dispatch(requestStopLoading());
    }
  };
  const onHideModal = (): void => {
    if (onHide) {
      onHide('Forgotpassword');
    }
  };

  useEffect(() => {
    setPostForgotSuccess(false);
  }, [isShow]);

  return (
    <>
      <Modalboostrap modifier="login" isShow={isShow} onHide={onHideModal}>
        {!isPostForgotSuccess ? (
          <div className="t-forgotpassword">

            <div className="t-forgotpassword_title">
              <Heading type="h4">{t('forgot_password')}</Heading>
            </div>
            <div className="t-forgotpassword_description">
              {t('title_receive_email')}
            </div>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="t-forgotpassword_input">
                  <Input
                    modifiers={['border-default', 'login']}
                    type="email"
                    name="email"
                    placeholder={t('placeholder:enter_email')}
                  />
                </div>
                <div className="t-forgotpassword_button">
                  <Button
                    modifiers={[
                      'white',
                      'ovalFill',
                      'uppercase',
                      'bold',
                    ]}
                    type="submit"
                  >
                    {t('button:submit')}
                  </Button>
                </div>

                <div className="t-forgotpassword_signin">
                  {t('have_account')}
                  <button type="button" className="t-forgotpassword_signin_link" onClick={onRedirectToLogin}>
                    {t('button:login').toUpperCase()}
                  </button>
                </div>
              </form>
            </FormProvider>
          </div>
        ) : (
          <div className="t-forgotpassword_notify">
            <p>{t('title_sent_success')}</p>
            <p>{t('content_sent_success')}</p>
          </div>
        )}
      </Modalboostrap>
    </>
  );
};

export default ForgotPassword;
