import { AxiosRequestConfig } from 'axios';

import {
  REQUEST_UPLOAD_FILE,
  REQUEST_DELETE_FILE,
  REQUEST_SUBMIT_UPLOAD_FILE,
  UPLOAD_FILE_ON_SUCCESS,
  UPLOAD_FILE_ON_ERROR,
  DELETE_FILE_ON_SUCCESS,
  DELETE_FILE_ON_ERROR,
  SUBMIT_UPLOAD_FILE_ON_SUCCESS,
  SUBMIT_UPLOAD_FILE_ON_ERROR,
  SET_UPLOAD_FILE,
  SET_UPLOAD_PROGRESS,
  ErrorsResponse,
  UploadFilesActionTypes,
  UploadFile,
  SubmitUploadFilesRequest,
  FILTER_FILE_PROGRESS,
  RESET_UPLOAD_FILES,
} from './types';

export const setUploadFiles = (
  files: FileList,
): UploadFilesActionTypes => ({
  type: SET_UPLOAD_FILE,
  files,
});

export const setUploadProgress = (
  id: number,
  progress: number,
): UploadFilesActionTypes => ({
  type: SET_UPLOAD_PROGRESS,
  id,
  progress,
});

export const requestUploadFiles = (
  uploadFiles: FormData,
  fileId: number,
  config: AxiosRequestConfig,
): UploadFilesActionTypes => ({
  type: REQUEST_UPLOAD_FILE,
  uploadFiles,
  fileId,
  config,
});

export const uploadFilesOnError = (
  errors: ErrorsResponse,
  fileId: number,
): UploadFilesActionTypes => ({
  type: UPLOAD_FILE_ON_ERROR,
  errors,
  fileId,
});

export const uploadFilesOnSuccess = (
  dataUploadFile: UploadFile,
): UploadFilesActionTypes => ({
  type: UPLOAD_FILE_ON_SUCCESS,
  dataUploadFile,
});

export const requestDeleteFile = (
  file: UploadFile,
): UploadFilesActionTypes => ({
  type: REQUEST_DELETE_FILE,
  file,
});

export const deleteFilesOnError = (
  errors: ErrorsResponse,
): UploadFilesActionTypes => ({
  type: DELETE_FILE_ON_ERROR,
  errors,
});

export const deleteFilesOnSuccess = (
  file: UploadFile,
): UploadFilesActionTypes => ({
  type: DELETE_FILE_ON_SUCCESS,
  file,
});

export const requestSubmitUploadFiles = (
  submitUploadFiles: SubmitUploadFilesRequest,
): UploadFilesActionTypes => ({
  type: REQUEST_SUBMIT_UPLOAD_FILE,
  submitUploadFiles,
});

export const submitUploadFilesOnError = (
  errors: ErrorsResponse,
): UploadFilesActionTypes => ({
  type: SUBMIT_UPLOAD_FILE_ON_ERROR,
  errors,
});

export const submitUploadFilesOnSuccess = (
): UploadFilesActionTypes => ({
  type: SUBMIT_UPLOAD_FILE_ON_SUCCESS,
});

export const filterFileProgress = (
  fileId: number,
): UploadFilesActionTypes => ({
  type: FILTER_FILE_PROGRESS,
  fileId,
});

export const resetUploadFiles = (
): UploadFilesActionTypes => ({
  type: RESET_UPLOAD_FILES,
});
