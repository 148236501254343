import React, { PropsWithChildren } from 'react';
import { FormProvider, UseFormMethods, SubmitHandler } from 'react-hook-form';

interface FormProps<T> {
  method: UseFormMethods<T>;
  submitForm?: SubmitHandler<T>;
  className?: string;
}

const FormContainer = <T, >({
  children,
  method,
  submitForm,
  className,
}: PropsWithChildren<FormProps<T>>): React.ReactElement => (
  <div className={`o-form ${className || ''}`}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <FormProvider {...method}>
      <form onSubmit={submitForm && method.handleSubmit(submitForm)} noValidate>
        {children}
      </form>
    </FormProvider>
  </div>
  );

export default FormContainer;
