/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig } from 'axios';

import { httpNode, http } from '../instance';

import {
  SubmitUploadFilesRequest,
} from 'reducers/courseuploadfiles/types';

export const uploadFile = async (
  params: FormData,
  config: AxiosRequestConfig,
): Promise<void> => {
  const response = await http.post('upload-files', params, config);
  return response.data.data;
};

export const deleteFile = async (fileId: number): Promise<void> => {
  const response = await http.delete(`delete-files/${fileId}`);
  return response.data;
};

export const submitUploadFiles = async (
  params: SubmitUploadFilesRequest,
): Promise<void> => {
  const response = await httpNode.post('course/participant/phase/folder/upload-files', params);
  return response.data;
};
