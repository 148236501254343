export const REQUEST_START_LOADING = 'REQUEST_START_LOADING';
export const REQUEST_STOP_LOADING = 'REQUEST_STOP_LOADING';

export interface RequestStartLoadingActionType {
  type: typeof REQUEST_START_LOADING;
}

export interface RequestStopLoadingActionType {
  type: typeof REQUEST_STOP_LOADING;
}

export interface LoadingInitState {
  loading: boolean;
}

export type LoadingActionsType =
  | RequestStartLoadingActionType
  | RequestStopLoadingActionType;
