import {
  REQUEST_GET_SYSTEM,
  GET_SYSTEM_ON_SUCCESS,
  GET_SYSTEM_ON_ERROR,
  ErrorsResponse,
  SystemResponse,
  GetSystemActionTypes,
} from './types';

export const requestGetSystem = (
): GetSystemActionTypes => ({
  type: REQUEST_GET_SYSTEM,
});

export const getSystemOnError = (
  errors: ErrorsResponse,
): GetSystemActionTypes => ({
  type: GET_SYSTEM_ON_ERROR,
  errors,
});

export const getSystemOnSuccess = (
  response: SystemResponse,
): GetSystemActionTypes => ({
  type: GET_SYSTEM_ON_SUCCESS,
  response,
});
