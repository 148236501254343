import React, { useContext } from 'react';

import ForgotPassword from 'components/templates/ForgotPassword';
import { ModalContext } from 'contexts/modal';
import { toggleModal } from 'contexts/modal/actions';
import { ModalType } from 'contexts/modal/types';

const ModalLogin: React.FC = () => {
  const {
    state: { modal },
    dispatch,
  } = useContext(ModalContext);

  const redirectLogin = (): void => {
    dispatch(toggleModal({ modal: ModalType.Login, status: true }));
  };

  return (
    <ForgotPassword
      isShow={modal.forgot}
      onHide={redirectLogin}
      onRedirectToLogin={redirectLogin}
    />
  );
};

export default ModalLogin;
