import { ErrorsResponse, SlugCourse } from '../phase/types';

import {
  REQUEST_ASSESSMENT_COURSE,
  GET_ASSESSMENT_COURSE_SUCCESS,
  GET_ASSESSMENT_COURSE_ERROR,
  UPDATE_COMMENT_PARTICIPANT,
  CLEAR_ASSESSMENT_PARTICIPANT,
  DataAssessmentCourseResponse,
  AssessmentCourseActionTypes,
} from './types';

export const requestAssessmentCourse = (slug: SlugCourse): AssessmentCourseActionTypes => ({
  type: REQUEST_ASSESSMENT_COURSE,
  payload: slug,
});
export const getAssessmentCourseSuccess = (data: DataAssessmentCourseResponse):
  AssessmentCourseActionTypes => ({
  type: GET_ASSESSMENT_COURSE_SUCCESS,
  payload: data,
});
export const getAssessmentCourseError = (error: ErrorsResponse):
  AssessmentCourseActionTypes => ({
  type: GET_ASSESSMENT_COURSE_ERROR,
  payload: error,
});
export const updateCommentParticipant = (payload: string):
  AssessmentCourseActionTypes => ({
  type: UPDATE_COMMENT_PARTICIPANT,
  payload,
});
export const clearAssessmentParticipant = ():
  AssessmentCourseActionTypes => ({
  type: CLEAR_ASSESSMENT_PARTICIPANT,
});
