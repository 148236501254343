import { httpNode } from '../instance';

export const listCourseFiles = async (
  folderId: number,
): Promise<void> => {
  const response = await httpNode.get(`course/participant/phase/folder/${folderId}/list-files`);
  return response.data.data;
};


export const getFileAllVersions = async (
  fileId: number,
): Promise<void> => {
  const response = await httpNode.get(`course/participant/file/${fileId}/all-versions`);
  return response.data.data;
};

export const getFileAllVersionsFromExpert = async (
  fileId: number,
  participantId: number,
): Promise<void> => {
  const response = await httpNode.get(`course/expert/file/${fileId}/all-versions/${participantId}`);
  return response.data.data;
};
