export const REQUEST_GET_INFO_REGISTRATION = 'REQUEST_GET_INFO_REGISTRATION';
export const GET_INFO_REGISTRATION_SUCCESS = 'GET_INFO_REGISTRATION_SUCCESS';
export const GET_INFO_REGISTRATION_ERROR = 'GET_INFO_REGISTRATION_ERROR';
export interface ErrorsResponse {
  code: string;
  detail: string;
  title: string;
}

export interface Position {
  isEditable: number;
  id: number;
  positionTranslation: PositionTranslation;
}

interface PositionTranslation {
  id: number;
  positionId: number;
  locale: string;
  name: string;
}

export interface Availability {
  id: number;
  availabilityTranslation: AvailabilityTranslation;
}

interface AvailabilityTranslation {
  id: number;
  availabilityId: number;
  locale: string;
  name: string;
}

export type TechnicalTranslation = {
  id: number;
  technicalId: number;
  locale: string;
  name: string;
}

export type Technical = {
  isEditable: number;
  id: number;
  technicalTranslation: TechnicalTranslation;
}
export type PrepareToWorkTranslation={
  id: number;
  prepareToWorkId: number;
  locale: string;
  name: string;
}

export type prepareToWork= {
  id: number;
  isEditable: number;
  createdAt: Date;
  updatedAt: Date;
  prepareToWorkTranslation: PrepareToWorkTranslation;
}


export interface DataInfoRegister {
  availabilities: Availability[];
  positions: Position[];
  title: {
    college: number;
    occupation: number;
  };
  technical: Technical[];
  prepareToWork: prepareToWork[];
}

export interface RegisterResponse {
  data: DataInfoRegister;
}

export interface RegisterRequest {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dob: string;
  gender: number;
  occupation: string;
  studentId?: string;
  availabilityId: number;
  positionId: number;
  positionText: string;
  address: string;
  languages: string;
  password: string;
  countryId: number;
  companyCode?: string;
}

export interface ResponseInfoRegistrations {
  data?: DataInfoRegister;
  loading: boolean;
  errors?: ErrorsResponse;
}

export interface RequestGetInfoRegistration {
  type: typeof REQUEST_GET_INFO_REGISTRATION;
}

export interface RequestGetInfoRegistrationSuccess {
  type: typeof GET_INFO_REGISTRATION_SUCCESS;
  data: DataInfoRegister;
}

export interface RequestGetInfoRegistrationError {
  type: typeof GET_INFO_REGISTRATION_ERROR;
  errors: ErrorsResponse;
}

export type GetInfoRegistrationActionTypes =
  | RequestGetInfoRegistration
  | RequestGetInfoRegistrationSuccess
  | RequestGetInfoRegistrationError;
