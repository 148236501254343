import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'translation/en.json';
import vi from 'translation/vi.json';

const defaultLang = window.localStorage.getItem('language') || 'en';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en,
      vi,
    },
    lng: defaultLang,
    fallbackLng: defaultLang,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
