import { SubscriptionStatusType } from 'utils/consts';

export const REQUEST_USER_SIGNIN = 'REQUEST_USER_SIGNIN';
export const REQUEST_USER_LOGOUT = 'REQUEST_USER_LOGOUT';
export const SIGNIN_ON_SUCCESS = 'SIGNIN_ON_SUCCESS';
export const SIGNIN_ON_ERROR = 'SIGNIN_ON_ERROR';
export const REQUEST_CHECK_USER_SIGNIN = 'REQUEST_CHECK_USER_SIGNIN';
export const SUBMITTED_SUBSCRIBE_PACKAGE = 'SUBMITTED_SUBSCRIBE_PACKAGE';

// interface
export interface UserSignInRequest{
   email: string;
   password: string;
}

export interface TokenLocalStorage {
  token: string;
  refreshToken: string;
}
export interface UserSigninResponse {
  active: number;
  createdAt: string;
  dob: string;
  email: string;
  emailVerifiedAt: string;
  firstName: string;
  gender: number;
  id: number;
  image: string;
  intro: string;
  language: string;
  lastName: string;
  location: string;
  occupation: string;
  refreshToken: string;
  rememberToken: string;
  studentId: string;
  technical: string;
  token: string;
  type: number;
  updatedAt: string;
  subscriptionStatus?: SubscriptionStatusType;
  isEmployer?: boolean;
}
export interface ErrorsResponse {
  code: string;
  detail: string;
  title: string;
}
export interface AuthResponseType {
  credential: string;
  operationType: string;
  user: UserSigninResponse;
}
export interface UserSignInState {
  user: UserSigninResponse | null;
  loading: boolean;
  errors: ErrorsResponse | null;
  isLogged: boolean;
}

export interface RequestSignInAction {
    type: typeof REQUEST_USER_SIGNIN;
    userSignIn: UserSignInRequest;
}
interface SignInOnSuccessAction {
    type: typeof SIGNIN_ON_SUCCESS;
    userResponse: UserSigninResponse;
}
interface SignInOnErrorAction {
    type: typeof SIGNIN_ON_ERROR;
    errors: ErrorsResponse;
}
interface RequestLogoutAction {
  type: typeof REQUEST_USER_LOGOUT;
}

export interface CheckUserSignInAction {
  type: typeof REQUEST_CHECK_USER_SIGNIN;
}

export interface SubmittedSubscribePackage {
  type: typeof SUBMITTED_SUBSCRIBE_PACKAGE;
}

export type UserSignInActionTypes =
  | RequestSignInAction
  | SignInOnSuccessAction
  | SignInOnErrorAction
  | RequestLogoutAction
  | CheckUserSignInAction
  | SubmittedSubscribePackage;
