import {
  CountryTypes,
  ErrorsResponse,
  GET_LIST_COUNTRY_ON_ERROR,
  GET_LIST_COUNTRY_ON_SUCCESS,
  RequestGetListCountryAction,
  RequestGetListCountryOnErrorAction,
  RequestGetListCountryOnSuccessAction,
  REQUEST_GET_LIST_COUNTRY,
} from './types';

export const requestGetListCountry = (
): RequestGetListCountryAction => ({
  type: REQUEST_GET_LIST_COUNTRY,
});

export const requestGetListCountrySuccess = (
  data: CountryTypes[],
): RequestGetListCountryOnSuccessAction => ({
  type: GET_LIST_COUNTRY_ON_SUCCESS,
  data,
});

export const requestGetListCountryError = (
  errors: ErrorsResponse,
): RequestGetListCountryOnErrorAction => ({
  type: GET_LIST_COUNTRY_ON_ERROR,
  errors,
});
