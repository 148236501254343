import React from 'react';

import mapModifiers from 'function/mapModifier';

interface HeadingProps {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  size?: 'large-max' | 'large' | 'small' | 'xsmall' | 'md' | 'lg' | 'bigger';
  weight?: 'light' | 'bold'| 'bold-lg';
  modifiers?: (
    | 'center'
    | 'right'
    | 'italic'
    | 'white'
    | 'normal'
    | 'gray'
    | 'blue'
    | 'brand-blue-dark'
    | 'uppercase'
    | 'font-lato'
    | 'font-lora'
    | 'font-muli'
    | 'cadet')[];
  children?: React.ReactNode;
}

const Heading: React.FC<HeadingProps> = ({
  type,
  weight,
  size,
  modifiers,
  children,
}: HeadingProps) => {
  const owlClass = 'a-heading';
  const renderHeading = () => {
    const className = mapModifiers(owlClass, weight, size, modifiers);
    switch (type) {
      case 'h1':
        return <h1 className={className}>{children}</h1>;
      case 'h2':
        return <h2 className={className}>{children}</h2>;
      case 'h3':
        return <h3 className={className}>{children}</h3>;
      case 'h4':
        return <h4 className={className}>{children}</h4>;
      case 'h5':
        return <h5 className={className}>{children}</h5>;
      default:
        return <h2 className={className}>{children}</h2>;
    }
  };

  return renderHeading();
};

Heading.defaultProps = {};

export default Heading;
