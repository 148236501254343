/* eslint-disable @typescript-eslint/camelcase */
export const REQUEST_FETCH_ABOUT_YOU = 'REQUEST_FETCH_ABOUT_YOU';
export const FETCH_ABOUT_YOU_SUCCESS = 'FETCH_ABOUT_YOU_SUCCESS';
export const FETCH_ABOUT_YOU_ERROR = 'FETCH_ABOUT_YOU_ERROR';

export const CLEAN_UP = 'CLEAN_UP';

export interface AboutYouResponse {
  id: number;
  active: number;
  display_order: number;
  created_at: string;
  updated_at: string;
  active_text: string;
  locale: string;
  title: string;
  translations: {
    id: number;
    about_you_id: number;
    locale: string;
    title: string;
  }[];
}

export interface PostContactParams {
  name: string;
  email: string;
  phone: string;
  about_you_id: string;
  message: string;
}

export interface ErrorsResponse {
  field: string;
  message: string;
}

export interface ContactState {
  aboutYou: AboutYouResponse[] | null;
  loading: boolean;
  errors: ErrorsResponse[] | null;
}

interface RequestFetchAboutYou {
  type: typeof REQUEST_FETCH_ABOUT_YOU;
}

interface FetchAboutYouSuccess {
  type: typeof FETCH_ABOUT_YOU_SUCCESS;
  response: AboutYouResponse[];
}

interface FetchAboutYouError {
  type: typeof FETCH_ABOUT_YOU_ERROR;
  errors: ErrorsResponse[];
}

export type ContactActionTypes =
  | RequestFetchAboutYou
  | FetchAboutYouSuccess
  | FetchAboutYouError;
