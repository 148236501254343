const ROUTE_PATH = {
  EMPLOYEE_WORK_FOLDER: '/employee/work-folder',
  PARTICIPANT_WORK_FOLDER: '/participant/work-folder',
  PARTICIPANT_LIST_COURSE: '/participant/list-course',
  PARTICIPANT_CATEGORY_WORK_FOLDER: (categoryId: number): string => `${ROUTE_PATH.PARTICIPANT_WORK_FOLDER}/category/${categoryId}`,
  EMPLOYEE_OWN_WORK_DETAIL: (projectId: number): string => `/work-folder/own-work/${projectId}`,
  PARTICIPANT_OWN_WORK_DETAIL: (categoryId: number, projectId: number): string => `/work-folder/own-work/category/${categoryId}/project/${projectId}`,
  PARTICIPANT_VIEW_RESULT_COURSE: (slug: string): string => `/participant/course/${slug}/result`,
  EXPERT_VIEW_PARTICIPANT_WORK_FOLDER: (participantId: number, categoryId: number): string => `/expert/work-folder/participant/${participantId}/category/${categoryId}`,
  EXPERT_VIEW_EMPLOYEE_WORK_FOLDER: (participantId: number): string => `/expert/work-folder/employee/${participantId}`,
  EXPERT_VIEW_PROJECT_DETAIL: (projectId: number): string => `/expert/project/${projectId}`,
  SPONSOR_VIEW_PARTICIPANT_WORK_FOLDER: (participantId: number, categoryId: number): string => `/sponsor/work-folder/participant/${participantId}/category/${categoryId}`,
  SPONSOR_VIEW_EMPLOYEE_WORK_FOLDER: (participantId: number): string => `/sponsor/work-folder/employee/${participantId}`,
  SPONSOR_VIEW_RESULT_COURSE_OF_PARTICIPANT: (courseSlug: string, participantId: number): string => `/course/${courseSlug}/employer/participant/portfolio/${participantId}`,
  SPONSOR_VIEW_PROJECT_DETAIL: (projectId: number): string => `/sponsor/project/${projectId}`,
};

export default ROUTE_PATH;
