/* eslint-disable linebreak-style */
export const REQUEST_GET_LIST_COURSE_FILES = 'REQUEST_GET_LIST_COURSE_FILES';
export const GET_LIST_COURSE_FILES_ON_SUCCESS = 'GET_LIST_COURSE_FILES_ON_SUCCESS';
export const GET_LIST_COURSE_FILES_ON_ERROR = 'GET_LIST_COURSE_FILES_ON_ERROR';
export const REQUEST_GET_FILE_ALL_VERSIONS = 'REQUEST_GET_FILE_ALL_VERSIONS';

// interface
export interface ListCourseFilesResponse {
  id: number;
  phaseFolderId: number;
  name: string;
  subName: string;
  thumbnail: string;
  url: string;
  mimeType: string;
  extension: string;
  size: number;
  isLatest: number;
  isLate: number;
  accountId: number;
  userId: number;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
  totalFiles: number;
}

export interface ListCourseFilesState {
  courseFiles: ListCourseFilesResponse[];
  loading: boolean;
  errors: ErrorsResponse | null;
}

export interface ErrorsResponse {
  code: string;
  detail: string;
  title: string;
}

export interface RequestGetListCourseFilesAction {
  type: typeof REQUEST_GET_LIST_COURSE_FILES;
  folferId: number;
}

interface GetListCourseFilesOnErrorAction {
  type: typeof GET_LIST_COURSE_FILES_ON_ERROR;
  errors: ErrorsResponse;
}
interface GetListCourseFilesOnSuccessAction {
  type: typeof GET_LIST_COURSE_FILES_ON_SUCCESS;
  data: ListCourseFilesResponse[];
}

export interface RequestFilesAllVersionsAction {
  type: typeof REQUEST_GET_FILE_ALL_VERSIONS;
  fileId: number;
  userType: number;
  participantId?: number;
}

export type ListCourseFileActionTypes =
  | RequestGetListCourseFilesAction
  | GetListCourseFilesOnErrorAction
  | GetListCourseFilesOnSuccessAction
  | RequestFilesAllVersionsAction;
