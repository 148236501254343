import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import {
  fetchAboutYouError, fetchAboutYouSuccess,
} from 'actions/pages/contact';
import {
  REQUEST_FETCH_ABOUT_YOU, AboutYouResponse,
} from 'actions/pages/types';
import { getAboutYou } from 'apis/requests/contact';


function* fetchAboutYouSaga(): Generator {
  try {
    const data = (yield call(getAboutYou)) as AboutYouResponse[];
    yield put(fetchAboutYouSuccess(data));
  } catch (e) {
    yield put(fetchAboutYouError(e));
  }
}
export default function* watchFetchContactPageSaga(): Generator {
  yield all([takeLatest(REQUEST_FETCH_ABOUT_YOU, fetchAboutYouSaga)]);
}
