import { httpNode } from '../instance';

import { DataAssessmentCourseResponse } from 'reducers/detailcourse/criteria/types';
import { PhaseSlug, GetFolderSlug, DataResponse } from 'reducers/detailcourse/foldercourse/types';
import {
  DataDetailCourseResponse,
  SlugCourse,
  ResponseEndPhase,
  ResponseStartPhase,
} from 'reducers/detailcourse/phase/types';
import { ItemPhase } from 'reducers/pages/home/types';

export const getDetailCourse = async (
  slugCourse: SlugCourse,
): Promise<DataDetailCourseResponse> => {
  const response = await httpNode.get(`course/${slugCourse}/participant`);
  return response.data.data;
};

export const getProgress = async (
  slugCourse: SlugCourse,
): Promise<ItemPhase[]> => {
  const response = await httpNode.get(`course/${slugCourse}/participant/progress-assessments`);
  return response.data.data;
};

export const getAllFolder = async (
  getFolderSlug: GetFolderSlug,
): Promise<DataResponse> => {
  const response = await httpNode.get(`course/${getFolderSlug.courseSlug}/participant/phase/${getFolderSlug.phaseSlug}`);
  return response.data.data;
};

export const getAssessmentCourse = async (
  slugPhase: PhaseSlug,
): Promise<DataAssessmentCourseResponse> => {
  const response = await httpNode.get(`course/${slugPhase}/participant/assessments`);
  return response.data.data;
};
interface Comment{
  courseId: number;
  comment: string;
}

export const postParticipantComment = async (params: Comment): Promise<any> => {
  const res = await httpNode.post('course/participant/comment', params);
  return res.data;
};

export const postStartPhase = async (params: {
  phaseId: number;
}): Promise <ResponseStartPhase> => {
  const res = await httpNode.post('course/participant/phase/start', params);
  return res.data.data;
};

export const postEndPhase = async (params: {
  phaseId: number;
}): Promise <ResponseEndPhase> => {
  const res = await httpNode.post('course/participant/phase/end', params);
  return res.data.data;
};
