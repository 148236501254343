import { call, put, takeLatest } from 'redux-saga/effects';

import { listCourseFiles, getFileAllVersions, getFileAllVersionsFromExpert } from 'apis/requests/listcoursefiles';
import {
  getListCourseFilesOnError,
  getListCourseFilesOnSuccess,
} from 'reducers/coursefile/actions';
import {
  RequestGetListCourseFilesAction,
  REQUEST_GET_LIST_COURSE_FILES,
  REQUEST_GET_FILE_ALL_VERSIONS,
  ListCourseFilesResponse,
  RequestFilesAllVersionsAction,
} from 'reducers/coursefile/types';


export function* requestListCourseFiles(action: RequestGetListCourseFilesAction): Generator {
  try {
    const data = (yield call(listCourseFiles, action.folferId)) as ListCourseFilesResponse[];
    yield put(getListCourseFilesOnSuccess(data));
  } catch (e) {
    yield put(getListCourseFilesOnError(e.response.data.errors));
  }
}

export function* requestFileAllVersions(action: RequestFilesAllVersionsAction): Generator {
  try {
    if (action.userType === 1) {
      const data = (yield call(getFileAllVersions, action.fileId)) as ListCourseFilesResponse[];
      yield put(getListCourseFilesOnSuccess(data));
    } else if (action.userType === 2 && action.participantId) {
      const data = (yield call(getFileAllVersionsFromExpert,
        action.fileId, action.participantId)) as ListCourseFilesResponse[];
      yield put(getListCourseFilesOnSuccess(data));
    }
  } catch (e) {
    yield put(getListCourseFilesOnError(e));
  }
}

export default function* watchListCourseFilesSaga(): Generator {
  yield takeLatest(REQUEST_GET_LIST_COURSE_FILES, requestListCourseFiles);
  yield takeLatest(REQUEST_GET_FILE_ALL_VERSIONS, requestFileAllVersions);
}
