/* eslint-disable react/no-danger */
import React from 'react';

import mapModifiers from 'function/mapModifier';

type FontType = 'muli';
type TextSizeType =
  | 'xs' // xs
  | 'sm' // sm
  | 'sm-small'
  | 'lg' // lg
  | 'xl' // xl
  | 'xxl'
  | 'fs50-lg'
  | 'fs12-lg'
  | 'fs18-lg'
  | 'fs24-lg'
  | 'sz16'
type WeightType = 'light' | 'bold';
export type Modifiers =
  | 'uppercase'
  | 'italic'
  | 'decoration'
  | 'overflow2'
  // color
  | 'white'
  | 'gray'
  | 'brand-blue-dark'
  | 'red-dark'
  | 'cadet'

  // TextAlign
  | 'center'
  | 'right'
  | 'underline'

interface TextProps {
  font?: FontType;
  size?: TextSizeType;
  weight?: WeightType;
  modifiers?: Modifiers[];
  children?: React.ReactNode;
  dangerouslySetInnerHTML?: string | null;
}

const Text: React.FC<TextProps> = ({
  children,
  size,
  weight,
  modifiers,
  dangerouslySetInnerHTML,
}: TextProps) => {
  const owlClass = 'a-text';

  if (dangerouslySetInnerHTML) {
    return (
      <p
        dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }}
        className={mapModifiers(owlClass, size, weight, modifiers)}
      />
    );
  }
  return (
    <p className={mapModifiers(owlClass, size, weight, modifiers)}>
      {children}
    </p>
  );
};

Text.defaultProps = {};

export default Text;
