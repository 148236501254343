import { combineReducers } from 'redux';

import criteria from './criteria';
import foldercourse from './foldercourse';
import phase from './phase';
import progress from './progess';

export default combineReducers({
  phase,
  progress,
  foldercourse,
  criteria,
});
