/* eslint-disable linebreak-style */
export const REQUEST_USER_UPDATE_PASSWORD = 'REQUEST_USER_UPDATE_PASSWORD';
export const UPDATE_PASSWORD_ON_SUCCESS = 'UPDATE_PASSWORD_ON_SUCCESS';
export const UPDATE_PASSWORD_ON_ERROR = 'UPDATE_PASSWORD_ON_ERROR';
export const REQUEST_RESET_FORM_UPDATE_PASSWORD = 'REQUEST_RESET_FORM_UPDATE_PASSWORD';

// interface
export interface UserUpdatePasswordRequest{
  oldPassword: string;
  newPassword: string;
}

export interface ErrorsResponse {
  code: string;
  detail: string;
  title: string;
}
export interface UserUpdatePasswordState {
  loading: boolean;
  errors: ErrorsResponse | null;
  isSuccess: boolean;
}
export interface RequestUpdatePasswordAction {
  type: typeof REQUEST_USER_UPDATE_PASSWORD;
  userUpdatePassword: UserUpdatePasswordRequest;
}
export interface RequestResetFormUpdatePasswordtAction {
  type: typeof REQUEST_RESET_FORM_UPDATE_PASSWORD;
}
interface UpdatePasswordOnErrorAction {
  type: typeof UPDATE_PASSWORD_ON_ERROR;
  errors: ErrorsResponse;
}
interface UpdatePasswordOnSuccessAction {
  type: typeof UPDATE_PASSWORD_ON_SUCCESS;
}

export type UserUpdatePasswordActionTypes =
  | RequestUpdatePasswordAction
  | UpdatePasswordOnSuccessAction
  | UpdatePasswordOnErrorAction
  | RequestResetFormUpdatePasswordtAction;
