import { httpNode } from '../instance';

import { PublishPortfolioListResponse } from 'reducers/expertpublishportfolio/types';
import { ListCoursesRequest } from 'reducers/pages/courses/types';

// eslint-disable-next-line import/prefer-default-export
export const getPublishPortfolioList = async (
  params: ListCoursesRequest,
): Promise<PublishPortfolioListResponse> => {
  try {
    const response = await httpNode.get(`course/expert/list-courses/public-portfolio?pageNum=${params.pageNum}&pageSize=${params.pageSize}`);
    return response.data;
  } catch (e) {
    throw e.message;
  }
};
