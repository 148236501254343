import { httpNode } from '../instance';

import { DataNotificationResponse } from 'reducers/notification/types';

export const getExpertNotification = async (
): Promise<DataNotificationResponse[]> => {
  try {
    const response = await httpNode.get('/account/expert/notifications');
    return response.data.data;
  } catch (e) {
    throw e.message;
  }
};

export const getParticipantNotification = async (
): Promise<DataNotificationResponse[]> => {
  try {
    const response = await httpNode.get('/account/participant/notifications');
    return response.data.data;
  } catch (e) {
    throw e.message;
  }
};

export const getEmployerNotification = async (
): Promise<DataNotificationResponse[]> => {
  try {
    const response = await httpNode.get('/account/employer/notifications');
    return response.data.data;
  } catch (e) {
    throw e.message;
  }
};

export const postNotification = async (): Promise<void> => {
  try {
    await httpNode.post('/account/seen-notification');
  } catch (e) {
    throw e.message;
  }
};
