import { http } from '../instance';

import { SystemResponse } from 'reducers/system/types';

const getSystem = async (): Promise<SystemResponse> => {
  const response = await http.get('system-config');
  return response.data.data;
};

export default getSystem;
