import {
  ActionType, ModalActions, ModalType, State,
} from './types';

const reducer = (state: State, action: ModalActions): State => {
  switch (action.type) {
    case ActionType.Toggle:
      return {
        ...state,
        modal: {
          ...state.modal,
          login: action.payload.modal === ModalType.Login && action.payload.status,
          forgot: action.payload.modal === ModalType.Forgot && action.payload.status,
          register: action.payload.modal === ModalType.Register && action.payload.status,
        },
      };
    default:
      return state;
  }
};

export default reducer;
