import {
  REQUEST_START_LOADING,
  REQUEST_STOP_LOADING,
  LoadingActionsType,
  LoadingInitState,
} from './types';

const initState: LoadingInitState = {
  loading: false,
};
export default (state = initState, action: LoadingActionsType): LoadingInitState => {
  switch (action.type) {
    case REQUEST_START_LOADING:
      return {
        loading: true,
      };
    case REQUEST_STOP_LOADING: {
      return {
        loading: false,
      };
    }
    default:
      return state;
  }
};
