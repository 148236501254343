/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { getUrlWithRole } from 'function/utils';
import { UserSigninResponse } from 'reducers/auth/types';

type RedirectWithRoleProps = {
  component: any;
  path: string;
  exact: boolean;
  user?: UserSigninResponse|null;
};

const RedirectWithRole = ({
  component: Component,
  user,
  ...rest
}: RedirectWithRoleProps) => (
  <Route
    {...rest}
    render={(routeProps) => ((user && rest.path === '/') ? (
      <Redirect
        to={getUrlWithRole(user && user.type)}
      />
    ) : (
      <Component {...routeProps} />
    ))}
  />
);

export default RedirectWithRole;
