import { ErrorsResponse } from '../phase/types';
import { Expert } from '../progess/types';

import { PhasesTranslations } from 'reducers/pages/home/types';

export const REQUEST_GET_ALL_FOLDER = 'REQUEST_GET_ALL_FOLDER';
export const GET_ALL_FOLDER_SUCCESS = 'GET_ALL_FOLDER_SUCCESS';
export const GET_ALL_FOLDER_ERROR = 'GET_ALL_FOLDER_ERROR';
export const CLEAR_ALL_FOLDER = 'CLEAR_ALL_FOLDER';
export interface ItemFolder{
  id: number;
  phaseId: number;
  name: string;
  accountId: null|number;
  userId: number;
  maxSize: number;
  minSize: number;
  maxFiles: number;
  minFiles: number;
  deletedAt: null;
  createdAt: string;
  updatedAt: string;
}
export interface CourseProgress {
  total: number;
  completed: number;
}
export interface DataResponse {
  id: number;
  courseId: number;
  image: string;
  video: string;
  active: number;
  startDate: string;
  endDate: string;
  startDateOfAssessment: string;
  endDateOfAssessment: string;
  displayOrder: number;
  phasesTranslations: PhasesTranslations[];
  phaseFolders: ItemFolder[];
  profile: Expert;
  courseProgress: CourseProgress;
  nearDeadline: boolean;
}
export type PhaseSlug=string;
export type GetFolderSlug={
  courseSlug: string;
  phaseSlug: string;
};
export interface RequestAllFolderAction {
  type: typeof REQUEST_GET_ALL_FOLDER;
  payload: GetFolderSlug;
}
export interface GetAllFolderSuccessAction {
  type: typeof GET_ALL_FOLDER_SUCCESS;
  payload: DataResponse;
}
export interface GetAllFolderErrorAction {
  type: typeof GET_ALL_FOLDER_ERROR;
  payload: ErrorsResponse;
}
export interface CleanAllFolderAction {
  type: typeof CLEAR_ALL_FOLDER;
}
export type TypeAllFolderActions=RequestAllFolderAction
                                  |GetAllFolderSuccessAction
                                  |GetAllFolderErrorAction
                                  |CleanAllFolderAction;
