import {
  REQUEST_USER_LOGOUT,
  REQUEST_USER_SIGNIN,
  SIGNIN_ON_ERROR,
  SIGNIN_ON_SUCCESS,
  UserSignInActionTypes,
  UserSignInState,
  REQUEST_CHECK_USER_SIGNIN,
  SUBMITTED_SUBSCRIBE_PACKAGE,
  UserSigninResponse,
} from './types';

import { SubscriptionStatusType } from 'utils/consts';

const defaultState: UserSignInState = {
  user: null,
  loading: false,
  errors: null,
  isLogged: false,
};

export default (
  state = defaultState,
  action: UserSignInActionTypes,
): UserSignInState => {
  switch (action.type) {
    case REQUEST_CHECK_USER_SIGNIN:
    case REQUEST_USER_SIGNIN:
      return {
        ...state,
        errors: null,
        loading: true,
      };
    case SIGNIN_ON_SUCCESS:
      return {
        ...state,
        user: action.userResponse,
        errors: null,
        isLogged: true,
        loading: false,
      };
    case SIGNIN_ON_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case REQUEST_USER_LOGOUT:
      return {
        ...state,
        user: null,
        errors: null,
        loading: false,
        isLogged: false,
      };
    case SUBMITTED_SUBSCRIBE_PACKAGE:
      return {
        ...state,
        user: {
          ...state.user as UserSigninResponse,
          subscriptionStatus: SubscriptionStatusType.PENDING,
        },
      };
    default:
      return state;
  }
};
