import { httpNode } from '../instance';

interface ErrorsResponse {
  code: string;
  detail: string;
  title: string;
}
interface ParamsForgotPassword{
  email: string;
}
export default async (params: ParamsForgotPassword): Promise<boolean|ErrorsResponse> => {
  try {
    await httpNode.post('account/forgot-password', params);
    return true;
  } catch (error) {
    return error[0].detail;
  }
};
