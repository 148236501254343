import { yupResolver } from "@hookform/resolvers";
import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import { setAccessToken, setRefreshToken } from "apis/localstorage";
import { postLogin } from "apis/requests/user";
import Button from "components/atoms/Button";
import Input from "components/atoms/Input";
import Text from "components/atoms/Text";
import Modal from "components/organisms/Modal";
import { userSignInOnSuccess } from "reducers/auth/actions";
import {
  requestStartLoading,
  requestStopLoading,
} from "reducers/loading/actions";

interface FormLogin {
  email: string;
  password: string;
}

interface PopupLoginProps {
  isShow: boolean;
  onHide?: (value: string) => void;
  onLoginSuccess?: () => void;
  onRedirectToForgotPassword?: () => void;
  handleRedirectRegister?: () => void;
}
const PopupLogin: React.FC<PopupLoginProps> = ({
  isShow,
  onHide,
  onLoginSuccess,
  onRedirectToForgotPassword,
  handleRedirectRegister,
}) => {
  const { t } = useTranslation([
    "form_user",
    "button",
    "placeholder",
    "validation",
  ]);
  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t("validation:email_required"))
      .email(t("validation:email_not_valid")),
    password: yup.string().required(t("validation:password_required")),
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const methos = useForm<FormLogin>({
    resolver: yupResolver(schema),
  });
  const handleSubmit = (data: FormLogin): void => {
    (async (): Promise<void> => {
      dispatch(requestStartLoading());
      try {
        const userLogin = await postLogin(data);
        dispatch(requestStopLoading());
        setAccessToken(userLogin.token);
        setRefreshToken(userLogin.refreshToken);
        dispatch(userSignInOnSuccess(userLogin));
        if (onLoginSuccess) {
          onLoginSuccess();
        }
        if (userLogin.type === 2) {
          history.push("/expert/courses");
        } else if (userLogin.type === 3) {
          history.push("/employer/courses");
        }
      } catch (error) {
        dispatch(requestStopLoading());
        methos.setError("email", {
          type: "validate",
          message: t("wrong_information"),
        });
        methos.setError("password", {
          type: "validate",
          message: t("wrong_information"),
        });
      }
    })();
  };

  const onHideModal = (): void => {
    if (onHide) {
      onHide("Login");
    }
  };

  return (
    <Modal modifier="login" isShow={isShow} onHide={onHideModal}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methos}>
        <form
          className="t-popuplogin"
          onSubmit={methos.handleSubmit(handleSubmit)}
        >
          <div className="t-popuplogin_login">
            <Text size="fs50-lg" weight="bold">
              {t("title_login")}
            </Text>
          </div>
          <div className="t-popuplogin_input">
            <div className="t-popuplogin_content">
              <Text size="fs24-lg" weight="bold">
                Email
              </Text>
            </div>
            <Input
              modifiers={["border-default", "login", "disabled-default"]}
              type="text"
              name="email"
              placeholder={t("placeholder:enter_email")}
            />
          </div>
          <div className="t-popuplogin_input">
            <div className="t-popuplogin_content">
              <Text size="fs24-lg" weight="bold">
                {t("password")}
              </Text>
            </div>
            <Input
              modifiers={["border-default", "login", "disabled-default"]}
              type="password"
              name="password"
              placeholder="*************"
            />
          </div>
          <div className="t-popuplogin_action">
            <button
              type="button"
              className="t-popuplogin_forgot"
              onClick={onRedirectToForgotPassword}
            >
              {t("forgot_password")}
            </button>
          </div>
          <div className="t-popuplogin_btn">
            <div className="t-popuplogin_btn_login">
              <Button
                modifiers={["white", "ovalFill", "uppercase", "bold", "shadow"]}
                type="submit"
              >
                {t("button:login")}
              </Button>
            </div>
            <div className="t-popuplogin_btn_register">
              <Button
                modifiers={[
                  "white",
                  "ovalFillBlue",
                  "uppercase",
                  "bold",
                  "shadow",
                ]}
                type="button"
                handleClick={handleRedirectRegister}
              >
                {t("button:register")}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};
export default PopupLogin;
