/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import {
  REQUEST_GET_DATA_HOME,
  GET_DATA_HOME_ON_SUCCESS,
  HomePageActionTypes,
  DataHomePageResponse,
  DataCarouselsParner,
} from './types';

const defaultState: DataHomePageResponse = {
  isLoading: false,
  dataHomeCarousel: null,
  programs: null,
  carousels: null,
  benefits: null,
  titles: null,
  sectionAbout: null,
  sectionStructure: null,
  sectionSupport: null,
  courses: null,
};

export default (state = defaultState,
  action: HomePageActionTypes): DataHomePageResponse => {
  switch (action.type) {
    case REQUEST_GET_DATA_HOME:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DATA_HOME_ON_SUCCESS: {
      if (action.dataHomeCarousel && action.dataHomeCarousel.page_meta_data
        && action.dataHomeCourse) {
        return {
          ...state,
          dataHomeCarousel: action.dataHomeCarousel,
          programs: [action.dataHomeCarousel.page_meta_data.section_program_6,
            action.dataHomeCarousel.page_meta_data.section_program_7],
          carousels: Object.values(
            action.dataHomeCarousel.page_meta_data.home_slider_2.slider,
          ) as DataCarouselsParner[],
          benefits: action.dataHomeCarousel.page_meta_data.home_benefits_3,
          titles: Object.values(action.dataHomeCarousel.page_meta_data.section_title_4.title),
          sectionAbout: action.dataHomeCarousel.page_meta_data.section_about_8,
          sectionStructure: action.dataHomeCarousel.page_meta_data.section_structure_9,
          sectionSupport: action.dataHomeCarousel.page_meta_data.section_support_10,
          courses: action.dataHomeCourse,
          isLoading: false,
        };
      }
      return { ...state };
    }

    default:
      return state;
  }
};
