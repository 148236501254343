import {
  REQUEST_START_LOADING,
  REQUEST_STOP_LOADING,
  LoadingActionsType,
} from './types';

export const requestStartLoading = (): LoadingActionsType => ({
  type: REQUEST_START_LOADING,
});

export const requestStopLoading = (): LoadingActionsType => ({
  type: REQUEST_STOP_LOADING,
});
