import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';

import IconBotton from 'components/atoms/IconButton';
import mapModifiers from 'function/mapModifier';

export interface ModalboostrapProps {
  modifier?: 'login' | 'upload' | 'report';
  isShow: boolean;
  onHide?: (value: boolean) => void;
  size?: 'lg';
  hideCloseIcon?: boolean;
}
const Modalboostrap: React.FC<ModalboostrapProps> = ({
  children,
  modifier,
  isShow,
  onHide,
  size,
  hideCloseIcon,
}) => {
  const handleHide = (): void => {
    if (onHide) {
      onHide(false);
    }
  };

  return (
    <Modal
      onHide={handleHide}
      dialogClassName={(modifier === 'login' || modifier === 'report') ? `modal-custom-main modal-border modal-custom-main-${modifier}` : `modal-custom-main modal-custom-main-${modifier}`}
      show={isShow}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <div className={mapModifiers('o-modal', modifier, size)}>
          {modifier && !hideCloseIcon && <div className="o-modal_icon"><IconBotton handleClick={handleHide} iconName="close" modifiers={['radius']} /></div>}
          <div className="o-modal_children">{children}</div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default Modalboostrap;
