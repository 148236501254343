import {
  REQUEST_SHOW_MODAL_ERROR,
  REQUEST_HIDE_MODAL_ERROR,
  ModalErrorState,
  ModalErrorActionType,
} from './types';


const initState = {
  show: false,
  code: '',
};
export default (state = initState, action: ModalErrorActionType): ModalErrorState => {
  switch (action.type) {
    case REQUEST_SHOW_MODAL_ERROR:
      return {
        show: true,
        code: action.payload,
      };
    case REQUEST_HIDE_MODAL_ERROR: {
      return initState;
    }
    default:
      return state;
  }
};
