import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import { getAllFolder } from 'apis/requests/detailcourse';
import {
  getAllFolderSuccess,
  getAllFolderError,
} from 'reducers/detailcourse/foldercourse/actions';
import {
  REQUEST_GET_ALL_FOLDER,
  RequestAllFolderAction,
  DataResponse,
} from 'reducers/detailcourse/foldercourse/types';


function* fetchDetailCourseSaga(action: RequestAllFolderAction): Generator {
  try {
    const data = (yield call(getAllFolder, action.payload)) as DataResponse;
    yield put(getAllFolderSuccess(data));
  } catch (errors) {
    yield put(getAllFolderError(errors[0]));
  }
}
export default function* watchFetchFolderCourseSaga(): Generator {
  yield takeLatest(REQUEST_GET_ALL_FOLDER, fetchDetailCourseSaga);
}
