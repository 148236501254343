/* eslint-disable import/no-cycle */
import { Assessment } from 'reducers/detailcourse/criteria/types';
import { CourseTranslations as CourseTranslate, PhasesTranslations } from 'reducers/pages/home/types';

export const REQUEST_GET_DETAIL_COURSE = 'REQUEST_GET_DETAIL_COURSE';
export const GET_DETAIL_COURSE_SUCCESS = 'GET_DETAIL_COURSE_SUCCESS';
export const GET_DETAIL_COURSE_ERROR = 'GET_DETAIL_COURSE_ERROR';
export const CLEAN_UP_DETAIL_COURSE = 'CLEAN_UP_DETAIL_COURSE';
export const UPDATE_DETAIL_COURSE = 'UPDATE_DETAIL_COURSE';
export interface ShareCourseType {
  id: number;
  image: string;
  active: number;
  displayOrder: number;
  startDate: string;
  endDate: string;
  courseManagement?: CourseFile[];
  courseFormats?: CourseFile[];
  supportAndAdvices?: CourseFile[];
  suppliers?: CourseFile[];
}
export interface CourseTranslations extends CourseTranslate {
  id: number;
  image: string;
  isHomepage: number;
  displayOrder: number;
  createdAt: string;
  endDate: string;
}
interface Accounts{
  AccountPhasesModel: {
    startDate: string;
    endDate?: string;
    assessment?: Assessment;
    accountId?: number;
    phaseId?: number;
    updatedAt?: string;
    id?: number;
  };
  id?: number;
}
interface CourseFile {
  title: string;
  fileName: string;
  link: string;
  className: string;
}
export interface Phase extends ShareCourseType {
  courseId: number;
  video: string;
  startDateOfAssessment: string;
  endDateOfAssessment: string;
  phasesTranslations: PhasesTranslations[];
  accounts: [] | Accounts[];
}
export interface ResponseStartPhase {
  updatedAt: string;
  id: number;
  accountId: number;
  phaseId: number;
  startDate: string;
}

export interface ResponseEndPhase {
  id: number;
  accountId: number;
  phaseId: number;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
}

export interface AccountCourse {
  id: number;
  studentId: string;
  firstName: string;
  lastName: string;
  intro: string;
  email: string;
  password: string;
  type: number;
  gender: number;
  image?: any;
  dob?: any;
  address: string;
  occupation?: any;
  technical?: any;
  language?: any;
  active: number;
  emailVerifiedAt?: any;
  rememberToken?: any;
  createdAt: Date;
  updatedAt: Date;
  prepareToWork?: any;
  subRole?: any;
  positionId: number;
  positionText?: any;
  availabilityId: number;
  availabilityText?: any;
  phone: string;
  subscribedMode: number;
  AccountCoursesModel: AccountCoursesModel;
}
export interface DataDetailCourseResponse extends ShareCourseType {
  accounts: AccountCourse[];
  image: string;
  courseTranslations: CourseTranslations[];
  phases: Phase[];
  estimateStartDate?: string;
  estimateEndDate?: string;
}
export interface ErrorsResponse {
  code: string;
  title: string;
  source: string;
}
export type SlugCourse = string;
export interface RequestDetailCourseAction {
  type: typeof REQUEST_GET_DETAIL_COURSE;
  payload: SlugCourse;
}
export interface GetDetailCourseSuccessAction {
  type: typeof GET_DETAIL_COURSE_SUCCESS;
  payload: DataDetailCourseResponse;
}
export interface GetDetailCourseErrorAction {
  type: typeof GET_DETAIL_COURSE_ERROR;
  payload: ErrorsResponse;
}
interface CleanUpAction {
  type: typeof CLEAN_UP_DETAIL_COURSE;
}

export interface UpdateDetailCourseAction {
  type: typeof UPDATE_DETAIL_COURSE;
  payload: Phase;
}

export type TypeDetailCourseActions = RequestDetailCourseAction
  | GetDetailCourseSuccessAction
  | GetDetailCourseErrorAction
  | UpdateDetailCourseAction
  | CleanUpAction;
