/* eslint-disable linebreak-style */
import {
  REQUEST_USER_UPDATE_PASSWORD,
  UPDATE_PASSWORD_ON_ERROR,
  UPDATE_PASSWORD_ON_SUCCESS,
  REQUEST_RESET_FORM_UPDATE_PASSWORD,
  UserUpdatePasswordRequest,
  UserUpdatePasswordActionTypes,
} from './types';

import { ErrorsResponse } from 'reducers/auth/types';

export const requestUserUpdatePassword = (
  userUpdatePassword: UserUpdatePasswordRequest,
): UserUpdatePasswordActionTypes => ({
  type: REQUEST_USER_UPDATE_PASSWORD,
  userUpdatePassword,
});

export const userUpdatePasswordOnError = (
  errors: ErrorsResponse,
): UserUpdatePasswordActionTypes => ({
  type: UPDATE_PASSWORD_ON_ERROR,
  errors,
});

export const userUpdatePasswordOnSuccess = (
): UserUpdatePasswordActionTypes => ({
  type: UPDATE_PASSWORD_ON_SUCCESS,
});

export const requestResetFormPassword = (
): UserUpdatePasswordActionTypes => ({
  type: REQUEST_RESET_FORM_UPDATE_PASSWORD,
});
