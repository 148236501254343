import { call, put, takeEvery } from 'redux-saga/effects';

import { getPublishPortfolioList } from 'apis/requests/expertpublishportfolio';
import {
  successGetPublishPortfolioList,
  errorGetPublishPortfolioList,
} from 'reducers/expertpublishportfolio/actions';
import {
  GET_PUBLISHPORTFOLIO_LIST_REQUEST,
  PublishPortfolioListResponse,
  RequestGetPublishPortfolioListAction,
} from 'reducers/expertpublishportfolio/types';

function* requestGetPublishPortfolioListSaga(
  action: RequestGetPublishPortfolioListAction,
): Generator {
  try {
    const data = (yield call(
      getPublishPortfolioList,
      action.request,
    )) as PublishPortfolioListResponse;
    yield put(successGetPublishPortfolioList(data));
  } catch (e) {
    yield put(errorGetPublishPortfolioList(e));
  }
}

export default function* watchGetPublishPortfolioListSaga(): Generator {
  yield takeEvery(GET_PUBLISHPORTFOLIO_LIST_REQUEST, requestGetPublishPortfolioListSaga);
}
