import { call, put, takeLatest } from 'redux-saga/effects';


import { getRegister } from 'apis/requests/register';
import {
  requestGetInfoRegistrationsSuccess,
  requestGetInfoRegistrationsError,
} from 'reducers/register/actions';
import { DataInfoRegister, ErrorsResponse, REQUEST_GET_INFO_REGISTRATION } from 'reducers/register/types';

export function* requestInfoRegistrations(): Generator {
  try {
    const data = (yield call(getRegister)) as DataInfoRegister;
    yield put(requestGetInfoRegistrationsSuccess(data));
  } catch (e) {
    yield put(requestGetInfoRegistrationsError(e as ErrorsResponse));
  }
}

export default function* watchFetchInfoRegistrationSaga(): Generator {
  yield takeLatest(REQUEST_GET_INFO_REGISTRATION, requestInfoRegistrations);
}
