import { AnyAction } from 'redux';
import { call, takeLatest } from 'redux-saga/effects';

import { REQUEST_DOWNLOAD_FILE } from 'actions/common/download';
import { downloadFile } from 'apis/requests/document';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* fetchDownloadFile({ url, filename, extension }: AnyAction) {
  yield call(downloadFile, url, filename, extension);
}

export default function* watchFetchDownloadFileSaga() {
  yield takeLatest(REQUEST_DOWNLOAD_FILE, fetchDownloadFile);
}
