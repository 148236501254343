/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import { yupResolver } from '@hookform/resolvers';
import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

import { DataInfoRegister } from 'reducers/register/types';

export type PulldownType={
  value: number;
  label: string;
}

export type FormResgisterType={
  address: string;
  availability: PulldownType;
  occupation: string;
  phone: string;
  dob: string;
  email: string;
  firstName: string;
  gender: PulldownType;
  languages: PulldownType[];
  lastName: string;
  password: string;
  positionId: PulldownType;
  reNewPassword: string;
  studentId?: string;
  countryId: PulldownType;
  companyCode?: string;
}

type UseRegisterType={
  methods: UseFormMethods<FormResgisterType>;
  isEnterPosition: boolean;
  setIsEnterPosition: Dispatch<SetStateAction<boolean>>;
}

const useRegister = (dataRegistration?: DataInfoRegister): UseRegisterType => {
  const [isEnterPosition, setIsEnterPosition] = useState(false);
  const oneLowercase = RegExp('(.*[a-z].*)');
  const oneUppercase = RegExp('(.*[A-Z].*)');
  const oneNumber = RegExp('(.*\\d.*)');
  const oneSpecial = RegExp('[@$!%*#&]');
  const { t } = useTranslation(['registration', 'placeholder', 'button', 'validation', 'employer_list_participants']);
  const formSchema = yup.object().shape({
    firstName: yup.string().required(t('validation:name_required')),
    lastName: yup.string().required(t('validation:lastName_required')),
    dob: yup.lazy((_value) => {
      if (typeof _value === 'string') {
        return yup.string().required(t('validation:dob_required'));
      }
      return yup.mixed().required(t('validation:dob_required'));
    }),
    email: yup.string().email(t('validation:email_not_valid')).required(t('validation:email_required')),
    phone: yup
      .string()
      .required(t('validation:phone_required'))
      .test({
        name: 'validator-custom-name',
        // eslint-disable-next-line object-shorthand
        test: function (value) {
          // You can add any logic here to generate a dynamic message
          return value && !isValidPhoneNumber(value)
            // eslint-disable-next-line react/no-this-in-sfc
            ? this.createError({
              message: t('validation:phone_not_valid'),
              path: 'phone', // Fieldname
            })
            : true;
        },
      }),
    gender: yup.object().required(t('validation:gender_required')),
    occupation: yup.string().required(t('validation:college_occupation_reuqired')),
    availability: yup.object().required(t('validation:availability_required')),
    countryId: yup.object().required(t('validation:country_id_required')),
    address: yup.string().required(t('validation:address_required')),
    positionId: yup.lazy((_value) => {
      if (isEnterPosition) {
        return yup.string().required(t('validation:position_required'));
      }
      return yup.object().required(t('validation:position_required'));
    }),
    languages: yup.lazy((_value) => {
      if (_value) {
        return yup.array().required(t('validation:lang_required'));
      }
      return yup.mixed().required(t('validation:lang_required'));
    }),
    password: yup.string()
      .required(t('validation:password_required'))
      .min(6, t('validation:password_min'))
      .max(32, t('validation:password_max'))
      .matches(oneLowercase, t('validation:onelowercase'))
      .matches(oneUppercase, t('validation:oneuppercase'))
      .matches(oneNumber, t('validation:onenumber'))
      .matches(oneSpecial, t('validation:onespecial')),
    reNewPassword: yup.string()
      .required(t('validation:password_required'))
      .min(6, t('validation:password_min'))
      .max(32, t('validation:password_max'))
      .matches(oneLowercase, t('validation:onelowercase'))
      .matches(oneUppercase, t('validation:oneuppercase'))
      .matches(oneNumber, t('validation:onenumber'))
      .matches(oneSpecial, t('validation:onespecial'))
      .oneOf([yup.ref('password')], t('validation:comfirm_password')),
  });
  const methods = useForm<FormResgisterType>({
    resolver: yupResolver(
      formSchema,
    ),
  });
  const watchPosition = methods.watch('positionId');
  useEffect(() => {
    if (!dataRegistration) return;
    const positionOther = dataRegistration.positions.find((item) => item.isEditable === 1);
    if (!positionOther) return;
    if (watchPosition
      && Number(watchPosition.value) === positionOther.positionTranslation.positionId) {
      setIsEnterPosition(true);
    }
  }, [dataRegistration, watchPosition]);

  return {
    methods,
    isEnterPosition,
    setIsEnterPosition,
  };
};
export default useRegister;
