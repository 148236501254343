enum NotificationType {
  EXPERT_PARTICIPANT_SUBMIT_FILE_LATE = 1,
  EXPERT_START_ASSESSMENT = 2,
  EXPERT_PARTICIPANT_SUBMIT_FINAL_COMMENT = 3,
  PARTICIPANT_HAS_ASSESSMENT_REPORT = 4,
  PARTICIPANT_DEADLINE_IS_NEAR = 5,
  PARTICIPANT_EXPERT_SUBMIT_FINAL_COMMENT = 6,
  EMPLOYER_HAS_PUBLISH_PORTFOLIO = 7,
  PARTICIPANT_FINISH_COURSE = 8,
  PARTICIPANT_START_COURSE = 9,
  EMPLOYER_SEND_NOTIFICATION_TO_PARTICIPANT = 10,
  PARTICIPANT_SUBSCRIBE_PACKAGE = 11,
  EXPERT_PARTICIPANT_SUBSCRIPTION_SUCCESSFULLY = 12,
  EXPERT_PUBLISH_COURSE_WORK = 13,
  EXPERT_PUBLISH_OWN_WORK = 14,
  PARTICIPANT_HAS_SUBMITTED_OWN_WORK = 15,
  PARTICIPANT_COMPLETED_PHASE = 16
}

enum AccountType {
  PARTICIPANT = 1,
  EXPERT = 2,
  SPONSOR = 3
}

enum AccountRegisterErrType {
  NOT_ALLOW_EMPTY = '0000',
  EMAIL_EXISTS = '2004',
  PHONE_NUMBER_EXISTS = '2005',
  STUDENT_ID_EXITS = '2006',
  COMPANY_CODE_NOT_FOUND = '2016'
}

enum PortfolioType {
  INDIVIDUAL = 'INDIVIDUAL',
  BELONG_TO_COMPANY = 'BELONG_TO_COMPANY'
}

enum GenderType {
  MALE = 1,
  FEMALE = 2
}

enum FilterPortfolioType {
  SELECT = 'SELECT',
  RADIO = 'RADIO'
}

enum SponsorHasRoleAccess {
  YES = 1,
  NO = 0
}

enum GeneralErrorType {
  INTERNAL_SERVER_ERROR = '0001',
  MISSING_ACCESS_TOKEN = '0002',
  INVALID_ACCESS_TOKEN = '0003',
  MISSING_REFRESH_TOKEN = '0004',
  INVALID_REFRESH_TOKEN = '0005',
  INVALID_FILE_EXTENSION = '0006',
  EXPIRED_ACCESS_TOKEN = '0007',
  EXPIRED_REFRESH_TOKEN = '0008'
}

enum CourseErrorType {
  COURSE_NOT_FOUND = '1000',
  PHASE_NOT_FOUND = '1001',
  FOLDER_NOT_FOUND = '1002',
  GREATER_THAN_MAX_FILE = '1003',
  FILE_NOT_FOUND = '1004',
  ASSESSMENT_EXISTS = '1005',
  CRITERIA_INVALID = '1006',
  COURSE_UNCOMPLETED = '1007',
  COURSE_COMMENTED = '1008',
  NOT_ACCEPT_TERM_CONDITION = '1009',
  PHASE_NOT_STARTED = '1010',
  COURSE_NOT_STARTED = '1011',
  PARTICIPANT_NOT_INCLUDE_COURSE = '1012',
  PHASE_NOT_ENDED = '1013',
  PHASE_STARTED = '1014',
  COURSE_ENDED = '1015',
  NOT_ENOUGH_TIME_TO_COMPLETED_COURSE = '1016',
  DURATION_OF_THE_PHASE = '1017',
  PHASE_ENDED = '1018',
}

enum AccountErrorType {
  ACCOUNT_NOT_FOUND = '2000',
  ACCOUNT_NOT_ACTIVATED = '2001',
  WRONG_PASSWORD = '2002',
  PARTICIPANT_PARTICIPATED_OTHER_COURSE = '2003',
  EMAIL_EXISTS = '2004',
  PHONE_NUMBER_EXISTS = '2005',
  AVAILABILITY_NOT_FOUND = '2006',
  POSITION_NOT_FOUND = '2007',
  POSITION_TEXT_IS_REQUIRED = '2008',
  TECHNICAL_COMPUTER_NOT_FOUND = '2009',
  TECHNICAL_TEXT_IS_REQUIRED = '2010',
  ACTIVATION_INVALID = '2011',
  PREPARE_TO_WORK_NOT_FOUND = '2012',
  PARTICIPANT_NOT_MEET_CONDITION = '2013',
  PARTICIPANT_PARTICIPATED_COURSE = '2014',
  COUNTRY_NOT_FOUND = '2015',
  COMPANY_NOT_FOUND = '2016',
  SPONSOR_DO_NOT_HAS_ACCESS = '2017'
}

const BANK_INFO = {
  accountName: 'CÔNG TY TNHH PATF GLOBAL',
  bankName: 'VIETCOMBANK',
  accountNumber: '1020913330',
  swiftCode: 'BFTV VNVX',
  packageCost: '499000',
};

enum SubscriptionStatusType {
  PENDING = 0,
  APPROVE = 1,
  DECLINE = 2
}

enum EndpointUploadFile {
  UPLOAD_PROJECT_FILE = 'upload-project-file',
  UPLOAD_IMAGE = 'upload-image'
}

enum ProjectStatus {
  PENDING = 0,
  APPROVED = 1,
  DENIED = 2
}

enum ChooseOptionStartCourse {
  LEARN_FREE_COURSE = 'LEARN_FREE_COURSE',
  SUBSCRIBE_PACKAGE = 'SUBSCRIBE_PACKAGE'
}

enum AssessmentCommentType {
  PHASE_COMMENT = 0,
  FINAL_COMMENT = 1
}

enum PublishPortfolioType {
  COURSE = 0, // publish course for free participant
  COURSE_WORK = 1 // publish course in work folder
}

export {
  NotificationType,
  AccountType,
  AccountRegisterErrType,
  PortfolioType,
  GenderType,
  FilterPortfolioType,
  SponsorHasRoleAccess,
  GeneralErrorType,
  CourseErrorType,
  AccountErrorType,
  BANK_INFO,
  SubscriptionStatusType,
  EndpointUploadFile,
  ProjectStatus,
  ChooseOptionStartCourse,
  AssessmentCommentType,
  PublishPortfolioType,
};
