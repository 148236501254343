import {
  REQUEST_GET_COURSE_EXPERT,
  GET_COURSE_EXPERT_SUCCESS,
  GET_COURSE_EXPERT_ERROR,
  CLEAR_COURSE_EXPERT,
  CourseExpertActionsType,
  ResponseDataType,
  ErrorsResponse,
  SlugCourse,
} from './types';

export const requestCourseExpert = (slug: SlugCourse): CourseExpertActionsType => ({
  type: REQUEST_GET_COURSE_EXPERT,
  payload: slug,
});
export const getCourseExpertSuccess = (data: ResponseDataType):
  CourseExpertActionsType => ({
  type: GET_COURSE_EXPERT_SUCCESS,
  payload: data,
});
export const getCourseExpertError = (error: ErrorsResponse):
  CourseExpertActionsType => ({
  type: GET_COURSE_EXPERT_ERROR,
  payload: error,
});

export const requestClearCourseExpert = (): CourseExpertActionsType => ({
  type: CLEAR_COURSE_EXPERT,
});
