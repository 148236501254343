import { httpNode } from '../instance';

export const getListCourse = async (): Promise<DataDetailtCourse[]> => {
  const response = await httpNode.get('course/homepages');
  return response.data.data;
};

export const getDetailCourseSlug = async (slug: string): Promise<DataDetailtCourse> => {
  const response = await httpNode.get(`/course/${slug}/homepages`);
  return response.data.data;
};
