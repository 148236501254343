import {
  REQUEST_USER_UPDATE_PASSWORD,
  UPDATE_PASSWORD_ON_ERROR,
  UPDATE_PASSWORD_ON_SUCCESS,
  UserUpdatePasswordActionTypes,
  UserUpdatePasswordState,
  REQUEST_RESET_FORM_UPDATE_PASSWORD,
} from './types';

const defaultState: UserUpdatePasswordState = {
  loading: false,
  isSuccess: false,
  errors: null,
};

export default (
  state = defaultState,
  action: UserUpdatePasswordActionTypes,
): UserUpdatePasswordState => {
  switch (action.type) {
    case REQUEST_USER_UPDATE_PASSWORD:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case UPDATE_PASSWORD_ON_SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
        errors: null,
      };
    case UPDATE_PASSWORD_ON_ERROR:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        errors: action.errors,
      };
    case REQUEST_RESET_FORM_UPDATE_PASSWORD:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};
