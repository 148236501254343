import { CourseTranslationsType } from 'reducers/expertcourse/types';

export const REQUEST_GET_LIST_COURSES = 'REQUEST_GET_LIST_COURSES';
export const GET_LIST_COURSES_ON_SUCCESS = 'GET_LIST_COURSES_ON_SUCCESS';
export const GET_LIST_COURSES_ON_ERROR = 'GET_LIST_COURSES_ON_ERROR';
export const RESET_LIST_COURSES = 'RESET_LIST_COURSES';

// interface
export interface ListCoursesRequest {
  pageNum: number;
  pageSize: number;
  searchText?: string;
}

export interface ListCoursesRequest2 {
  defaultPageNum: number;
  defaultPageSize: number;
  paginateOptions?: {
    pageSize: number;
    pageNum: number;
    categoryId: number;
  }[];
  searchText?: string;
}

export interface MetaResponse {
  totalPages: number;
  totalRecords: number;
}

export interface LinksResponse {
  self: string;
  first: string;
  prev: string;
  next: string;
  last: string;
}

export interface CategoryResponse {
  id: number;
  title: string;
}

interface AccountCoursesModel{
  id: number;
  accountId: number;
  courseId: number;
  status: number;
  total: null|number;
  comment: number|null;
  expertComment: number|null;
  accept: number;
  createdAt: string;
  updatedAt: string;
}
export interface Account{
  id: number;
  AccountCoursesModel: AccountCoursesModel;
}
export interface DataListResponse {
  id: number;
  image: string;
  courseManagement: string;
  courseFormats: string;
  supportAndAdvices: string;
  suppliers: string;
  active: number;
  displayOrder: number;
  createdAt: string;
  updatedAt: string;
  startDate: string;
  endDate: string;
  courseTranslations: CourseTranslationsType[];
}

export interface DataListCoursesResponse extends DataListResponse {
  accounts: Account[]|[];
}

export interface DataPublishPortfolioListResponse extends DataListResponse {
  totalParticipant: number;
}

export interface ErrorsResponse {
  code: string;
  title: string;
  source: string;
}

export interface CourseResponse {
  meta: MetaResponse | null;
  data: DataListCoursesResponse[];
  category: CategoryResponse;
  links: LinksResponse | null;
}

export interface ListCoursesResponseType {
  data: CourseResponse[];
}


export interface ListCoursesInState {
  data: {
    data: DataListCoursesResponse[];
    meta: MetaResponse | null;
    category: CategoryResponse;
    links: LinksResponse | null;
  }[];
  loading: boolean;
  errors: ErrorsResponse | null;
}

export interface RequestGetListCoursesAction {
  type: typeof REQUEST_GET_LIST_COURSES;
  courses: ListCoursesRequest2;
}
interface ListCoursesOnSuccessAction {
  type: typeof GET_LIST_COURSES_ON_SUCCESS;
  listCourseResponse: ListCoursesResponseType;
}
interface ListCoursesOnErrorAction {
  type: typeof GET_LIST_COURSES_ON_ERROR;
  errors: ErrorsResponse;
}

interface ResetListCoursesAction {
  type: typeof RESET_LIST_COURSES;
}

export type ListCoursesActionTypes =
  | RequestGetListCoursesAction
  | ListCoursesOnSuccessAction
  | ListCoursesOnErrorAction
  | ResetListCoursesAction;
