export const REQUEST_GET_SYSTEM = 'REQUEST_GET_SYSTEM';
export const GET_SYSTEM_ON_SUCCESS = 'GET_SYSTEM_ON_SUCCESS';
export const GET_SYSTEM_ON_ERROR = 'GET_SYSTEM_ON_ERROR';
export interface ErrorsResponse {
  code: string;
  detail: string;
  title: string;
}
interface ItemShareType{
  id: number;
  key: string;
  content: string;
  // eslint-disable-next-line @typescript-eslint/camelcase
  created_at: string;
  // eslint-disable-next-line @typescript-eslint/camelcase
  updated_at: string;
}
export interface SystemResponse{
    'logo_website': ItemShareType;
    'footer_logo': ItemShareType;
    'footer_link': ItemShareType;
    'facebook_url': ItemShareType;
    'facebook_logo': ItemShareType;
    'pinterest_url': ItemShareType;
    'pinterest_logo': ItemShareType;
    'instagram_url': ItemShareType;
    'instagram_logo': ItemShareType;
    'twitter_url': ItemShareType;
    'twitter_logo': ItemShareType;
    'link_redirect': ItemShareType;
    'copyright': ItemShareType;
}

export interface StateType{
  data: SystemResponse|null;
  loading: boolean;
  error: ErrorsResponse|null;
}

interface GetSystemAction {
  type: typeof REQUEST_GET_SYSTEM;
}

interface GetSystemOnErrorAction {
  type: typeof GET_SYSTEM_ON_ERROR;
  errors: ErrorsResponse;
}

interface GetSystemOnSuccessAction {
  type: typeof GET_SYSTEM_ON_SUCCESS;
  response: SystemResponse;
}

export type GetSystemActionTypes =
  GetSystemAction
  |GetSystemOnErrorAction
  |GetSystemOnSuccessAction
