import {
  ProfileState,
  GetProfileActionTypes,
  REQUEST_GET_PROFILE,
  GET_PROFILE_ON_SUCCESS,
  GET_PROFILE_ON_ERROR,
  UPDATE_PROFILE_ON_SUCCESS,
  UPDATE_PROFILE_ON_ERROR,
  REST_STATE_PROFILE,
} from './types';

const defaultState: ProfileState = {
  data: null,
  loading: false,
  isSuccess: false,
  errors: null,
  isUpdateProfile: false,
};

export default (
  state = defaultState,
  action: GetProfileActionTypes,
): ProfileState => {
  switch (action.type) {
    case REQUEST_GET_PROFILE:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case GET_PROFILE_ON_SUCCESS:
      return {
        ...state,
        data: action.responseProfile,
        loading: false,
        isSuccess: true,
        errors: null,
      };
    case GET_PROFILE_ON_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        isSuccess: false,
        errors: action.errors,
      };
    case UPDATE_PROFILE_ON_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdateProfile: true,
        errors: null,
      };
    case UPDATE_PROFILE_ON_ERROR:
      return {
        ...state,
        loading: false,
        isUpdateProfile: false,
        errors: action.errors,
      };
    case REST_STATE_PROFILE:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        isUpdateProfile: false,
        errors: null,
      };
    default:
      return state;
  }
};
