/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, forwardRef } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Controller, get, useFormContext } from 'react-hook-form';

import Text from 'components/atoms/Text';
import mapModifiers from 'function/mapModifier';

type CustomInputDatePickerProps={
  onClick?: () => void;
  error?: string;
}

const CustomInputDatePicker = forwardRef<HTMLInputElement, CustomInputDatePickerProps >(
  ({ onClick, error, ...props }, ref) => (
    <div className={mapModifiers('m-customInputDatePicker', error && 'error')} onClick={onClick}>
      {error && (
      <div className="m-customInputDatePicker_errormessage">
        <Text size="fs12-lg" modifiers={['red-dark']}>
          {error}
        </Text>
      </div>
      )}
      <input type="text" {...props} ref={ref} />
      <span className="m-customInputDatePicker_icon" />
    </div>
  ),
);

interface DatePickerInputProps extends ReactDatePickerProps{
  error?: string;
}

const DatePickerInput: React.FC<DatePickerInputProps> = ({ error, ...props }) => (
  <div>
    <DatePicker
      {...props}
      customInput={<CustomInputDatePicker error={error} />}
    />
  </div>
);

interface DatePickerHookFormProps extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
}

export const DatePickerHookForm: React.FC<DatePickerHookFormProps> = (
  { name, ...props },
) => {
  const { control, errors } = useFormContext();
  const [startDate, setStartDate] = useState<Date | [Date, Date] | null>(new Date());
  const hasError: Record<string, string> = get(errors, `${name}`);
  return (
    <Controller
      onChange={(date: Date | [Date, Date] | null) => setStartDate(date)}
      value={startDate || ''}
      control={control}
      selected={startDate}
      name={name}
      defaultValue=""
      render={({ onChange, value, ...rest }) => (
        <DatePickerInput
          {...props}
          {...rest}
          error={hasError && hasError.message}
          onChange={onChange}
          selected={value}
          autoComplete="off"
        />
      )}
    />
  );
};

export default DatePickerInput;
