import { ErrorsResponse } from '../phase/types';

import {
  REQUEST_GET_ALL_FOLDER,
  GET_ALL_FOLDER_ERROR,
  GET_ALL_FOLDER_SUCCESS,
  CLEAR_ALL_FOLDER,
  TypeAllFolderActions,
  GetFolderSlug,
  DataResponse,
} from './types';

export const requestGetAllFolder = (slug: GetFolderSlug): TypeAllFolderActions => ({
  type: REQUEST_GET_ALL_FOLDER,
  payload: slug,
});
export const getAllFolderSuccess = (data: DataResponse): TypeAllFolderActions => ({
  type: GET_ALL_FOLDER_SUCCESS,
  payload: data,
});
export const getAllFolderError = (error: ErrorsResponse): TypeAllFolderActions => ({
  type: GET_ALL_FOLDER_ERROR,
  payload: error,
});
export const cleanAllfolder = (): TypeAllFolderActions => ({
  type: CLEAR_ALL_FOLDER,
});
