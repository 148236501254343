export const REQUEST_SHOW_MODAL_ERROR = 'REQUEST_SHOW_MODAL_ERROR';
export const REQUEST_HIDE_MODAL_ERROR = 'REQUEST_HIDE_MODAL_ERROR ';

export interface RequestShowModalErrorActionType {
  type: typeof REQUEST_SHOW_MODAL_ERROR;
  payload: string;
}

export interface RequestHideModalErrorActionType {
  type: typeof REQUEST_HIDE_MODAL_ERROR;
}

export interface ModalErrorState {
  show: boolean;
  code: string;
}

export type ModalErrorActionType =
  | RequestShowModalErrorActionType
  | RequestHideModalErrorActionType;
