import { httpNode } from '../instance';

import { DataInfoRegister, RegisterRequest } from 'reducers/register/types';

export const getRegister = async (
): Promise<DataInfoRegister> => {
  const response = await httpNode.get('account/participants/information-registration');
  return response.data.data;
};


export const postRegister = async (params: RegisterRequest): Promise<void> => {
  const response = await httpNode.post('account/participants/register', params);
  return response.data;
};

export const postActiveAccount = async (params: { token: string | null }): Promise<void> => {
  const response = await httpNode.post(`account/participants/active-account?token=${params.token}`);
  return response.data;
};
