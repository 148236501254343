/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { i18n } from 'i18next';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import { DeepMap } from 'react-hook-form';

import { EditProfile } from 'components/templates/ProfileForm';
import { ProgressFile } from 'reducers/courseuploadfiles/types';
import { CriteriaTranslation } from 'reducers/detailcourse/criteria/types';
import {
  DataDetailCourseResponse,
  Phase,
} from 'reducers/detailcourse/phase/types';
import {
  CourseTranslationsType,
  PhasesTranslationsType,
  ResponseDataType,
  TranslateCourseAndPhase,
} from 'reducers/expertcourse/types';
import { InfoParticipant } from 'reducers/expertlistparticipant/types';
import {
  DataListCoursesResponse,
  DataListResponse,
  DataPublishPortfolioListResponse,
} from 'reducers/pages/courses/types';
import {
  ProfileResponse,
  UpdateProfileParticipantRequest,
  UpdateProfileRequest,
} from 'reducers/profile/types';

export const classNames: (...items: string[]) => string = (...items) => items.join(' ');

export const smoothScrollTo = (to: number, duration: number): void => {
  const element = document.scrollingElement || document.documentElement;
  const start = element.scrollTop;
  const change = to - start;
  const startDate = +new Date();

  const easeInOutQuad = (
    t: number,
    b: number,
    c: number,
    d: number,
  ): string => {
    let newT = t / (d / 2);
    if (newT < 1) return String((c / 2) * newT * newT + b);
    newT -= 1;
    return String((-c / 2) * (newT * (newT - 2) - 1) + b);
  };

  const animateScroll = (): void => {
    const currentDate = +new Date();
    const currentTime = currentDate - startDate;
    element.scrollTop = parseInt(
      easeInOutQuad(currentTime, start, change, duration),
      10,
    );
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      element.scrollTop = to;
    }
  };
  animateScroll();
};

export const scrollTop = (): void => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

type CourseProcessTypes = { status?: 'success' | 'active'; percent?: number };

export const calculationCourseProcess = (
  start: string,
  end: string,
): CourseProcessTypes => {
  const duration = moment.duration(moment(end).diff(moment(start))).asDays();
  const durationStart = moment.duration(moment().diff(moment(start))).asDays();
  const durationEnd = moment.duration(moment().diff(moment(end))).asDays();
  if (durationEnd < 0) {
    return durationStart > 0
      ? { status: 'active', percent: (durationStart / duration) * 100 }
      : {};
  }
  if (durationEnd > 0) return { status: 'success', percent: 100 };
  return {};
};

export const formatDate = (timeDate?: string): string => {
  if (!timeDate) return '';
  const date = new Date(moment(timeDate).format());
  const dd = `0${date.getDate()}`.slice(-2);
  const mm = `0${date.getMonth() + 1}`.slice(-2);
  const yyyy = date.getFullYear();
  return `${dd}.${mm}.${yyyy}`;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const modifyFiles = (existingFiles: ProgressFile[], files: FileList) => {
  const fileToUpload: ProgressFile[] = [];
  for (let i = 0; i < files.length; i += 1) {
    let id = 0;
    if (existingFiles) {
      id = existingFiles.length + i + 1;
    } else {
      id = i + 1;
    }

    fileToUpload.push({
      id,
      file: files[i],
      progress: 0,
      isUploading: true,
      name: files[i].name,
      size: files[i].size,
      errors: null,
    });
  }
  return fileToUpload;
};
export const findPhaseActive = (
  dataResponse: DataDetailCourseResponse,
): Phase => {
  if (dataResponse.phases[0].accounts.length <= 0) {
    return dataResponse.phases[0];
  }

  const indexPhaseNotStartFrist = dataResponse.phases.findIndex(
    (phase) => phase.accounts.length <= 0,
  );

  if (indexPhaseNotStartFrist !== -1) {
    return dataResponse.phases[indexPhaseNotStartFrist - 1];
  }

  return dataResponse.phases[dataResponse.phases.length - 1];
};

export const getTranslateCourseAndPhase = (
  i18N: i18n,
  infoPhase?: ResponseDataType,
): TranslateCourseAndPhase => {
  let translate: TranslateCourseAndPhase = {
    phase: {
      id: 0,
      phaseId: 0,
      locale: '',
      name: '',
      slug: '',
      description: '',
      title: '',
    },
    course: {
      id: 0,
      about: null,
      courseId: 0,
      description: '',
      locale: '',
      name: '',
      slug: '',
      support: null,
      title: '',
    },
  };
  if (infoPhase && infoPhase.courseTranslations && i18N) {
    const course = infoPhase.courseTranslations.find(
      (item) => item.locale === i18N.language,
    );
    if (course) {
      translate = { ...translate, course };
    }
  }
  if (infoPhase && infoPhase.phases && i18N) {
    infoPhase.phases.forEach((item) => {
      const phase = item.phasesTranslations.find(
        (val) => val.locale === i18N.language,
      );
      if (phase) {
        translate = { ...translate, phase };
      }
    });
  }
  return translate;
};

export const getTranslatePhase = (
  i18N: i18n,
  item?: PhasesTranslationsType[],
): PhasesTranslationsType => {
  let newPhase: PhasesTranslationsType = {
    id: 0,
    phaseId: 0,
    locale: '',
    name: '',
    slug: '',
    description: '',
    title: '',
  };
  if (item && i18N) {
    const phase = item.find((val) => val.locale === i18N.language);
    if (phase) {
      newPhase = phase;
    }
  }
  return newPhase;
};

export const getCourseTranslate = (
  i18N: i18n,
  item?:
    | DataListCoursesResponse
    | DataPublishPortfolioListResponse
    | DataListResponse,
): CourseTranslationsType => {
  let translateCourse: CourseTranslationsType = {
    id: 0,
    about: null,
    courseId: 0,
    description: '',
    locale: '',
    name: '',
    slug: '',
    support: null,
    title: '',
  };
  if (item && item.courseTranslations && i18N) {
    const course = item.courseTranslations.find(
      (val) => val.locale === i18N.language,
    );
    if (course) {
      translateCourse = course;
    }
  }
  return translateCourse;
};

export const getTranslateCriteria = (
  i18N: i18n,
  item?: CriteriaTranslation[],
): CriteriaTranslation => {
  let newCriteria: CriteriaTranslation = {
    id: 0,
    criteriaId: 0,
    locale: '',
    name: '',
    description: '',
  };
  if (item && i18N) {
    const criteria = item.find((val) => val.locale === i18N.language);
    if (criteria) {
      newCriteria = criteria;
    }
  }
  return newCriteria;
};

type optionPulldown = {
  value: string;
  label: string;
};

export const nameLang = (editData?: ProfileResponse): string => {
  if (!editData || !editData.language) return '';
  return editData.language;
};
export const nameAvaibility = (editData?: ProfileResponse): string => {
  if (!editData || !editData.availability) return '';
  return editData.availability.availabilityTranslation.name;
};

export const checkOptionsAvailability = (
  editData?: ProfileResponse | null,
): null | optionPulldown => {
  if (!editData || !editData.availability) return null;
  return {
    value: editData.availability.availabilityTranslation.availabilityId.toString(),
    label: editData.availability.availabilityTranslation.name,
  };
};

export const nameCountry = (editData?: ProfileResponse): string => {
  if (!editData || !editData.country) return '';
  return editData.country.countryTranslation.name;
};

export const checkCountry = (editData?: ProfileResponse) => {
  if (!editData || !editData.country) return null;
  return {
    value: editData.country.countryTranslation.countryId.toString(),
    label: editData.country.countryTranslation.name,
  };
};

export const namePosition = (editData?: ProfileResponse): string => {
  if (!editData || !editData.position) return '';
  if (editData.position.positionTranslation.positionId === 8) {
    return editData.positionText || '';
  }
  return editData.position.positionTranslation.name;
};
export const checkOptionsPosition = (
  editData?: ProfileResponse,
): null | optionPulldown => {
  if (!editData || !editData.position) return null;
  return {
    value: editData.position.positionTranslation.positionId.toString(),
    label: editData.position.positionTranslation.name,
  };
};
export const nameTechnical = (editData?: ProfileResponse): string => {
  if (!editData || !editData.technical) return '';
  return editData.technical;
};
export const checkGender = (
  male: string,
  female: string,
  key?: number,
): string => {
  if (!key || key === 1) {
    return male;
  }
  return female;
};

export const createStringArray = (arr: optionPulldown[]): string => {
  const result = arr.map((_item) => _item.label);
  return result.toString();
};

const prepareToWork = (
  prepareToWork0?: boolean | string,
  prepareToWork1?: boolean | string,
  prepareToWork2?: boolean | string,
) => {
  const result: number[] = [];
  if (prepareToWork0 && typeof prepareToWork0 === 'string') {
    result.push(parseFloat(prepareToWork0));
  }
  if (prepareToWork1 && typeof prepareToWork1 === 'string') {
    result.push(parseFloat(prepareToWork1));
  }
  if (prepareToWork2 && typeof prepareToWork2 === 'string') {
    result.push(parseFloat(prepareToWork2));
  }
  return result;
};

const customFeildUploadProfile = (profileform: EditProfile) => ({
  positionText:
    typeof profileform.positionId === 'string'
      ? profileform.positionId
      : undefined,
  positionId:
    typeof profileform.positionId === 'object'
      ? parseFloat(profileform.positionId && profileform.positionId.value)
      : typeof profileform.positionId === 'string' && profileform.positionId
        ? 8
        : undefined,
  address: profileform.address,
  availabilityId: parseFloat(
    profileform.availabilityId && profileform.availabilityId.value,
  ),
  languages:
    profileform.languages && profileform.languages.length > 0
      ? createStringArray(profileform.languages)
      : '',
  technicalIds:
    profileform.technicalIds && profileform.technicalIds.length > 0
      ? profileform.technicalIds.map((item) => parseFloat(item.value))
      : [],
  occupation: profileform.occupation,
  technicalText: profileform.technicalText,
  countryId: profileform.countryId
    ? Number(profileform.countryId.value)
    : undefined,
});

export const customDataUploadProfile = (
  profileform: EditProfile,
  dirty: DeepMap<EditProfile, true>,
): UpdateProfileParticipantRequest => {
  const {
    positionText,
    positionId,
    address,
    availabilityId,
    languages,
    technicalIds,
    occupation,
    technicalText,
    countryId,
  } = customFeildUploadProfile(profileform);
  return {
    address: dirty.address && address,
    positionId: dirty.positionId && positionId,
    availabilityId: dirty.availabilityId && availabilityId,
    languages: dirty.languages && languages,
    occupation: dirty.occupation && occupation,
    technicalIds: dirty.technicalIds && technicalIds,
    positionText: dirty.positionId && positionText,
    technicalText: dirty.technicalText && technicalText,
    prepareToWork:
      (dirty.prepareToWork0 || dirty.prepareToWork1 || dirty.prepareToWork2)
      && prepareToWork(
        profileform.prepareToWork0,
        profileform.prepareToWork1,
        profileform.prepareToWork2,
      ),
    countryId: dirty.countryId && countryId,
  };
};

export const customUpdateProfile = (
  profileform: EditProfile,
  dirty: DeepMap<EditProfile, true>,
): UpdateProfileRequest => ({
  intro: dirty.intro && profileform.intro,
});

export const checkLang = (
  editData: ProfileResponse,
  optionsLanguge: optionPulldown[],
) => {
  const result: optionPulldown[] = [];
  if (!editData.language) return null;
  optionsLanguge.forEach((item) => {
    if (
      editData.language
        .replace(/ /g, '')
        .split(',')
        .includes(item.label.replace(/ /g, ''))
      && item
    ) {
      result.push({ value: item.value, label: item.label });
    }
  });
  return result || null;
};

export const checkTechnical = (
  editData: ProfileResponse,
  optionsTechnical: optionPulldown[],
) => {
  if (!editData.technical) return null;
  const result: optionPulldown[] = [];
  optionsTechnical.forEach((item) => {
    if (
      editData.technical
        .replace(/ /g, '')
        .split(',')
        .includes(item.label.replace(/ /g, ''))
      && item
    ) {
      result.push({ value: item.value, label: item.label });
    }
  });
  return result || null;
};

export const errorResponse = (error: any): string => {
  if (!error) return '';
  let err = '';
  if (Array.isArray(error) && error.length > 0) {
    err = (error[0] && error[0].code) || '';
  }
  return err;
};

export const formatNumber = (value: number) => {
  if (!value) {
    return '0';
  }
  return value.toLocaleString('en-US');
};

export const mappingListParticipant = (
  currentParticipant: InfoParticipant[],
  newParticipant: InfoParticipant[],
) => {
  let dataMapping: InfoParticipant[] = [];
  if (currentParticipant) dataMapping = [...currentParticipant];

  if (newParticipant.length) {
    const listParticipantNotExist = newParticipant.filter(
      (item) => currentParticipant.every((v) => v.id !== item.id),
    );
    dataMapping = [...dataMapping, ...listParticipantNotExist];
  }

  return dataMapping;
};
