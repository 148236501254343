import { combineReducers } from 'redux';

import contact from './contact';
import courses from './courses';
import home from './home';

export default combineReducers({
  home,
  contact,
  courses,
});
