export const REQUEST_GET_LIST_PARTICIPANTS = 'REQUEST_GET_LIST_PARTICIPANTS';
export const GET_LIST_PARTICIPANTS_SUCCESS = 'GET_LIST_PARTICIPANTS_SUCCESS';
export const GET_LIST_PARTICIPANTS_ERROR = 'GET_LIST_PARTICIPANTS_ERROR';
export const CLEAR_LIST_PARTICIPANTS = 'CLEAR_LIST_PARTICIPANTS';
export interface MetaType{
  totalPages: number;
  totalRecords: number;
}
export interface ParticipantItemType{
  id: number;
  studentId: string;
  firstName: string;
  lastName: string;
  intro: string;
  email: string;
  type: number;
  gender: number;
  image: string;
  dob: string;
  location: null|string;
  occupation: null|string;
  technical: null|string;
  language: null|string;
  active: number;
  emailVerifiedAt: string;
  rememberToken: null|string;
  createdAt: string;
  updatedAt: string;
  phases?: {
    AccountPhasesModel: AccountPhasesModel;
  }[];
  isHasRoleAccess: number;
}
interface LinksType{
  self: string;
  first: string;
  prev: string|null;
  next: string|null;
  last: string;
}
export interface DataResponseType{
  isShowMore?: boolean;
  meta: MetaType|null;
  data: ParticipantItemType[]|[];
  links: LinksType|null;
}
export interface ErrorsResponse {
  code: string;
  title: string;
  source: string;
}
export type QueryParamsType={
  slugCourse: string;
  slugPhase: string;
  pageNum: number;
  pageSize: number;
  searchText?: string;
  isShowMore?: boolean;
}
export interface ListParticipantsInitState {
  meta: MetaType|null;
  data: ParticipantItemType[];
  links: LinksType|null;
  loading: boolean;
  error: ErrorsResponse | null;
}
export interface RequestListParticipantsActionType {
  type: typeof REQUEST_GET_LIST_PARTICIPANTS;
  payload: QueryParamsType;
}
interface GetListParticipantsSuccessType {
  type: typeof GET_LIST_PARTICIPANTS_SUCCESS;
  payload: DataResponseType;
}

interface GetListParticipantsErrorAction {
  type: typeof GET_LIST_PARTICIPANTS_ERROR;
  payload: ErrorsResponse;
}

interface RequestClearListParticipants {
  type: typeof CLEAR_LIST_PARTICIPANTS;
}

export type ListParticipantsActionsType = RequestListParticipantsActionType
  | GetListParticipantsSuccessType
  | GetListParticipantsErrorAction
  | RequestClearListParticipants;
