/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { getAccessToken } from 'apis/localstorage';

type PrivateRouteProp = {
  component: any;
  path: string;
  exact: boolean;
};

const PrivateRoute = ({
  component: Component,
  ...rest
}: PrivateRouteProp) => (
  <Route
    {...rest}
    render={(routeProps) => (getAccessToken() ? (
      <Component {...routeProps} />
    ) : (
      <Redirect
        to={{
          pathname: '/',
          state: { from: routeProps.location },
        }}
      />
    ))}
  />
);

export default PrivateRoute;
