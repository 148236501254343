import {
  ErrorsResponse,
  ListCoursesResponseType,
  REQUEST_GET_LIST_COURSES,
  GET_LIST_COURSES_ON_SUCCESS,
  GET_LIST_COURSES_ON_ERROR,
  RESET_LIST_COURSES,
  ListCoursesActionTypes,
  ListCoursesRequest2,
} from './types';


export const requestGetListCourses = (
  courses: ListCoursesRequest2,
): ListCoursesActionTypes => ({
  type: REQUEST_GET_LIST_COURSES,
  courses,
});

export const listCoursesOnSuccess = (
  listCourseResponse: ListCoursesResponseType,
): ListCoursesActionTypes => ({
  type: GET_LIST_COURSES_ON_SUCCESS,
  listCourseResponse,
});

export const listCoursesOnError = (
  errors: ErrorsResponse,
): ListCoursesActionTypes => ({
  type: GET_LIST_COURSES_ON_ERROR,
  errors,
});

export const resetListCourses = (
): ListCoursesActionTypes => ({
  type: RESET_LIST_COURSES,
});
