import { AxiosRequestConfig } from 'axios';

export const REQUEST_UPLOAD_FILE = 'REQUEST_UPLOAD_FILE';
export const REQUEST_DELETE_FILE = 'REQUEST_DELETE_FILE';
export const REQUEST_SUBMIT_UPLOAD_FILE = 'REQUEST_SUBMIT_UPLOAD_FILE';
export const UPLOAD_FILE_ON_SUCCESS = 'UPLOAD_FILE_ON_SUCCESS';
export const UPLOAD_FILE_ON_ERROR = 'UPLOAD_FILE_ON_ERROR';
export const DELETE_FILE_ON_SUCCESS = 'DELETE_FILE_ON_SUCCESS';
export const DELETE_FILE_ON_ERROR = 'DELETE_FILE_ON_ERROR';
export const SUBMIT_UPLOAD_FILE_ON_SUCCESS = 'SUBMIT_UPLOAD_FILE_ON_SUCCESS';
export const SUBMIT_UPLOAD_FILE_ON_ERROR = 'SUBMIT_UPLOAD_FILE_ON_ERROR';
export const REQUEST_RESET_FORM_UPDATE_PASSWORD = 'REQUEST_RESET_FORM_UPDATE_PASSWORD';
export const SET_UPLOAD_FILE = 'SET_UPLOAD_FILE';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export const FILTER_FILE_PROGRESS = 'FILTER_FILE_PROGRESS';
export const RESET_UPLOAD_FILES = 'RESET_UPLOAD_FILES';

// interface
export interface SubmitUploadFilesRequest {
  folderId: string;
  fileIds: number[];
}

export interface ErrorsResponse {
  code: number;
  detail: string;
  title: string;
  message: string;
}

export interface UploadFile {
  id: number;
  phaseFolderId: number;
  name: string;
  subName: string;
  thumbnail: string;
  url: string;
  mimeType: string;
  extension: string;
  size: number;
  createdAt: string;
  updatedAt: string;
  fileId: number;
}

export interface ProgressFile {
  id: number;
  file: File;
  progress: number;
  isUploading?: boolean;
  name: string;
  size: number;
  errors: string | null;
  limit?: boolean;
}

export interface UploadFilesState {
  dataUploadFile: UploadFile[];
  fileProgress: ProgressFile[];
  loading: boolean;
  errors: string | null;
  uploadedFiles: boolean;
  isSuccess: boolean;
}

export interface SetUploadFileAction {
  type: typeof SET_UPLOAD_FILE;
  files: FileList;
}

export interface SetUploadProgressAction {
  type: typeof SET_UPLOAD_PROGRESS;
  id: number;
  progress: number;
}

export interface RequestUploadFilesAction {
  type: typeof REQUEST_UPLOAD_FILE;
  uploadFiles: FormData;
  fileId: number;
  config: AxiosRequestConfig;
}
interface UploadFileOnSuccessAction {
  type: typeof UPLOAD_FILE_ON_SUCCESS;
  dataUploadFile: UploadFile;
}
interface UploadFileOnErrorAction {
  type: typeof UPLOAD_FILE_ON_ERROR;
  errors: ErrorsResponse;
  fileId: number;
}

export interface RequestDeleteFilesAction {
  type: typeof REQUEST_DELETE_FILE;
  file: UploadFile;
}
interface DeleteFileOnSuccessAction {
  type: typeof DELETE_FILE_ON_SUCCESS;
  file: UploadFile;
}
interface DeleteFileOnErrorAction {
  type: typeof DELETE_FILE_ON_ERROR;
  errors: ErrorsResponse;
}

export interface RequestSubmitUploadFilesAction {
  type: typeof REQUEST_SUBMIT_UPLOAD_FILE;
  submitUploadFiles: SubmitUploadFilesRequest;
}
interface SubmitFileOnSuccessAction {
  type: typeof SUBMIT_UPLOAD_FILE_ON_SUCCESS;
}
interface SubmitFileOnErrorAction {
  type: typeof SUBMIT_UPLOAD_FILE_ON_ERROR;
  errors: ErrorsResponse;
}

interface FilterFileProgressAction {
  type: typeof FILTER_FILE_PROGRESS;
  fileId: number;
}

interface ResetUploadFilesAction {
  type: typeof RESET_UPLOAD_FILES;
}


export type UploadFilesActionTypes =
  | SetUploadFileAction
  | RequestUploadFilesAction
  | UploadFileOnSuccessAction
  | UploadFileOnErrorAction
  | RequestDeleteFilesAction
  | DeleteFileOnSuccessAction
  | DeleteFileOnErrorAction
  | RequestSubmitUploadFilesAction
  | SubmitFileOnSuccessAction
  | SubmitFileOnErrorAction
  | SetUploadProgressAction
  | FilterFileProgressAction
  | ResetUploadFilesAction;
