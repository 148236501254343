/* eslint-disable linebreak-style */
export const REQUEST_ACCEPT_POLICY = 'REQUEST_ACCEPT_POLICY';
export const ACCEPT_POLICY_ON_SUCCESS = 'ACCEPT_POLICY_ON_SUCCESS';
export const ACCEPT_POLICY_ON_ERROR = 'ACCEPT_POLICY_ON_ERROR';

// interface
export interface AcceptPolicyState {
  isSuccessAcceptPolicy: boolean;
  errors: ErrorsResponse | null;
}

export interface RequestAcceptPolicy {
  courseId: number;
}

export interface ErrorsResponse {
  code: string;
  detail: string;
  title: string;
}

export interface RequestAcceptPolicyAction {
  type: typeof REQUEST_ACCEPT_POLICY;
  acceptPolicy: RequestAcceptPolicy;
}

interface AcceptPolicyOnErrorAction {
  type: typeof ACCEPT_POLICY_ON_ERROR;
  errors: ErrorsResponse;
}

interface AcceptPolicyOnSuccessAction {
  type: typeof ACCEPT_POLICY_ON_SUCCESS;
}

export type AcceptPolicyActionTypes =
  | RequestAcceptPolicyAction
  | AcceptPolicyOnErrorAction
  | AcceptPolicyOnSuccessAction;
