import {
  call, put, takeEvery,
} from 'redux-saga/effects';

import { getExpertCourse } from 'apis/requests/expertcourse';
import {
  getCourseExpertSuccess,
  getCourseExpertError,
} from 'reducers/expertcourse/actions';
import {
  REQUEST_GET_COURSE_EXPERT,
  RequestCourseExpertActionType,
  ResponseDataType,
} from 'reducers/expertcourse/types';
import { requestStopLoading } from 'reducers/loading/actions';


function* requestExpertCourseSaga(action: RequestCourseExpertActionType): Generator {
  try {
    const data = (yield call(getExpertCourse, action.payload)) as ResponseDataType;
    yield put(requestStopLoading());
    yield put(getCourseExpertSuccess(data));
  } catch (e) {
    yield put(getCourseExpertError(e));
  }
}

export default function* watchFetchExpertCourseSaga(): Generator {
  yield takeEvery(REQUEST_GET_COURSE_EXPERT, requestExpertCourseSaga);
}
