import {
  REQUEST_GET_LIST_COURSE_FILES,
  GET_LIST_COURSE_FILES_ON_ERROR,
  GET_LIST_COURSE_FILES_ON_SUCCESS,
  REQUEST_GET_FILE_ALL_VERSIONS,
  ListCourseFileActionTypes,
  ListCourseFilesState,
} from './types';

const defaultState: ListCourseFilesState = {
  courseFiles: [],
  loading: false,
  errors: null,
};

export default (state = defaultState,
  action: ListCourseFileActionTypes): ListCourseFilesState => {
  switch (action.type) {
    case REQUEST_GET_LIST_COURSE_FILES:
    case REQUEST_GET_FILE_ALL_VERSIONS:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case GET_LIST_COURSE_FILES_ON_SUCCESS:
      return {
        ...state,
        courseFiles: action.data,
        loading: false,
        errors: null,
      };
    case GET_LIST_COURSE_FILES_ON_ERROR:
      return {
        ...state,
        courseFiles: [],
        loading: false,
        errors: action.errors,
      };
    default:
      return state;
  }
};
