import React from 'react';

import Icon, { IconName } from 'components/atoms/Icon';
import mapModifiers from 'function/mapModifier';

interface IconButtonProps {
  handleClick?: () => void;
  iconName: IconName;
  modifiers?: ('radius'|'bgBlue'|'bgYellow'|'bgOriolesOrange')[];
  isDisabled?: boolean;
}

const IconButton: React.FC<IconButtonProps> = ({
  handleClick,
  iconName,
  modifiers,
  isDisabled,
}) => (
  <button
    disabled={isDisabled}
    className={mapModifiers('a-iconButton', modifiers)}
    type="button"
    onClick={handleClick}
  >
    <Icon iconName={iconName} />
  </button>
);

export default IconButton;
