export const REQUEST_GET_LIST_COUNTRY = 'REQUEST_GET_LIST_COUNTRY';
export const GET_LIST_COUNTRY_ON_SUCCESS = 'GET_LIST_COUNTRY_ON_SUCCESS';
export const GET_LIST_COUNTRY_ON_ERROR = 'GET_LIST_COUNTRY_ON_ERROR';

export type CountryTypes = {
  id: number;
  createdAt: string;
  updatedAt: string;
  countryTranslation: {
    id: number;
    countryId: number;
    locale: string;
    name: string;
  };
}

export type ListCountryState = {
  data: CountryTypes[];
  loading: boolean;
  errors?: ErrorsResponse ;
}

export type ErrorsResponse = {
  code: string;
  detail: string;
  title: string;
}

export type RequestGetListCountryAction = {
  type: typeof REQUEST_GET_LIST_COUNTRY;
}

export type RequestGetListCountryOnSuccessAction = {
  type: typeof GET_LIST_COUNTRY_ON_SUCCESS;
  data: CountryTypes[];
}

export type RequestGetListCountryOnErrorAction = {
  type: typeof GET_LIST_COUNTRY_ON_ERROR;
  errors: ErrorsResponse;
}

export type ListCountryActionTypes =
  | RequestGetListCountryAction
  | RequestGetListCountryOnSuccessAction
  | RequestGetListCountryOnErrorAction;
