import {
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import { RootState } from 'store';

export type UseSelectorHookType = TypedUseSelectorHook<RootState>;
const useSelector: UseSelectorHookType = useReduxSelector;

export default useSelector;
