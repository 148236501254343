import React from 'react';

import ModalForgot from './modal-forgot';
import ModalLogin from './modal-login';
import ModalRegister from './modal-register';

const Common: React.FC = () => (
  <>
    <ModalLogin />
    <ModalForgot />
    <ModalRegister />
  </>
);

export default Common;
