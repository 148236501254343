/* eslint-disable import/prefer-default-export */
import { httpNode } from '../instance';

import { UserUpdatePasswordRequest } from 'reducers/account/types';

export const updatePassword = async (
  params: UserUpdatePasswordRequest,
): Promise<void> => {
  const response = await httpNode.put('account/update-password', params);
  return response.data;
};
