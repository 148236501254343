import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import getListCoursesParticipant from 'apis/requests/courses';
import {
  listCoursesOnError,
  listCoursesOnSuccess,
} from 'reducers/pages/courses/action';
import {
  REQUEST_GET_LIST_COURSES,
  ListCoursesResponseType,
  RequestGetListCoursesAction,
} from 'reducers/pages/courses/types';


function* requestListCoursesSaga(action: RequestGetListCoursesAction): Generator {
  try {
    const data = (yield call(getListCoursesParticipant, action.courses)) as ListCoursesResponseType;
    yield put(listCoursesOnSuccess(data));
  } catch (e) {
    yield put(listCoursesOnError(e));
  }
}

export default function* watchFetchListCoursesPageSaga(): Generator {
  yield takeLatest(REQUEST_GET_LIST_COURSES, requestListCoursesSaga);
}
