import { Phase, ErrorsResponse, SlugCourse } from '../phase/types';
import { Expert } from '../progess/types';

export const REQUEST_ASSESSMENT_COURSE = 'REQUEST_ASSESSMENT_COURSE';
export const GET_ASSESSMENT_COURSE_SUCCESS = 'GET_ASSESSMENT_COURSE_SUCCESS';
export const GET_ASSESSMENT_COURSE_ERROR = 'GET_ASSESSMENT_COURSE_ERROR';
export const UPDATE_COMMENT_PARTICIPANT = 'UPDATE_COMMENT_PARTICIPANT';
export const CLEAR_ASSESSMENT_PARTICIPANT = 'CLEAR_ASSESSMENT_PARTICIPANT';
export interface CriteriaTranslation {
  id: number;
  criteriaId: number;
  locale: string;
  name: string;
  description: null | string;
}
export interface Course {
  id: number;
  CourseCriteriasModel: { id: number };
}
export interface PhaseMarks {
  phaseId: number;
  score: number | null;
}
export interface Criteria {
  id: number;
  displayOrder: number;
  active: number;
  criteriaTranslations: CriteriaTranslation[];
  courses: Course[];
  listPhaseMarks: PhaseMarks[];
  avgMark: number;
}
export interface Mark {
  id: number;
  assessmentId: number;
  criteriaId: number;
  score: number;
}

interface CommentTranslation {
  commentId: number;
  content: string;
  locale: string;
}

export interface AssessmentComment {
  assessmentId: number;
  commentId: number;
  commentTranslations: CommentTranslation[];
}

export interface Assessment {
  id: number;
  active: number;
  displayOrder: number;
  phaseId: number;
  participantId: number;
  expertId: number;
  avgScore: number;
  expertComment?: string | null;
  expert: Expert;
  createdAt: string;
  updatedAt: string;
  startDateOfAssessment: string;
  endDateOfAssessment: string;
  assessmentComments: AssessmentComment[];
  assessmentMarks: Mark[];
}

interface Accounts{
  AccountPhasesModel: {
    startDate: string;
    endDate: string;
    assessment: Assessment;
    startDateOfAssessment: string;
    endDateOfAssessment: string;
  };
}
export interface PhaseAssessment extends Phase {
  accounts: [] | Accounts[];
}
export interface DataAssessmentCourseResponse {
  listCriterias: Criteria[];
  listPhases: PhaseAssessment[];
  finalMark: number;
  finalComment: null|string;
  expertFinalComment: null|string;
  expert: Expert|null;
  isCompleted: boolean;
}
export interface RequestAssessmentCourse {
  type: typeof REQUEST_ASSESSMENT_COURSE;
  payload: SlugCourse;
}
export interface GetAssessmentCourseSuccessAction {
  type: typeof GET_ASSESSMENT_COURSE_SUCCESS;
  payload: DataAssessmentCourseResponse;
}
export interface GetAssessmentCourseErrorAction {
  type: typeof GET_ASSESSMENT_COURSE_ERROR;
  payload: ErrorsResponse;
}

export interface UpdateCommentParticipant {
  type: typeof UPDATE_COMMENT_PARTICIPANT;
  payload: string;
}

export interface ClearAssessmentParticipant {
  type: typeof CLEAR_ASSESSMENT_PARTICIPANT;
}

export type AssessmentCourseActionTypes = RequestAssessmentCourse
  | GetAssessmentCourseSuccessAction
  | GetAssessmentCourseErrorAction
  | UpdateCommentParticipant
  | ClearAssessmentParticipant
