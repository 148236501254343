import { httpNode } from '../instance';

import { ListCoursesRequest2, ListCoursesResponseType } from 'reducers/pages/courses/types';

const getListCoursesParticipant = async (
  params: ListCoursesRequest2,
): Promise<ListCoursesResponseType> => {
  try {
    const response = await httpNode.get('course/list-courses', {
      params,
    });
    return response.data;
  } catch (e) {
    throw e.message;
  }
};

export const getListCoursesExpert = async (
  params: ListCoursesRequest2,
): Promise<ListCoursesResponseType> => {
  try {
    const response = await httpNode.get('course/expert/list-courses', {
      params,
    });
    return response.data;
  } catch (e) {
    throw e.message;
  }
};

export default getListCoursesParticipant;
