import { httpNode } from '../instance';

import { CountryTypes } from 'reducers/country/types';


const getListCountryService = async (): Promise<CountryTypes[]> => {
  const response = await httpNode.get('account/country-list');
  return response.data.data;
};

export default getListCountryService;
